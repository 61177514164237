import {ELanguageLevel, ELanguageLevelTable} from "../Api/Dtos/Resume/ELanguageLevel";

export interface ILanguageLevelOption {
    id: number,
    label: string,
    active: boolean,
}

let arr = Object.keys(ELanguageLevel).splice(6, 7);
export const LanguageLevelOptions: ILanguageLevelOption[] = arr.map( (key, index) => {
    return {
        id: index,
        label: key,
        active: true,
    }
});

let ar = Object.keys(ELanguageLevelTable).splice(3,7)
export const LanguageLevelTableOptions: ILanguageLevelOption[] = ar.map( (key, index) => {
    return {
        id: index,
        label: key,
        active: true,
    }
});