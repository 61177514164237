import React from "react";
import styled from "ibis-design-system/lib/HelperFunctions/ThirdParty/styled-components";
import Tile from "ibis-design-system/lib/components/core/Tile";
import {ProgressRing} from "./Overview/ProgressRing";

const SubText = styled.span`
    font-weight: ${(props: any) => props.theme.fontWeights.regular};
    font-size: 12px;
`;

const Title = styled.span`
    font-weight: ${(props: any) => props.theme.fontWeights.light};
    font-size: 52px;
`;

const SubTitle = styled.span`
    font-weight: ${(props: any) => props.theme.fontWeights.light};
    font-size: 24px;
`;

interface ITileFilledProps {
    // The large text displayed in the center of the tile.
    title: string;
    // custom styles for the title text.
    titleStyles?: React.CSSProperties;
    // The smaller text displayed below the title.
    subtext: string;
    // Optional - Determines the color of the tile. Default is "neutralPrimary".
    color?: string;
    // Boolean - if true the tiles background color will be white, and the warning icon will be displayed.
    warning?: boolean;
    // Optional - Size of the Tile. Default is 150px.
    size?: number;
    // Optional - A smaller title below the large title.
    subTitle?: string;
    // Optional - A boolean that if set to true, will display the title (which should be a number in this case) inside a percentage filled ring.
    percentage?: boolean
}

export const TileFilled: React.FunctionComponent<ITileFilledProps> = (props) => {
    return (
        <Tile
            size={props.size ? props.size : 150}
            warning={props.warning}
            backgroundColor={props.warning ? "white" : 'rgba(0, 0, 0, 0.05)'}
            color={props.color ? props.color : "neutralPrimary"}
        >
            <Tile.Head>
                { !props.subTitle ?
                    props.percentage ?
                        <ProgressRing radius={50} stroke={3} progress={props.title && !isNaN(Number(props.title)) ? Number(props.title) : 0}>
                            <Title style={{position: "absolute", left: 48, top: 22, fontSize: 34, ...props.titleStyles}}>{props.title + "%"}</Title>
                        </ProgressRing>
                        :
                        <Title style={props.titleStyles}>{props.title}</Title>
                    :
                    <div>
                        <div style={{marginTop: 16}}><Title style={props.titleStyles}>{props.title}</Title></div>
                        <div style={{textAlign: "center", marginTop: -18}}><SubTitle>{props.subTitle}</SubTitle></div>
                    </div>
                }
            </Tile.Head>
            <Tile.SubText>
                <SubText>{props.subtext}</SubText>
            </Tile.SubText>
        </Tile>
    );
};
