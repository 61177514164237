import {ICreateTrainingDto, ITrainingDto} from "./ITrainingDto";

export interface IEducationDto extends ITrainingDto {
    educationName: string;
    title: string;
}

export interface ICreateEducationDto extends ICreateTrainingDto {
    educationName?: string;
    title?: string;
    trainingSource: ETrainingSource;
}

export enum ETrainingSource {
    Training,
    Education
}
