import React, {useState} from "react";
import {AddItemForm} from "../../AddItemForm";
import {IYearPickerDates, YearPicker} from "../../YearPicker";
import {ICreateTrainingDto} from "../../../Api/Dtos/Resume/ITrainingDto";
import TextInput from "ibis-design-system/lib/components/inputFields/TextInput";
import {getCompanies, getCompany} from "../../../Api/requests";
import CompanyPicker from "ibis-design-system/lib/components/pickers/CompanyPicker";
import {useNatures} from "../../../Hooks/GlobalConfigContext";
import {companyPickerResult} from "../../../Utils/companyPickerResult";
import Flex from 'ibis-design-system/lib/components/core/Flex';
import {SemiBold} from "../../Bold";
import {EOP} from "../../../Interfaces/IPatchParams";
import {ETrainingSource} from "../../../Api/Dtos/Resume/IEducationDto";
import moment from "moment";

const INITIAL_TRAINING: ICreateTrainingDto = {
    from: moment().toISOString(),
    to: null,
    subjectOfTraining: undefined,
    teachingInstitute: undefined,
    trainingSource: ETrainingSource.Training
}

interface ICreateTrainingProps {
    patch: (args: any) => void;
}

export const CreateTraining: React.FunctionComponent<ICreateTrainingProps> = (props) => {

    const natures = useNatures()
    const [training, setTraining] = useState<ICreateTrainingDto>(INITIAL_TRAINING);

    const setSubject = (value: string) => {
        const tmpTraining = {...training};
        tmpTraining.subjectOfTraining = value;
        setTraining(tmpTraining);
    }

    const setYears = (dates: IYearPickerDates): void => {
        const tmpTraining = {...training};
        tmpTraining.from = dates.start;
        tmpTraining.to = dates.end;
        setTraining(tmpTraining);
    }

    const updateTeachingInstitute = (change: any): void => {
        if (!training || !change) { return; }

        const pickerResult = companyPickerResult(change);
        const tmpTraining = {...training};
        tmpTraining.teachingInstitute = pickerResult ? pickerResult : undefined;
        setTraining(tmpTraining)
    }

    return (
        <AddItemForm closeOnAdd addButtonText={"Add training"} onAddButtonClick={() => props.patch([
            {
                op: EOP.Add,
                path: `trainings/-`,
                value: training,
            },
        ])} cancel={() => setTraining(INITIAL_TRAINING)}>
            <Flex>
                <div style={{flex: 1, flexDirection: "column"}}>
                    <div style={{marginTop: 5}}>
                        <SemiBold style={{marginBottom: 6.5}}>Period</SemiBold>
                        <YearPicker onChange={(dates: IYearPickerDates) => setYears(dates)} dates={{start: training.from, end: training.to ?? undefined}} style={{minWidth: 130, justifyContent: "space-between"}} />
                    </div>
                </div>
                <div style={{flex: 4, flexDirection: "column"}}>
                    <TextInput
                        label="Subject of training"
                        placeholder="Enter a subject"
                        value={training.subjectOfTraining}
                        onChange={(val: string) => setSubject(val)}
                    />

                    <div style={{marginTop: 20}}>
                        <CompanyPicker
                            label="Education institute"
                            placeholder="Select a education institute"
                            pickLimit={1}
                            exclude={training?.teachingInstitute ? [training?.teachingInstitute] : []}
                            selected={training?.teachingInstitute ? [training?.teachingInstitute] : []}
                            natures={natures}
                            getCompany={getCompany}
                            getCompanies={(input: string) => getCompanies(input, null, null)}
                            onChanged={(change: any) => updateTeachingInstitute(change)}
                        />
                    </div>
                </div>
            </Flex>
        </AddItemForm>
    );
};
