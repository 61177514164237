import React, {useEffect, useMemo, useState} from "react";
import {IContainerProps} from "../Interfaces/IContainterProps";
import Map from "ibis-design-system/lib/components/map/Map";
import Box from "ibis-design-system/lib/components/Atoms/Box/Box";
import Flex from 'ibis-design-system/lib/components/core/Flex';
import {Container} from "./Container";
import {ContainerContent} from "../Components/ContainerContent";
import {Tiles} from "../Components/Overview/Tiles";
import {MarginTopBlock} from "../Components/MarginTopBlock";
import {ISmallIconWithTextProps} from "../Components/SmallIconWithText";
import {PersonalDataList} from "../Components/Overview/PersonalDataList";
import {InformationBlock} from "../Components/InformationBlock";
import {EducationAndTrainingItem} from "../Components/Overview/EducationAndTrainingItem";
import {CalendarItem} from "../Components/CalendarItem/CalendarItem";
import {useCacheState} from "ibis-design-system/lib/Providers/HttpCacheProvider";
import {AssignmentDescriptionBlock} from "../Components/AssignmentDescriptionBlock";
import {TeachingAssignmentsOverview} from "../Components/TeachingAssignmentsOverview";
import {useAreasOfExpertise, useLanguages} from "../Hooks/GlobalConfigContext";
import {EmploymentRecordOverviewItem} from "../Components/EmploymentRecordOverviewItem";
import {ICalendarEventDto} from "../Api/Dtos/ICalendarEventDto";
import {getCalenderEventsForActiveUser} from "../Api/requests";
import {getFullName} from "../Utils/getFullName";
import { SemiBold } from "../Components/Bold";
import { WorkUndertakenOverviewItem } from "../Components/WorkUndertakenOverviewItem";
import {useNavigationState} from 'ibis-design-system/lib/Providers/NavigationProvider';
export const Overview: React.FunctionComponent<IContainerProps> = (props) => {

    const languages = useLanguages();
    const GetareasOfExpertises = useAreasOfExpertise();
    const httpCache = useCacheState();
    const [languageNames, setLanguageNames] = useState<(string | undefined)[]>([]);
    const [events, setEvents] = useState<Array<{entity: ICalendarEventDto, sortKey: string}>>([]);
    const {
        entities,
    } = useNavigationState();
    
    useEffect(() => {
        if (props.item.user) {
            getCalenderEventsForActiveUser(props.item.user.id).then(events => setEvents(events.entities)).catch(e => console.log(e));
        }

        if (!props.item.europassLanguages) return;
        setLanguageNames(props.item.europassLanguages.map( ep => languages.find( language => ep.language === language.id)?.label));
    }, [props.item, httpCache, languages]);
    const DateEmployee = new Date(props.item.employmentStartDate)
    const personalDetails: ISmallIconWithTextProps[] = useMemo(() => {
        return [
            {
                icon: "Chat",
                text:  languageNames && languageNames.length ? languageNames.join(" ") : "Add language on resume tab" // "Dutch, English, German"
            },
            {
                icon: "Mail",
                text: props.item.user?.email ? props.item.user.email : "", // "Johan.Pennekamp@deltares.nl",
                email: true,
            },
            {
                icon: "Phone",
                text: props.item.user?.phone ? props.item.user.phone : "",
                tel: true
            },
            {
                icon: "Phone",
                text: props.item.user?.mobile ? props.item.user.mobile : "",
                tel: true
            },
            {
                icon: "Home",
                text: "",
            },
        ]
    }, [props.item.user, languageNames]);

    function AreaofExpertise(): string[] | undefined {
        const findAreaOfExpertiseEntities = entities.map((a: any) => a.areaOfExpertises);
        for (let i = 0; i < findAreaOfExpertiseEntities.length; i++) {
            if (Array.isArray(findAreaOfExpertiseEntities[i]) && findAreaOfExpertiseEntities[i].length > 0) {
                return findAreaOfExpertiseEntities[i];
            }
        }
        return undefined;
    }
    function getAreaLabels(): string[] {
        const areaOfExpertise = AreaofExpertise();
      
        if (areaOfExpertise === undefined) {
          return [];
        }
        
        const labels = areaOfExpertise
          .map((id:any) => GetareasOfExpertises.find(area => area.id === id)?.label) 
          .filter((area:any) => area !== undefined);
      
        return labels as string[];
      }
      
    const areaLabels = getAreaLabels();
    let employment: ISmallIconWithTextProps[] = [
        {
            text: DateEmployee.toLocaleDateString() // "July, 1985 - current"
        }
    ]

    let departmentAndUnit: ISmallIconWithTextProps[] = [
        {
            text: props.item.user?.department ? props.item.user.department : "" // "Ecosystems and Sediment Dynamics (ESD) - ZKS"
        }
    ]

      let areasOfExpertise: ISmallIconWithTextProps[] = areaLabels?.map(label => ({
        text: label
      }));
    return (
        <Container style={props.style} className={props.className}>
            <Box height="150px" width="100%">
                <Map/>
            </Box>

            <ContainerContent>
                <Tiles item={props.item}/>

                <MarginTopBlock style={{display: "flex"}}>
                    <PersonalDataList header="Contact" content={personalDetails}/>
                    <PersonalDataList header="Employment at Deltares" content={employment} />
                    <PersonalDataList header="Department - unit" content={departmentAndUnit} contentStyle={{maxWidth: 210}}/>
                    <PersonalDataList header="Area of expertise" content={areasOfExpertise} />
                </MarginTopBlock>

                {/* <MarginTopBlock>
                    <InformationBlock header="Key Qualifications">
                        <KeyQualificationsOverviewItem keyQualifications={WYSIWYG_dataConversion(props.item.keyQualifications!.map((x:any) => x))} />
                    </InformationBlock>
                </MarginTopBlock> */}

                <MarginTopBlock>
                    <InformationBlock header="Education and training">
                        <EducationAndTrainingItem educations={props.item.educations} trainings={props.item.trainings} />
                    </InformationBlock>
                </MarginTopBlock>

                <MarginTopBlock>
                    <InformationBlock header="Teaching assignment">
                        <TeachingAssignmentsOverview teaching={props.item.teachings} />
                    </InformationBlock>
                </MarginTopBlock>
                <MarginTopBlock>
                    <InformationBlock header="Work undertaken">
                        <WorkUndertakenOverviewItem workUndertaken={props.item.workUndertakens} />
                    </InformationBlock>
                </MarginTopBlock>
                <MarginTopBlock>
                    <InformationBlock header="Employment record">
                        <EmploymentRecordOverviewItem employmentRecord={props.item.employmentRecords} />
                    </InformationBlock>
                </MarginTopBlock>
            </ContainerContent>
        </Container>
    );
};
