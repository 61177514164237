import React, {useMemo, useState} from "react";
import {BlueText} from "./BlueText";

interface ILimitListSizeProps {
    maxSize: number;
    list: JSX.Element[];
}

export const LimitListSize: React.FunctionComponent<ILimitListSizeProps> = (props) => {

    const [size, setSize] = useState<number>(props.maxSize);

    const partialList = useMemo(() => {
        if (size === undefined || size === null) return [];
        if (!props.list || !props.list.length) return [];

        return [...props.list].slice(0, size);
    }, [props.list, size]);

    const flipSize = () => {
        if (size === undefined || size === null || props.maxSize === null || props.maxSize === undefined || !props.list || !props.list.length) return;
        if (size === props.maxSize) return setSize(props.list.length);
        setSize(props.maxSize);
    };

    if (!props.list || !props.list.length) return <div />

    return (
        <>
            {partialList}

            { props.list.length > props.maxSize &&
                <BlueText onClick={() => flipSize()}>
                    {size === props.maxSize ?
                        `Show ${props.list && props.maxSize ? props.list.length - props.maxSize : "-"} more`
                        :
                        "Hide results"
                    }
                </BlueText>
            }
        </>
    );
};
