import Settings from './Settings';
import {IRequestObject} from "./Interfaces/IRequestObject";
import {HttpGet} from 'ibis-design-system/lib/HelperFunctions/HttpHelper';
import {IUserDetails} from "./Api/Dtos/IUserDto";
import { useUnits } from './Hooks/GlobalConfigContext';




const TAKE = 50;


export function getRequests(userId: string, division: string): IRequestObject[] {
    const myUnit = FindUnitId(division);
    return [
        {
            id: 'following',
            request: {
                url: `${Settings.apiUrls.resumes}/resumes/search`,
                filters: [{ type: 'following' }, { type: 'notArchived' }],
                take: TAKE,
            },
        },
        {
            id: 'my-resumes',
            request: {
                url: `${Settings.apiUrls.resumes}/resumes/search`,
                skip: 0,
                take: TAKE,
                filters: [
                    {
                        type: 'user',
                        parameters: [{ name: 'id', value: userId }],
                    },
                    { type: 'notArchived' },
                ],
            },
        },
        {
            id: 'unit',
            request: {
                url: `${Settings.apiUrls.resumes}/resumes/search`,
                skip: 0,
                take: TAKE,
                filters: [
                    {
                        type: 'unit',
                        parameters: [{ name: 'term', value: division }],
                    },
                    { type: 'notArchived' },
                ],
                
            },
        },
        {
            id: 'shared-with-me',
            request: {
                url: `${Settings.apiUrls.resumes}/resumes/search`,
                skip: 0,
                take: TAKE,
                filters: [
                    {
                        type: 'shared-with-me',
                        parameters: [{ name: 'id', value:  userId }],
                    },
                    { type: 'notArchived' },

                ],
            },
        },
        {
            id: 'active-resumes',
            request: {
                url: `${Settings.apiUrls.resumes}/resumes/search`,
                skip: 0,
                take: TAKE,
                filters: [
                    { type: 'notArchived' },
                ],
            },
        },
        {
            id: 'active-projectleads',
            request: {
                url: `${Settings.apiUrls.resumes}/resumes/pending/search`,
                skip: 0,
                take: TAKE,
                filters: [
                    { type: 'notArchived' },
                ],
            },
        },
        {
            id: 'my-mothertongue',
            request: {
                url: `${Settings.apiUrls.resumes}/resumes/pending/search`,
                skip: 0,
                take: TAKE,
                filters: [
                    { type: 'notArchived' },
                ],
            },
        },
        {
            id: 'archived',
            request: {
                url: `${Settings.apiUrls.resumes}/resumes/search`,
                skip: 0,
                take: TAKE,
                filters: [
                    { type: 'archived' },
                ],
            },
        },
        {
            id: 'deleted',
            request: {
                url: `${Settings.apiUrls.resumes}/resumes/search`,
                skip: 0,
                take: TAKE,
                filters: [
                    {     type: 'deleted' },
                    { type: 'notArchived' },
                ],
            },
        },
    ];
}

export async function getUsers(distinctUsers: any, httpCache: any) {
    const userCalls = [];
    for (let i = 0; i < distinctUsers.length; i++) {
        const user = distinctUsers[i];
        userCalls.push(
            await getUser(user, httpCache)
                .then((response) => response)
                .catch((error) => console.log(error))
        );
    }
    
    return Promise.all(userCalls);
}

export async function getUser(id: any, httpCache: any): Promise<IUserDetails> {
    const url = `${Settings.apiUrls.singleSignOn}/users/${id}/details`;
    const userFromCache = httpCache[url];
    
    if (userFromCache) {
        return userFromCache;
    } else {
        return await HttpGet(url)
            .then((response: any) => {
                httpCache[url] = response;
                return response;
            })
            .catch((error: any) => console.log(error));
    }
}

export function feasibilityToText(feasibility: any) {
    if (feasibility < 25) return 'Very unlikely';
    else if (feasibility >= 25 && feasibility < 50) return 'Unlikely';
    else if (feasibility >= 50 && feasibility < 75) return 'Likely';
    else if (feasibility >= 75 && feasibility < 100) return 'Very likely';
    else if (feasibility === 100) return 'Certain';
    return null;
}

export function  FindUnitId(division: string) {
    const units = useUnits();
    return units.filter((unit: { abbreviation: string; }) => unit.abbreviation === division).map((unit) => unit.id)
}

