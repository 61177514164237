import React, { useState, useEffect, createContext, useContext } from 'react';
import Settings from "../Settings";
import {
    IBasicGlobalConfigEntity,
    IGlobalConfigResponse,
    IMinimalGlobalConfigEntity
} from "../Api/Dtos/IBasicGlobalConfigEntity";
import {HttpGet, HttpPost} from "ibis-design-system/lib/HelperFunctions/HttpHelper";
import {ICountryDto} from "../Api/Dtos/ICountryDto";
import { IUnitDto } from '../Api/Dtos/IUnitDto';
import { IDepartmentDto } from '../Api/Dtos/IDepartmentDto';
import { IPersonDto } from '../Api/Dtos/IPersonDto';
import { Contact } from '../Api/Dtos/ICompanyDto';
import { IResumeDto } from '../Api/Dtos/Resume/IResumeDto';

export interface IGlobalConfigContext {
    units: IUnitDto[];
    natures: IBasicGlobalConfigEntity[];
    regions: any[];
    countries: ICountryDto[];
    relationTypes: any[];
    languages: IBasicGlobalConfigEntity[];
    areasOfExpertise: IMinimalGlobalConfigEntity[];
    // The different download options for templates.
    templateDownloadOptions: IBasicGlobalConfigEntity[];
    departments: IDepartmentDto[];
    functionTitle: IResumeDto[];

}

const INITIAL_STATE: IGlobalConfigContext = {
    units: [],
    natures: [],
    regions: [],
    countries: [],
    relationTypes: [],
    languages: [],
    areasOfExpertise: [],
    templateDownloadOptions: [],
    departments: [],
    functionTitle: [],

}

export const GlobalConfigContext = createContext<IGlobalConfigContext>(INITIAL_STATE);

/**
 * Import this hook to get access to all of the Global Config data.
 */
export function useGlobalConfig() {
    return useContext(GlobalConfigContext);
}

export const GlobalConfigProvider: React.FunctionComponent = (props) => {

    const [config, setConfig] = useState<IGlobalConfigContext>(INITIAL_STATE);

    useEffect(() => {
        fetchGlobalConfigData();
    }, []);

    const fetchGlobalConfigData = async (): Promise<void> => {
        HttpGet(`${Settings.apiUrls.globalConfig}/units`).then((response: IGlobalConfigResponse<IUnitDto[]>) =>
        setConfig((prevConfig) => {
            return { ...prevConfig, units: response.entities };
        })
        );

        HttpGet(`${Settings.apiUrls.globalConfig}/organisation-natures`).then((response: IGlobalConfigResponse<IBasicGlobalConfigEntity[]>) =>
            setConfig((prevConfig) => {
                return { ...prevConfig, natures: response.entities };
            })
        );

        HttpGet(`${Settings.apiUrls.globalConfig}/regions`).then((response: IGlobalConfigResponse<any[]>) =>
            setConfig((prevConfig) => {
                return { ...prevConfig, regions: response.entities };
            })
        );

        HttpGet(`${Settings.apiUrls.globalConfig}/countries`).then((response: IGlobalConfigResponse<ICountryDto[]>) =>
            setConfig((prevConfig) => {
                return { ...prevConfig, countries: response.entities };
            })
        );

        HttpGet(`${Settings.apiUrls.globalConfig}/organisation-relations`).then((response: IGlobalConfigResponse<any[]>) =>
            setConfig((prevConfig) => {
                return { ...prevConfig, relationTypes: response.entities };
            })
        );

        HttpGet(`${Settings.apiUrls.globalConfig}/languages`).then((response: IGlobalConfigResponse<IBasicGlobalConfigEntity[]>) =>
            setConfig((prevConfig) => {
                return { ...prevConfig, languages: response.entities };
            })
        );

        HttpGet(`${Settings.apiUrls.globalConfig}/area-of-expertise`).then((response: IGlobalConfigResponse<IMinimalGlobalConfigEntity[]>) =>
            setConfig((prevConfig) => {
                return { ...prevConfig, areasOfExpertise: response.entities };
            })
        );


        HttpGet(`${Settings.apiUrls.globalConfig}/departments`).then((response: IGlobalConfigResponse<IDepartmentDto[]>) =>
        setConfig((prevConfig) => {
            return { ...prevConfig, departments: response.entities };
            })     
        );
        HttpPost(`${Settings.apiUrls.globalConfig}/resume-export-template/templates`, true).then((response: IBasicGlobalConfigEntity[]) =>
        setConfig((prevConfig) => {
            return { ...prevConfig, templateDownloadOptions: response };
            })
        );
    
    };
  
    return (
        <GlobalConfigContext.Provider value={config}>
            {props.children}
        </GlobalConfigContext.Provider>
    );
}

export function useNatures(): any[] {
    const context = useContext(GlobalConfigContext);
    if (context === undefined) {
        throw new Error('useNatures must be used within a GlobalConfigProvider');
    }
    return context.natures;
}

export function useRegions(): any[] {
    const context = useContext(GlobalConfigContext);
    if (context === undefined) {
        throw new Error('useRegions must be used within a GlobalConfigProvider');
    }
    return context.regions;
}

export function useCountries(): ICountryDto[] {
    const context = useContext(GlobalConfigContext);
    if (context === undefined) {
        throw new Error('useCountries must be used within a GlobalConfigProvider');
    }
    return context.countries;
}

export function useRelationTypes(): any[] {
    const context = useContext(GlobalConfigContext);
    if (context === undefined) {
        throw new Error('useRelationTypes must be used within a GlobalConfigProvider');
    }
    return context.relationTypes;
}

export function useLanguages(): IBasicGlobalConfigEntity[] {
    const context = useContext(GlobalConfigContext);
    if (context === undefined) {
        throw new Error('useLanguages must be used within a GlobalConfigProvider');
    }
    return context.languages;
}

export function useAreasOfExpertise(): IMinimalGlobalConfigEntity[] {
    const context = useContext(GlobalConfigContext);
    if (context === undefined) {
        throw new Error('useAreasOfExpertise must be used within a GlobalConfigProvider');
    }
    return context.areasOfExpertise;
}

export function useTemplateDownloadOptions(): IBasicGlobalConfigEntity[] {
    const context = useContext(GlobalConfigContext);
    if (context === undefined) {
        throw new Error('useTemplateDownloadOptions must be used within a GlobalConfigProvider');
    }
    return context.templateDownloadOptions;
}
export function useUnits(): IUnitDto[] {
    const context = useContext(GlobalConfigContext);
    if (context === undefined) {
        throw new Error('useUnits must be used within a GlobalConfigProvider');
    }
    return context.units;
}

export function useDepartments(): IDepartmentDto[] {
    const context = useContext(GlobalConfigContext);
    if (context === undefined) {
        throw new Error('useDepartments must be used within a GlobalConfigProvider');
    }
    return context.departments;
}

export function useFunctionTitle(): IResumeDto[] {
    const context = useContext(GlobalConfigContext);
    if (context === undefined) {
        throw new Error('useFunctionTitle must be used within a GlobalConfigProvider');
    }
    return context.functionTitle;
}