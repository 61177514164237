/**
 * Find the implementation of this interface with the dev settings in public/appSettings.dev.json.
 */

// @ts-ignore
import { SetFileServiceUrls } from 'ibis-helpers/lib/Config';

export interface ISettings {
    apiUrls: APIUrls;
    uiUrls:  UIUrls;
    appId:   string;
    title:   string;
    mapsKey: string;
    version: string;
}

export interface APIUrls {
    singleSignOn: string;
    tenders:      string;
    projects:     string;
    resumes:      string;
    fileService:  string;
    logService:   string;
    globalConfig: string;
    calendars:    string;
    portal:       string;
    cci:          string;
    sources:      string;
    settings:     string;
}

export interface UIUrls {
    singleSignOn: string;
    fileService:  string;
    portal:       string;
}

let Settings: ISettings;

export function SetSettings(json: any) {
    Settings = json;
    SetFileServiceUrls(
        Settings.uiUrls.fileService,
        Settings.apiUrls.fileService
    );
}

export { Settings as default };
