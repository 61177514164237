import React from "react";
import {IContainerProps} from "../../Interfaces/IContainterProps";
import {ITeachingDto} from "../../Api/Dtos/Resume/ITeachingDto";
import {TeachingAssignment} from "./Teaching/TeachingAssignment";
import {CreateTeachingAssignment} from "./Teaching/CreateTeachingAssignment";
import {hasPermission} from "../../Utils/hasPermission";

interface ITeachingProps extends IContainerProps {}

export const Teaching: React.FunctionComponent<ITeachingProps> = (props) => {
    return (
        <div>
            { props.item.teachings && props.item.teachings.map( (teaching: ITeachingDto, index: number) => <TeachingAssignment key={teaching.id} permissions={props.item.userContext} style={{marginTop: index > 0 ? 1 : 0}} teachingAssignment={teaching} patch={props.patch} /> )}
            {hasPermission(props.item.userContext.permissions) &&
                <CreateTeachingAssignment patch={props.patch}/>
            }
        </div>
    );
};
