import React from "react";
import {IContainerProps} from "../../Interfaces/IContainterProps";

import {hasPermission} from "../../Utils/hasPermission";
import { CreateKeyQualifications } from "./KeyQualifications/CreateKeyQualifications";
import { KeyQualificationsItem } from "./KeyQualifications/KeyQualificationsItem";

interface ICreateKeyQualificationsProps extends IContainerProps {
    style?: React.CSSProperties;
}

export const KeyQualifications: React.FunctionComponent<ICreateKeyQualificationsProps> = (props) => {
    return (
        <div style={{paddingTop: 20, paddingLeft: 30, paddingRight: 46}}>
            {hasPermission(props.item.userContext.permissions) &&
                <CreateKeyQualifications patch={props.patch} id={props.item.id}/>
            }
            {props.item.keyQualifications && props.item.keyQualifications.map( (qualification:any) => <KeyQualificationsItem key={qualification.id} permissions={props.item.userContext} keyQualifications={qualification} patch={props.patch}  /> )}
        </div>
    );
};
