import {Component} from "react";

export interface IProgressRingProps {
    // Size of te ring.
    radius: number;
    // Width of the border.
    stroke: number;
    // The percentage of progress made.
    progress: number;
}

/**
 * Displays a ring, of which the border is filled with the amount of progress given in the "progress" prop.
 */
export class ProgressRing extends Component<IProgressRingProps> {

    private readonly normalizedRadius: number;
    private readonly circumference: number;

    constructor(props: IProgressRingProps) {
        super(props);

        const { radius, stroke } = this.props;

        this.normalizedRadius = radius - stroke * 2;
        this.circumference = this.normalizedRadius * 2 * Math.PI;
    }

    render() {
        const { radius, stroke, progress } = this.props;

        const strokeDashoffset = this.circumference - progress / 100 * this.circumference;

        return (
            <div>
                <svg
                    height={radius * 2}
                    width={radius * 2}
                    style={{transform: "rotate(-90deg)"}}
                >
                    <circle
                        stroke="#d4dcfa"
                        fill="transparent"
                        strokeWidth={ stroke }
                        strokeDasharray={ this.circumference + ' ' + this.circumference }
                        style={ { strokeDashoffset: 0 } }
                        r={ this.normalizedRadius }
                        cx={ radius }
                        cy={ radius }
                    />
                    <circle
                        stroke="#0d38e0"
                        fill="transparent"
                        strokeWidth={ stroke }
                        strokeDasharray={ this.circumference + ' ' + this.circumference }
                        style={ { strokeDashoffset } }
                        r={ this.normalizedRadius }
                        cx={ radius }
                        cy={ radius }
                    />
                </svg>
                {this.props.children}
            </div>
        );
    }
}