import React, {useCallback, useEffect, useMemo, useState} from "react";
import {
    getCompanyNameWithSourceId,
    ICompanyIdWithSourceId,
    ICompanyNameWithSourceId
} from "../Utils/getCompanyNameWithSourceId";
import {LimitListSize} from "./LimitListSize";
import {OverviewItemRow} from "./OverviewItemRow";
import moment from "moment";
import {dateYearOrPresent} from "../Utils/dateYearOrPresent";
import {IEmploymentRecordDto} from "../Api/Dtos/Resume/IEmploymentRecordDto";

interface IEmploymentRecordOverviewItemProps {
    employmentRecord: IEmploymentRecordDto[] | null;
}

export const EmploymentRecordOverviewItem: React.FunctionComponent<IEmploymentRecordOverviewItemProps> = (props) => {

    const [employmentRecordCompanies, setEmploymentRecordCompanies] = useState<ICompanyNameWithSourceId[]>();

    useEffect(() => {
        if (!props.employmentRecord) return;
        getAllCompaniesForEducations(props.employmentRecord);
    }, [props.employmentRecord]);

    const getAllCompaniesForEducations = async (employmentRecord: IEmploymentRecordDto[]): Promise<string[] | undefined> => {
        let companyIds: ICompanyIdWithSourceId[] = employmentRecord.map( work => ({companyId: work.employer, sourceId: work.id}) );
        if (!companyIds) return;
        const teachingCompanies = await getCompanyNameWithSourceId(companyIds);
        setEmploymentRecordCompanies(teachingCompanies)
    }

    const companyName = (workId: string, employmentRecordCompanies?: ICompanyNameWithSourceId[]): string => {
        if (!employmentRecordCompanies || !employmentRecordCompanies.length) return "";

        let companyName = employmentRecordCompanies.find( c => c.sourceId === workId)?.companyName;
        if (companyName) return companyName;
        return "";
    };

    if (!props.employmentRecord) {
        return (
            <div />
        );
    }

    return (
        <LimitListSize maxSize={2} list={
            props.employmentRecord && props.employmentRecord.map((work, index) =>
                <OverviewItemRow
                    key={work.id}
                    rootStyles={{marginTop: index > 0 ? 1 : 0}}
                    linkText={`${work.position}`}
                    subText={`${companyName(work.id, employmentRecordCompanies)} (${moment(work.from).year()} - ${dateYearOrPresent(work.to)})`}
                />
            )}
        />
    );
};
