import React from "react";
import {Container} from "./Container";
import {IContainerProps} from "../Interfaces/IContainterProps";
import {PublicationItem} from "../Components/Resume/Publications/PublicationItem";
import {IPublicationDto} from "../Api/Dtos/Resume/IPublicationDto";
import {CreatePublication} from "../Components/Resume/Publications/CreatePublication";
import {hasPermission} from "../Utils/hasPermission";

export const Publications: React.FunctionComponent<IContainerProps> = (props) => {
    const sortPublications = [...props.item.publications!];
    sortPublications.sort((a, b) => {
        if (a.year === null) return -1;
        if (b.year === null) return 1;
        return new Date(b.year!).getTime() - new Date(a.year!).getTime();
    });
    return (
        <Container>
            <div style={{paddingTop: 20, paddingLeft: 30, paddingRight: 46}}>
            { hasPermission(props.item.userContext.permissions) &&
                    <CreatePublication patch={props.patch} user={props.user} />
                }
                {sortPublications.map( (pub: IPublicationDto, index: number) =>
                    <PublicationItem key={pub.id} style={{marginTop: index > 0 ? 1 : 0}} permissions={props.item.userContext} publication={pub} patch={props.patch} />
                )}

            </div>
        </Container>
    );
};
