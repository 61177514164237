import React, {useState} from "react";
import {ICreateTeachingDto} from "../../../Api/Dtos/Resume/ITeachingDto";
import Flex from "ibis-design-system/lib/components/core/Flex";
import {SemiBold} from "../../Bold";
import {IYearPickerDates, YearPicker} from "../../YearPicker";
import TextInput from "ibis-design-system/lib/components/inputFields/TextInput";
import CompanyPicker from "ibis-design-system/lib/components/pickers/CompanyPicker";
import {getCompanies, getCompany} from "../../../Api/requests";
import {AddItemForm} from "../../AddItemForm";
import {companyPickerResult} from "../../../Utils/companyPickerResult";
import {onStateChange} from "../../../Utils/onStateChange";
import {useNatures} from "../../../Hooks/GlobalConfigContext";
import moment from "moment";

const INITIAL_TEACHING_ASSIGNMENT: ICreateTeachingDto = {
    teachingAssignment: undefined,
    teachingInstitute: undefined,
    from: moment().toISOString(),
    to: null,
}

interface ICreateTeachingAssignmentProps {
    patch: (args: any) => void;
}

export const CreateTeachingAssignment: React.FunctionComponent<ICreateTeachingAssignmentProps> = (props) => {

    const natures = useNatures();
    const [teachingAssignment, setTeachingAssignment] = useState<ICreateTeachingDto>(INITIAL_TEACHING_ASSIGNMENT);

    const onChange = (key: keyof ICreateTeachingDto, value: string) => {
        const result = onStateChange<ICreateTeachingDto>(teachingAssignment, key, value);
        if (result) setTeachingAssignment(result);
    }

    const setYears = (dates: IYearPickerDates): void => {
        const tmpTeachingAssignment = {...teachingAssignment};
        tmpTeachingAssignment.from = dates.start;
        tmpTeachingAssignment.to = dates.end;
        setTeachingAssignment(tmpTeachingAssignment);
    }

    const updateTeachingInstitute = (change: any): void => {
        if (!teachingAssignment || !change) {
            return;
        }

        const pickerResult = companyPickerResult(change);
        const tmpTeachingAssignment = {...teachingAssignment};
        tmpTeachingAssignment.teachingInstitute = pickerResult ? pickerResult : undefined;
        setTeachingAssignment(tmpTeachingAssignment)
    }

    return (
        <AddItemForm closeOnAdd addButtonText={"Add teaching"} onAddButtonClick={() => props.patch([
            {
                op: 'add',
                path: `teachings/-`,
                value: teachingAssignment,
            },
        ])} cancel={() => setTeachingAssignment(INITIAL_TEACHING_ASSIGNMENT)}>
            <Flex>
                <div style={{flex: 1, flexDirection: "column"}}>
                    <div style={{marginTop: 5}}>
                        <SemiBold style={{marginBottom: 6.5}}>Period</SemiBold>
                        <YearPicker
                            onChange={(dates: IYearPickerDates) => setYears(dates)}
                            dates={{
                                start: teachingAssignment.from ? teachingAssignment.from : undefined,
                                end: teachingAssignment.to ? teachingAssignment.to : undefined
                            }}
                            style={{minWidth: 130, justifyContent: "space-between"}}
                        />
                    </div>
                </div>
                <div style={{flex: 4, flexDirection: "column"}}>
                    <TextInput
                        label="Teaching assignment"
                        placeholder="Assignment"
                        value={teachingAssignment.teachingAssignment}
                        onChange={(val: string) => onChange("teachingAssignment", val)}
                    />


                    <div style={{marginTop: 20}}>
                        <CompanyPicker
                            label="Teaching assignment"
                            placeholder="Select a education institute"
                            pickLimit={1}
                            exclude={teachingAssignment?.teachingInstitute ? [teachingAssignment?.teachingInstitute] : []}
                            selected={teachingAssignment?.teachingInstitute ? [teachingAssignment?.teachingInstitute] : []}
                            natures={natures}
                            getCompany={getCompany}
                            getCompanies={(input: string) => getCompanies(input, null, null)}
                            onChanged={(change: any) => updateTeachingInstitute(change)}
                        />
                    </div>
                </div>
            </Flex>
        </AddItemForm>
    );
};
