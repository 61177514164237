import React, {useState} from "react";
import Flex from "ibis-design-system/lib/components/core/Flex";
import Box from 'ibis-design-system/lib/components/core/Box';
import {IContainerProps} from "../../Interfaces/IContainterProps";
import {useAreasOfExpertise, useLanguages} from "../../Hooks/GlobalConfigContext";
import Dropdown from 'ibis-design-system/lib/components/inputFields/Dropdown';
import {IOption} from "../../Interfaces/IOption";
import TextInput from "ibis-design-system/lib/components/inputFields/TextInput";
import WYSIWYGEditor from "ibis-design-system/lib/components/inputFields/WYSIWYGEditor";
import {MarginTopBlock} from "../MarginTopBlock";
import {WYSIWYG_dataConversion} from "../../Utils/WYSIWYG_dataConversion";
import {EOP, IPatchParams} from "../../Interfaces/IPatchParams";
import {hasPermission} from "../../Utils/hasPermission";
import {AreaOfExpertisePicker, IAreaOfExpertiseChange} from "./AreaOfExpertisePicker";

const preferredLanguages = ['Dutch', 'English', 'French', 'German', 'Spanish'];

interface IGeneralProps extends IContainerProps {}

export const General: React.FunctionComponent<IGeneralProps> = (props) => {

    const languages = useLanguages();
    const areasOfExpertise = useAreasOfExpertise();
    const [functionTitle, setFunctionTitle] = useState<string | null>(props.item.functionTitle);
    const [CMSReference, setCMSReference] = useState<string | null>(props.item.cmsReference);
    console.log(CMSReference, props.item)

    const createOrRemoveAreaOfExpertise = (a: IAreaOfExpertiseChange): IPatchParams<any, any> => {
        if (a.type === "remove") {
            return {
                op: EOP.Remove,
                path: `areaOfExpertises/${a.id}`,
            }
        }

        return {
            op: EOP.Add,
            path: `areaOfExpertises/-`,
            value: a.id
        }
    }
    
    languages.sort((a, b) => {
      const labelA = a.label;
      const labelB = b.label;
      
      if (preferredLanguages.includes(labelA) && !preferredLanguages.includes(labelB)) {
        return -1; 
      } else if (!preferredLanguages.includes(labelA) && preferredLanguages.includes(labelB)) {
        return 1; 
      } else {
        const indexA = languages.indexOf(a);
        const indexB = languages.indexOf(b);
        return indexA - indexB; 
      }
    });
    

    return (
        <div>
            <Flex style={{width: 1000, gap: 20}}>
                <Box style={{width: 265}}>
                    <Dropdown
                        label="Language of resume"
                        placeholder="Select a language"
                        multiSelect={false}
                        selected={props.item.resumeLanguage}
                        options={languages}
                        onChanged={(change: IOption)  => props.patch([
                            {
                                op: EOP.Replace,
                                path: `/resumeLanguage`,
                                value: change.key,
                            },
                        ])}
                        disabled={!hasPermission(props.item.userContext.permissions)}
                    />
                </Box>
                <Box style={{width: 265}}>
                    <TextInput
                        label="ADB CMS registration number"
                        placeholder="Enter an ADB CMS registration number"
                        value={CMSReference}
                        onChange={(val: string) => setCMSReference(val)}
                        onBlur={() => {
                            props.patch([
                                {
                                    op: EOP.Replace,
                                    path: `/CMSReference`,
                                    value: CMSReference,
                                },
                            ])
                        }}
                        disabled={!hasPermission(props.item.userContext.permissions)}
                    />
                    </Box>
            </Flex>

            <MarginTopBlock style={{width: 420}}>
                <TextInput
                    label="Function title"
                    placeholder="Enter a function title"
                    value={functionTitle}
                    onChange={(val: string) => setFunctionTitle(val)}
                    onBlur={() => {
                        props.patch([
                            {
                                op: EOP.Replace,
                                path: `/functionTitle`,
                                value: functionTitle,
                            },
                        ])
                    }}
                    disabled={!hasPermission(props.item.userContext.permissions)}
                />
            </MarginTopBlock>

            <MarginTopBlock>
                {/* Replace this with a pill input. Key qualifications still have to be added to the global config. */}
                <AreaOfExpertisePicker
                    AreasOfExpertise={areasOfExpertise}
                    label="Area of expertise"
                    disabled={!hasPermission(props.item.userContext.permissions)}
                    selectedAreasOfExpertise={props.item.areaOfExpertises ? props.item.areaOfExpertises : []}
                    onChange={(val) => props.patch(val.map( a => createOrRemoveAreaOfExpertise(a)))}
                />
            </MarginTopBlock>
        </div>
    );
};
