import React, {useEffect, useMemo, useState} from "react";
import { TagPicker, ITag, IBasePickerSuggestionsProps } from '@fluentui/react/lib/Pickers';
import {IBasePickerStyleProps, IBasePickerStyles, IStyleFunctionOrObject} from "office-ui-fabric-react";
import styled from "ibis-design-system/lib/HelperFunctions/ThirdParty/styled-components";
import {IMinimalGlobalConfigEntity} from "../../Api/Dtos/IBasicGlobalConfigEntity";
import {IFoundation} from "../../Interfaces/IFoundation";

const Label = styled.div`
  font-weight: ${(props: IFoundation) => props.theme.fontWeights.semiBold};
  margin-bottom: 9px;
`

export interface IAreaOfExpertiseChange {
    id: string;
    type: "add" | "remove";
    allSelectedTags: ITag[] | undefined;
}

interface IAreaOfExpertisePickerProps {
    // A list of all AreasOfExpertise a user can select.
    AreasOfExpertise: IMinimalGlobalConfigEntity[];
    // An array of the selected AreasOfExpertise.
    selectedAreasOfExpertise: string[];
    // Fired when a user selects a AreaOfExpertise.
    onChange: (selectedAreasOfExpertise: IAreaOfExpertiseChange[]) => void;
    // The label displayed above the input field.
    label?: string;
    // Disabled the picker if true.
    disabled?: boolean;
}

const AreaOfExpertiseToTag = (AreaOfExpertise: IMinimalGlobalConfigEntity): ITag => ({ key: AreaOfExpertise.id, name: AreaOfExpertise.label});

export const AreaOfExpertisePicker: React.FunctionComponent<IAreaOfExpertisePickerProps> = (props) => {

    const [tags, setTags] = useState<ITag[]>([]);

    useEffect(() => {
        if (!props.AreasOfExpertise) {
            setTags([]);
            return;
        }

        setTags(props.AreasOfExpertise.map( AreaOfExpertise => AreaOfExpertiseToTag(AreaOfExpertise)));
    }, [props.AreasOfExpertise])

    const sendSelectedToProps = (changedTags: ITag[] | undefined): void => {

        // If all tags have been removed, send a remove request for all tags.
        if (!changedTags || !changedTags.length && props.selectedAreasOfExpertise) {
            let toRemove = props.selectedAreasOfExpertise.map<IAreaOfExpertiseChange>( s => ({id: s, allSelectedTags: undefined, type: "remove"}));
            props.onChange(toRemove);
            return;
        }

        if (props.selectedAreasOfExpertise && props.selectedAreasOfExpertise.length && changedTags && changedTags.length && props.selectedAreasOfExpertise.length > changedTags.length) {
            // Now that we have verified a tag has been removed, we'll find it and send a patch to remove that tag.
            let removed: string | undefined;
            props.selectedAreasOfExpertise.forEach( area => {
                let found = false;
                changedTags.forEach( (t) => t.key === area ? found = true : undefined);
                if (!found) removed = area;
            });
            if (!removed) return;
            props.onChange([{id: removed, type: "remove", allSelectedTags: changedTags}]);
            return;
        }

        // Down here we know for sure a new tag has been added. So we'll find that language and send a create request to the backend.
        let added: string | undefined;
        changedTags?.forEach( changedTag => {
            if (!props.selectedAreasOfExpertise?.find( t => t === changedTag.key)) added = changedTag.key as string;
        });
        if (!added) return;
        props.onChange([{id: added, type: "add", allSelectedTags: changedTags}]);
    }

    const selectedAreasOfExpertise = useMemo(() => {
        if (!props.selectedAreasOfExpertise || !props.selectedAreasOfExpertise.length) return [];
        let fullAreasOfExpertise: IMinimalGlobalConfigEntity[] = [];
        props.selectedAreasOfExpertise.forEach( kw => {
            let full = props.AreasOfExpertise.find( fkw => fkw.id === kw)
            if (full) fullAreasOfExpertise.push(full);
        });
        return fullAreasOfExpertise.map( AreaOfExpertise => AreaOfExpertiseToTag(AreaOfExpertise));
    }, [props.selectedAreasOfExpertise, props.AreasOfExpertise]);

    const getTextFromItem = (item: ITag) => item.name;

    const listContainsTagList = (tag: ITag, tagList?: ITag[]) => {
        if (!tagList || !tagList.length || tagList.length === 0) {
            return false;
        }
        return tagList.some(compareTag => compareTag.key === tag.key);
    };

    const filterSuggestedTags = (filterText: string, tagList?: ITag[]): ITag[] => {
        return filterText
            ? tags.filter(
                tag => tag.name.toLowerCase().indexOf(filterText.toLowerCase()) === 0 && !listContainsTagList(tag, tagList),
            )
            : [];
    };

    // const sendSelectedToProps = (selectedTags: ITag[] | undefined): void => {
    //     if (!selectedTags || !selectedTags?.length) return props.onChange([]);
    //     const selectedAreasOfExpertise = props.AreasOfExpertise.filter( AreaOfExpertise => AreaOfExpertise.id === selectedTags.find(s => s.key === AreaOfExpertise.id)?.key);
    //     props.onChange(!selectedAreasOfExpertise || !selectedAreasOfExpertise.length ? [] : selectedAreasOfExpertise);
    // }

    const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
        suggestionsHeaderText: 'Suggested areas of expertise',
        noResultsFoundText: 'No areas of expertise found',
    };

    const style: IStyleFunctionOrObject<IBasePickerStyleProps, IBasePickerStyles> | undefined = {
        text: {
            borderLeft: "none",
            borderTop: "none",
            borderRight: "none",
        },
    }

    return (
        <div>
            <Label>{props.label}</Label>
            <TagPicker
                styles={style}
                removeButtonAriaLabel="Remove"
                onResolveSuggestions={filterSuggestedTags}
                getTextFromItem={getTextFromItem}
                pickerSuggestionsProps={pickerSuggestionsProps}
                itemLimit={30}
                selectedItems={selectedAreasOfExpertise}
                onChange={(items?: ITag[] | undefined) => sendSelectedToProps(items)}
                disabled={props.disabled}
            />
        </div>
    );
};
