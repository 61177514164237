import React, {useCallback, useEffect, useMemo, useState} from "react";
import {IBaseProps} from "../../../Interfaces/IBaseProps";
import {EOP, IPatchParams} from "../../../Interfaces/IPatchParams";
import {
    EPublicationAuthorSource,
    IPublicationAuthorDto,
    IPublicationDto
} from "../../../Api/Dtos/Resume/IPublicationDto";
import {ResumeInfoCard} from "../../ResumeInfoCard";
import {IYearPickerDates} from "../../YearPicker";
import {yearPickerDates} from "../../../Utils/yearPickerDates";
import {SemiBold} from "../../Bold";
import TextInput from "ibis-design-system/lib/components/inputFields/TextInput";
import {onStateChange} from "../../../Utils/onStateChange";
import {MT20} from "../../MT20";
import {getUser} from "../../../Misc";
import {useCacheState} from "ibis-design-system/lib/Providers/HttpCacheProvider";
import Persona from "ibis-design-system/lib/components/core/Persona";
import {getPerson} from "../../../Api/requests";
import {IUserDetails} from "../../../Api/Dtos/IUserDto";
import {IPersonDto} from "../../../Api/Dtos/IPersonDto";
import {getInitials, PersonaSize} from "office-ui-fabric-react";
import Flex from 'ibis-design-system/lib/components/Atoms/Flex';
import {EUserSource, IUserOrContact, IUserOrContactPickerChange, UserOrContactPicker} from "../../UserOrContactPicker";
import {IUserContextDto} from "../../../Api/Dtos/Resume/IUserContextDto";

interface IPublicationItemProps extends IBaseProps {
    publication: IPublicationDto;
    patch: (args: IPatchParams<any, any>[]) => void;
    permissions: IUserContextDto;
}

export const PublicationItem: React.FunctionComponent<IPublicationItemProps> = (props) => {

    const httpCache = useCacheState();
    const [editing, setEditing] = useState<boolean>(false);
    const [publication, setPublication] = useState<IPublicationDto>(props.publication);
    const [authors, setAuthors] = useState<{deltares: IUserDetails[], persons: IPersonDto[]}>({deltares: [], persons: []});

    useEffect(() => {
        if (!props.publication.authors) return;
        // getAuthorData(props.publication.authors);
        setPublication(props.publication);
    }, [props.publication]);

    const onChange = (key: keyof IPublicationDto, value: any) => {
        const result = onStateChange<IPublicationDto>(publication, key, value);
        if (result) setPublication(result);
    }

    const updatePublication = (): void => {

        let updateArr: IPatchParams<any, any>[] = [
            {
                op: EOP.Replace,
                path: `publications/${props.publication.id}/title`,
                value: publication.title
            },
            {
                op: EOP.Replace,
                path: `publications/${props.publication.id}/DOI`,
                value: publication.doi
            },
            {
                op: EOP.Replace,
                path: `publications/${props.publication.id}/reference`,
                value: publication.reference
            },
            {
                op: EOP.Replace,
                path: `publications/${props.publication.id}/originallyPublishedIn`,
                value: publication.originallyPublishedIn
            },
            {
                op: EOP.Replace,
                path: `publications/${props.publication.id}/year`,
                value: publication.year
            },
            {
                op: EOP.Replace,
                path: `publications/${props.publication.id}/authors`,
                value: [
                    {
                        authorId:'',
                        source: 0,
                        name: publication.authors
                    }
                ]
            }
            
        ]

        props.patch(updateArr);
        setEditing(false);
    }

    const onDeletePublication = (): void => {
        props.patch([
            {
                op: EOP.Remove,
                path: `/publications/${props.publication.id}`,
            }
        ])
    }
      
  
    const handleClick = useCallback( () => {
        const doi = props.publication.doi;
        window.open(`https://doi.org/${doi}`);
    }, [props.publication.doi]);

    return (
        <ResumeInfoCard
            style={props.style}
            editing={editing}
            setEditing={setEditing}
            period={yearPickerDates(props.publication.year, undefined)}
            startDateOnly
            datePickerText={"Year"}
            updatePeriod={(year: IYearPickerDates) => onChange("year", year.start)}
            onUpdate={updatePublication}
            onDelete={onDeletePublication}
            permissions={props.permissions}
        >
            <SemiBold>Title</SemiBold>
            { editing ?
                <TextInput
                    placeholder="Assignment"
                    value={props.publication?.title}
                    onChange={(val: string) => onChange("title", val)}
                />
                :
                <div>{props.publication.title}</div>
            }
        
            <MT20>
                <SemiBold style={{marginBottom: 5}}>Author(s)</SemiBold>
                { editing ?
                    <TextInput
                        placeholder=""
                        value={props.publication.authors?.map((x:any)=> x.name)}
                        onChange={(val: string) => onChange("authors", val)}
                    />
                    :
                    <div style={{marginTop: 10}}>{props.publication.authors?.map((x:any)=> x.name)}</div>
                }
            </MT20>

            <MT20>
                <SemiBold>Originally published in</SemiBold>
                { editing ?
                    <TextInput
                        placeholder=""
                        value={props.publication?.originallyPublishedIn}
                        onChange={(val: string) => onChange("originallyPublishedIn", val)}
                    />
                    :
                    <div style={{marginTop: 10}}>{props.publication.originallyPublishedIn}</div>
                }
            </MT20>

            <MT20>
                <Flex>
                    <div style={{flex: 1}}>
                        <SemiBold>DOI</SemiBold>
                        { editing ?
                            <TextInput
                                placeholder=""
                                value={props.publication?.doi}
                                onChange={(val: string) => onChange("doi", val)}
                            />
                            :
                            <div style={{marginTop: 10,color: 'rgb(13, 56, 224)'}} onClick={handleClick}>{props.publication.doi}</div>

                        }
                    </div>
                    <div style={{flex: 1, marginLeft: 20}}>
                        <SemiBold>Reference/URL</SemiBold>
                        { editing ?
                            <TextInput
                                placeholder=""
                                value={props.publication?.reference}
                                onChange={(val: string) => onChange("reference", val)}
                            />
                            :
                            <div style={{marginTop: 10}}>{props.publication.reference}</div>
                        }
                    </div>
                </Flex>
            </MT20>
        </ResumeInfoCard>
    );
};
