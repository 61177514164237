export enum ELanguageLevel {
    A1,
    A2,
    B1,
    B2,
    C1,
    C2
}

export enum ELanguageLevelTable{
    MotherTongue,
    Excellent,
    Fair,
}