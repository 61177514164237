import React from "react";
import {IBaseProps} from "../Interfaces/IBaseProps";
import {EditableCardRoot} from "./EditableCardRoot";
import Flex from 'ibis-design-system/lib/components/Atoms/Flex';
import {IContextualMenuProps} from "office-ui-fabric-react";
import {DotMenu} from "./DotMenu";
import Button from "ibis-design-system/lib/components/core/Button";
import {SemiBold} from "./Bold";
import {IYearPickerDates, YearPicker} from "./YearPicker";
import {MT12} from "./MT12";
import {yearFromToString} from "../Utils/yearFromToString";
import moment from "moment";
import {IUserContextDto} from "../Api/Dtos/Resume/IUserContextDto";
import {hasPermission} from "../Utils/hasPermission";
import { selectedEntity } from "../Hooks/selectedEntity";

interface IResumeInfoCardProps extends IBaseProps {
    editing: boolean
    setEditing: (isEditing: boolean) => void;
    period?: IYearPickerDates;
    startDateOnly?: boolean;
    datePickerText?: string;
    updatePeriod?: (dates: IYearPickerDates) => void;
    onUpdate: () => void;
    onDelete?: () => void;
    onCancel?: () => void;
    permissions?: IUserContextDto;
    disablePeriod?: boolean;

}

export const ResumeInfoCard: React.FunctionComponent<IResumeInfoCardProps> = (props) => {
    const urlPath = window.location.pathname;
    const selected = selectedEntity();
    const selectedEntities = selected.getSelection();
    const resumeId = selectedEntities?.map((x:any) => x.id).toString()
    const menuProps: IContextualMenuProps = {
        items: [
            {
                key: 'update',
                text: 'Update',
                onClick: () => props.setEditing(!props.editing),
            },
            {
                key: 'delete',
                text: 'Delete',
                onClick: () => props.onDelete ? props.onDelete() : undefined,
            },
        ],
    };

    return (
        <EditableCardRoot editing={props.editing} style={props.style}>
            <Flex >
                {!props.disablePeriod &&
                <div className="associationsClass" style={{flex: 1, flexDirection: "column"}}>
                    <SemiBold>{props.datePickerText ? props.datePickerText : "Period"}</SemiBold>
                    { props.editing ?
                    <YearPicker dates={props.period} onChange={(dates: IYearPickerDates) => props.updatePeriod!(dates)} displaySingleCalendar={props.startDateOnly} />
                    :
                    <MT12>{ props.startDateOnly ? props.period?.start ? moment(props.period.start).format("yyyy") : "" : yearFromToString(props.period?.start, props.period?.end)}</MT12>
                    }
                </div>
                }
                <div style={{flex: 4, flexDirection: "column"}}>
                    {props.children}
                </div>

                {hasPermission(props.permissions?.permissions) &&
                    <DotMenu style={{position: "absolute", top: 0, right: 0}} menuProps={menuProps}/>
                }

            </Flex>

            { props.editing &&
                <Flex mt={3} mb={1}>
                    <Button variant="primary" onClick={() =>props.onUpdate()}>
                        Update
                    </Button>
                    <span style={{marginLeft: 8}}>
                            <Button onClick={() => {
                                props.setEditing(false);
                                props.onCancel && props.onCancel();
                            }}>Cancel</Button>
                        </span>
                </Flex>
            }

        </EditableCardRoot>
    );
};
