import React, {useEffect, useState} from "react";
import {LimitListSize} from "./LimitListSize";
import {OverviewItemRow} from "./OverviewItemRow";
import moment from "moment";
import {dateYearOrPresent} from "../Utils/dateYearOrPresent";
import {ITeachingDto} from "../Api/Dtos/Resume/ITeachingDto";
import {getCompany} from "../Api/requests";
import {
    getCompanyNameWithSourceId,
    ICompanyIdWithSourceId,
    ICompanyNameWithSourceId
} from "../Utils/getCompanyNameWithSourceId";

interface ITeachingAssignmentsOverviewProps {
    teaching?: ITeachingDto[] | null;
}

interface teachingExperienceIds {
    teachingInstitute: string | undefined | null;
    teachingId: string;
}

export const TeachingAssignmentsOverview: React.FunctionComponent<ITeachingAssignmentsOverviewProps> = (props) => {

    const [companiesForEducation, setCompaniesForEducation] = useState<ICompanyNameWithSourceId[]>();

    useEffect(() => {
        if (!props.teaching) return;
        getAllCompaniesForEducations(props.teaching);
    }, [props.teaching]);

    const getAllCompaniesForEducations = async (teachings: ITeachingDto[]): Promise<string[] | undefined> => {
        let companyIds: ICompanyIdWithSourceId[] = teachings.map( ed => ({companyId: ed.teachingInstitute, sourceId: ed.id}) );
        if (!companyIds) return;
        const teachingCompanies = await getCompanyNameWithSourceId(companyIds);
        setCompaniesForEducation(teachingCompanies)
    }

    if (!props.teaching) {
        return (
            <div />
        );
    }

    return (
        <LimitListSize maxSize={2} list={
            props.teaching && props.teaching.map((teaching, index) =>
                <OverviewItemRow
                    key={teaching.id}
                    rootStyles={{marginTop: index > 0 ? 1 : 0}}
                    linkText={`${teaching.teachingAssignment}`}
                    subText={`${teaching.teachingInstitute && companiesForEducation ? companiesForEducation.find( c => c.sourceId === teaching.id)?.companyName :  ""} (${moment(teaching.from).year()} - ${dateYearOrPresent(teaching.to)})`}
                />
            )}
        />
    );
};
