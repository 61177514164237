import React, {useMemo, useState} from "react";
import {IContextualMenuProps} from "office-ui-fabric-react";
import styled from "ibis-design-system/lib/HelperFunctions/ThirdParty/styled-components";
import {Bold} from "../../Bold";
import {IOption} from "../../../Interfaces/IOption";
import Dropdown from "ibis-design-system/lib/components/inputFields/Dropdown";
import {useLanguages} from "../../../Hooks/GlobalConfigContext";
import {LanguageLevelOptions, LanguageLevelTableOptions} from "../../../Utils/LanguageLevelOptions";
import {ILanguagesDto} from "../../../Api/Dtos/Resume/ILanguagesDto";
import {ELanguageLevel, ELanguageLevelTable} from "../../../Api/Dtos/Resume/ELanguageLevel";
import {EOP, IPatchParams} from "../../../Interfaces/IPatchParams";
import {DotMenu} from "../../DotMenu";
import {hasPermission} from "../../../Utils/hasPermission";
import {IUserContextDto} from "../../../Api/Dtos/Resume/IUserContextDto";

const Item= styled.div`
  margin-top: 1px;
  display: flex;
  background-color: ${(props: any) => props.usingAsCreateForm ? undefined : "white"};
  padding: 10px;
  padding-bottom: 27px;
`;
const Flex1 = styled.div`
    Flex: 1;
    display: block;
    margin-left: 10px;
`;
const Flex2 = styled.div`
    Flex: 2;
    display: block;
`;

interface ILanguagesProps {
    patch: (params: IPatchParams<string, keyof ILanguagesDto | string>[]) => void
    languages: ILanguagesDto;
    usingAsCreateForm?: boolean;
    userContext: IUserContextDto;
}

export const LanguageLevel: React.FunctionComponent<ILanguagesProps> = (props) => {

    const languages = useLanguages();
    const [editing, setEditing] = useState<boolean>(props.usingAsCreateForm ? props.usingAsCreateForm : false);

    const languageName = useMemo(() => languages.find(lg => lg.id === props.languages.language)?.label, [languages, props.languages])

    const menuProps: IContextualMenuProps = {
        items: [
            {
                key: 'update',
                text: 'Update',
                onClick: () => setEditing(!editing),
            },
            {
                key: 'delete',
                text: 'Delete',
                onClick: () => props.patch([{
                    op:EOP.Remove,
                    path: `languages/${props.languages.id}`,
                }]),
            },
        ],
    };

    return (
        <Item usingAsCreateForm={props.usingAsCreateForm}>
            <Flex2>
                <Bold style={{height: 35}}>Name</Bold>

                <div style={{marginTop: editing ? 15 : 28, width: 162}}>
                    { editing ?
                        <Dropdown
                            placeholder="Select a language"
                            multiSelect={false}
                            selected={props.languages.language}
                            options={languages}
                            onChanged={(change: IOption)  => props.patch([
                                    {
                                        op: EOP.Replace,
                                        path: props.usingAsCreateForm ? 'language' : `Languages/${props.languages.id}/language`,
                                        value: change.key,
                                    },
                                ])
                            }
                        />
                        :
                        languageName
                    }
                </div>
            </Flex2>

            <Flex1>
                <Bold style={{height: 35}}>Writing</Bold>

                <div style={{marginTop: editing ? 15 : 28, width: 89}}>
                    { editing ?
                        <Dropdown
                            placeholder="Level"
                            multiSelect={false}
                            selected={props.languages.writing}
                            options={LanguageLevelTableOptions}
                            onChanged={(change: IOption) => props.patch([
                                    {
                                        op: EOP.Replace,
                                        path: props.usingAsCreateForm ? 'writing' : `Languages/${props.languages.id}/writing`,
                                        value: change.key,
                                    },
                                ])
                            }
                        />
                        :
                        props.languages.writing !== null && props.languages.writing !== undefined  ? ELanguageLevelTable[props.languages.writing] : ""
                    }
                </div>
            </Flex1>
            <Flex1>
                <Bold style={{height: 35}}>Reading</Bold>

                <div style={{marginTop: editing ? 15 : 28, width: 89}}>
                    { editing ?
                        <Dropdown
                            placeholder="Level"
                            multiSelect={false}
                            selected={props.languages.reading}
                            options={LanguageLevelTableOptions}
                            onChanged={(change: IOption)  => props.patch([
                                    {
                                        op: EOP.Replace,
                                        path: props.usingAsCreateForm ? 'reading' :  `Languages/${props.languages.id}/reading`,
                                        value: change.key,
                                    },
                                ])
                            }
                        />
                        :
                        props.languages.reading !== null && props.languages.reading !== undefined ? ELanguageLevelTable[props.languages.reading] : ""
                    }
                </div>
            </Flex1>
            <Flex1>
                <Bold style={{height: 35}}>Speaking</Bold>

                <div style={{marginTop: editing ? 15 : 28, width: 89}}>
                    { editing ?
                        <Dropdown
                            placeholder="Level"
                            multiSelect={false}
                            selected={props.languages.speaking}
                            options={LanguageLevelTableOptions}
                            onChanged={(change: IOption) => props.patch([
                                    {
                                        op: EOP.Replace,
                                        path: props.usingAsCreateForm ? 'speaking' : `Languages/${props.languages.id}/speaking`,
                                        value: change.key,
                                    },
                                ])
                            }
                        />
                        :
                        props.languages.speaking !== null && props.languages.speaking !== undefined  ? ELanguageLevelTable[props.languages.speaking] : ""
                    }
                </div>
            </Flex1>
            <Flex1 style={{position: "relative"}}>
                {!props.usingAsCreateForm && hasPermission(props.userContext.permissions) ?
                    <DotMenu style={{position: "absolute", top: -4, right: -2}} menuProps={menuProps} />
                    :
                    null
                }
            </Flex1>
        </Item>
    );
};
