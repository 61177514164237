import React from "react";
import {Bold} from "../Bold";
import styled from "ibis-design-system/lib/HelperFunctions/ThirdParty/styled-components";
import {ISmallIconWithTextProps, SmallIconWithText} from "../SmallIconWithText";

const Flex1 = styled.div`
    flex: 1;
    display: block;
`;
const Content = styled.div`
    // margin-top: 6px;
`

interface IPersonalDataListProps {
    // The header - Bold text displayed above the list.
    header: string;
    // A list of object containing the data to render the list items.
    content?: ISmallIconWithTextProps[];
    // Optional style for root element.
    style?: React.CSSProperties;
    // Optional style for the root of the list elements.
    contentStyle?: React.CSSProperties;
}

export const PersonalDataList: React.FunctionComponent<IPersonalDataListProps> = (props) => {
    return (
        <Flex1 style={props.style}>
            <Bold>{props.header}</Bold>
            <Content style={props.contentStyle}>
                {props.content && props.content.map( (small, index) => <SmallIconWithText key={index} style={{marginTop: 8}} {...small} />)}
            </Content>
        </Flex1>
    );
};
