import React, {useEffect, useState} from "react";
import {ICompanyDto} from "../Api/Dtos/ICompanyDto";
import {getCompany} from "../Api/requests";
import {request} from "../Utils/request";

export const useCompany = (id: string | null | undefined) => {

    const [companyId, setCompanyId] = useState<string | null | undefined>(id);
    const [company, setCompany] = useState<ICompanyDto>();
    const [companies, setCompanies] = useState<(ICompanyDto | undefined)[]>([]);

    useEffect(() => {
        if (!companyId) return;

        fetchCompany(companyId)
    }, [companyId]);

    const fetchCompany = async (id: string, returnResult = false): Promise<void | ICompanyDto> => {
        if (!id) return;
        const {result, error} = await request(getCompany(id));

        if (!result || error) {
            console.error(`Something went wrong attempting to fetch company with id ${id}.`);
            console.error(error);
            return;
        }

        if (returnResult) return result;
        setCompany(result);
    }

    const fetchCompanies = (companyIds?: string[]) => {
        if (!companyIds) return;

        Promise.all(companyIds.map(async c => fetchCompany(c, true))).then( result => setCompanies(result as ICompanyDto[]));
    }

    const companyNames = companies.map((company) => company?.name || "");

    return {
        company,
        companies,
        fetchCompanies,
        companyNames,
        setCompanyId
    }
}