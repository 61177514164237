import React, { useState } from "react";
import { ICreateKeyQualifications } from "../../../Api/Dtos/Resume/IKeyQualificationsDto";
import { EOP, IPatchParams } from "../../../Interfaces/IPatchParams";
import { onStateChange } from "../../../Utils/onStateChange";
import {AddItemForm} from "../../AddItemForm";
import WYSIWYGEditor from "ibis-design-system/lib/components/inputFields/WYSIWYGEditor";
import {MarginTopBlock} from "../../MarginTopBlock";
import { WYSIWYG_dataConversion } from "../../../Utils/WYSIWYG_dataConversion";
import TextInput from "ibis-design-system/lib/components/inputFields/TextInput";
const INITIAL_RECORD: ICreateKeyQualifications = {
    qualification: undefined,
    title:undefined,
    resumeId: undefined,
}

interface ICreateKeyQualificationsProps {
    patch: (args: IPatchParams<ICreateKeyQualifications, string>[]) => void;
    id: string;
}

export const CreateKeyQualifications: React.FunctionComponent<ICreateKeyQualificationsProps> = (props) => {
    const [keyQualifications, setKeyQualifications] = useState<ICreateKeyQualifications>(INITIAL_RECORD);

    const onChange = (key: keyof ICreateKeyQualifications, value: any) => {
        const result = onStateChange<ICreateKeyQualifications>(keyQualifications, key, value);
        if (result) setKeyQualifications(result);
    }

    const updateQualifications = (change: any): void => {
        if (!keyQualifications || !change) { return; }
        const tmpKeyQualifications = {...keyQualifications};
        setKeyQualifications(tmpKeyQualifications)
    }

    const createKeyQualifications = (): void => {
        const tmpKeyQualifications = {...keyQualifications};
        props.patch([
            {
                op: EOP.Add,
                path: "keyQualifications/-",
                value: tmpKeyQualifications
            }
        ]);
        setKeyQualifications(INITIAL_RECORD)
    }

    return(
        <AddItemForm
            addButtonText={"Add Key Qualification"}
            onAddButtonClick={createKeyQualifications}
            cancel={() => setKeyQualifications(INITIAL_RECORD)}
            closeOnAdd
        >
            <MarginTopBlock>
            <TextInput
                        label="Title"
                        placeholder="Title of key Qualification"
                        value={keyQualifications.title}
                        onChange={(val: string) => onChange("title", val)}
                    />
                <WYSIWYGEditor
                    style={{display: "block !important"}}
                    content={WYSIWYG_dataConversion(keyQualifications.qualification).replace(/<\/?p>/g, '')}
                    onUpdate={(val: string) => onChange("qualification", val)}
                    label="Key Qualification"
                />
            </MarginTopBlock>
        </AddItemForm>
    );
};