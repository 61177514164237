import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import Header from 'ibis-design-system/lib/components/core/Header/Header';
import Box from 'ibis-design-system/lib/components/Atoms/Box/Box';
import Button from 'ibis-design-system/lib/components/core/Button';
import Flex from 'ibis-design-system/lib/components/core/Flex';
import MessageBar from 'ibis-design-system/lib/components/core/MessageBar';
import Layout from 'ibis-design-system/lib/components/templates/Layout';
import Menu from 'ibis-design-system/lib/components/core/Menu';
import CollectionHeader from 'ibis-design-system/lib/components/core/CollectionHeader';
import {useUserState} from 'ibis-design-system/lib/Providers/LoginProvider';
import Settings from './Settings';
import Search from './Search';
import {getMenuItems} from "./Menu";
import {ApplicationsContext} from 'ibis-design-system/lib/Providers/ApplicationsProvider';
import {useNavigationState} from 'ibis-design-system/lib/Providers/NavigationProvider';
import {getMenuItemById} from 'ibis-design-system/lib/components/core/Menu';
import DetailViewContainer from 'ibis-design-system/lib/Containers/DetailViewContainer';
import {IMenuItem} from "./Interfaces/IMenuItem";
import {IApplication} from "./Interfaces/IApplication";
import {
    collectionHeader,
    getCollectionTabs,
    getStateIcon,
    mapEntityTypeToCollectionType, PRIVATE_PERMISSION_MODE,
    PUBLIC_PERMISSION_MODE,
    SHARED_PERMISSION_MODE
} from "./Constants";
import CollectionContainer from 'ibis-design-system/lib/Containers/CollectionContainer';
import {getRequests} from "./Misc";
import {TenderMenuCard} from "./Components/TenderMenuCard";
import styled from 'ibis-design-system/lib/HelperFunctions/ThirdParty/styled-components';
import ToolbarDesignSystem from 'ibis-design-system/lib/components/Molecules/Toolbar';

import {UserContext} from 'ibis-design-system/lib/Providers/LoginProvider';
import {NavigationContext} from 'ibis-design-system/lib/Providers/NavigationProvider';
import Detailview from 'ibis-design-system/lib/components/core/Detailview';

import useWatchers from 'ibis-design-system/lib/Hooks/useWatchers';
import WatcherProvider from 'ibis-design-system/lib/Providers/WatcherProvider';
import {
    archiveResume,
    createNewResume,
    followResume,
    getDeltaresUserDetails,
    unArchiveResume,
    updatePermissionMode
} from "./Api/requests";

import {createActionArray} from "./Utils/CreateActionArray";
import {request} from "./Utils/request";
import {GetDetailTabs} from './Components/GetDetailTabs';
import {IResumeDto} from "./Api/Dtos/Resume/IResumeDto";
import {IPatchParams} from "./Interfaces/IPatchParams";
import {IUserDto} from "./Api/Dtos/IUserDto";
import {getNameForResume} from "./Utils/getNameForResume";
import {EPermission} from "./Api/Dtos/Resume/EPermission";
import {hasPermission} from "./Utils/hasPermission";
import {selectedEntity} from "./Hooks/selectedEntity";
import ExportMenu from "./Components/ExportMenu";

const DetailViewBlock = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

function App() {

    const [filters, setFilters] = useState([]);
    const [panelContent, setPanelContent] = useState(null);
    const [collectionTitle, setCollectionTitle] = useState('');
    // const [overflowActive, setOverflowActive] = useState(false);
    const [overflowActive] = useState(false);
    const {
        collectionId,
        navigateToCollection,
        navigateToCollectionNewEntity,
        setDocumentTitleApplication,
        setDocumentTitleCollection,
        collectionTab,
        selection,
        entities,
        updateEntities,
        setEntities,
        setSelection,
        navigateToEntity,
        navigateToErrorPage
    } = useNavigationState();
    const user = useUserState();
    const [exportMenuIsOpen, setExportMenuIsOpen] = useState<boolean>(false);
    
    const selected = selectedEntity();
    const requests = useMemo(() => getRequests(user.id, user.division), [user.id]);
    const ref = useRef(null);
    const watchers = useWatchers(Settings.appId, getDeltaresUserDetails);

    useEffect(() => {
        const menuItem = getMenuItemById(getMenuItems(''), collectionId);

        if (menuItem) {
            if (setDocumentTitleApplication) setDocumentTitleApplication('Resumes');
            setDocumentTitleCollection(menuItem.name);
            setCollectionTitle(menuItem.name);
        } else {
            if (setDocumentTitleApplication) setDocumentTitleApplication('Resumes');
        }
    }, [collectionId, setDocumentTitleApplication, setDocumentTitleCollection]);

    const flipFollowing = useCallback(() => {
        const selectedEntities = selected.getSelection();
        if (!selectedEntities) return;
        const followingEntities = selectedEntities.map( en => {
            en.isFollowing = !en.isFollowing;
            followResume(en.id, en.isFollowing);
            return en;
        });
        updateEntities(followingEntities);
    }, [updateEntities, selected]);
    const archive = useCallback(() => {
        const selectedEntities = selected.getSelection();
        if (!selectedEntities) return;
        const archivedEntities = selectedEntities.map( en => {
            en.isArchived = true;
            // make this pretier this is not the way
            en.isState !== 0  ? archiveResume(en.id) : unArchiveResume(en.id)
            en.isState === 0 ? archiveResume(en.id) : unArchiveResume(en.id)
            return en;
            
        }     
        );
        const newEntities = entities.filter((e:IResumeDto) => !archivedEntities.filter( a => a.id === e.id).length)    
        setSelection([])
        setEntities(newEntities)
    }, [updateEntities, selected]);

    const actions = useMemo(() => {
        return createActionArray(selection, entities, () => flipFollowing(), () => archive(), setExportMenuIsOpen)
    }, [entities, selection, flipFollowing]);

    const addNewResume = async (): Promise<void> => {
        const {result, error} = await request(createNewResume());
        if (error || !result) {
            console.log("Something went wrong attempting to create a new resume.");
            return;
        }
        navigateToCollectionNewEntity('active-resumes', 'activity');
        setEntities((x: any) => (x ? [result, ...x] : [result]));
        setSelection([0]);
        navigateToEntity(result.id, 'resume', 'overview');
    }

    function onUpdatePermissionMode(state: string, id: string, oldPermissionMode: string) {
        updateEntities([
            {
                id: id,
                permissionMode: state,
            },
        ]);
        updatePermissionMode(state, id).catch((error: any) => {
            updateEntities([
                {
                    id: id,
                    permissionMode: oldPermissionMode,
                },
            ]);
            navigateToErrorPage({code: error.code, navigateBack: false});
        });
    }

    return (
        <Box>
            <Layout>
                <Layout.Header>
                    <ApplicationsContext.Consumer>
                        {(applications: IApplication[]) => (
                            <Header
                                user={user}
                                singleSignOutUrl={Settings.uiUrls.singleSignOn + '/signout'}
                                applicationName={Settings.title}
                                portalUrl={Settings.uiUrls.portal}
                                notificationsApiUrl={Settings.apiUrls.portal}
                                applications={applications}
                                searchComponent={<Search setFilters={setFilters}/>}
                                onToolsButtonClick={setPanelContent}
                            />
                        )}
                    </ApplicationsContext.Consumer>
                </Layout.Header>
                <Layout.PrimaryToolbarButton>
                    <ExportMenu  item={entities}isOpen={exportMenuIsOpen} exportOptionsUrl={"test"} onClose={() => setExportMenuIsOpen(false)} />
                    <Button icon="add" variant="primary" onClick={addNewResume}>Add new</Button>
                </Layout.PrimaryToolbarButton>
                <Layout.Toolbar>
                    <ToolbarDesignSystem items={actions}/>
                </Layout.Toolbar>
                <Layout.Menu>
                    <Menu
                        id="tenderMenu"
                        items={getMenuItems(user.name)}
                        selected={collectionId}
                        onLinkClick={(item: IMenuItem) => navigateToCollection(item.id)}
                    />
                </Layout.Menu>
                { collectionId ?
                    <Layout.Collection>
                        {/* The sorting tabs */}
                        <CollectionHeader
                            title={collectionTitle}
                            tabs={getCollectionTabs()}
                            selectedTab={collectionTab}
                            onTabSelected={(tab: string) => {
                                navigateToCollection(collectionId, tab);
                            }}
                        >
                            {/* The list with Tender cards on the left side of the screen. */}
                            <CollectionContainer
                                key={collectionId}
                                collectionId={collectionId}
                                requests={requests}
                                searchFilters={filters}
                                onRenderItem={(item: IResumeDto, isSelected: () => void, onToggleItem: Function, onRowClick: any) =>
                                    <TenderMenuCard item={item} onToggleItem={onToggleItem} isSelected={isSelected}
                                                    onRowClick={onRowClick} user={user}/>
                                }
                            />
                        </CollectionHeader>
                    </Layout.Collection>
                : null}
                <Layout.Detailview>
                    <DetailViewContainer
                        backendUrl={`${Settings.apiUrls.resumes}`}
                        getItemType={mapEntityTypeToCollectionType}
                        actions={actions}
                        renderDetailView={(item: IResumeDto, type: any, patch: (args: IPatchParams<any, any>[]) => void, setMessage: any, userMessages: any) => {
                            return (
                                <Flex height="100%" width="100%" flexDirection="column">
                                    {userMessages.map((message: any) => (
                                        <MessageBar
                                            key={message.id}
                                            type={message.type}
                                            onDismiss={message.onDismiss}
                                        >
                                            {message.text}
                                        </MessageBar>
                                    ))}
                                    <DetailViewBlock>
                                        <WatcherProvider watchers={watchers}>
                                            <UserContext.Consumer>
                                                {(u: IUserDto) => (
                                                    <NavigationContext.Consumer>
                                                        {({entityTab, navigateToEntity, selection}: any) => (
                                                            <WatcherProvider watchers={watchers}>
                                                                <Detailview
                                                                    forwardedRef={ref}
                                                                    ipadPortrait={overflowActive}
                                                                    title={{
                                                                        gradient: 'none',
                                                                        showCoin: true,
                                                                        imageUrl: item.user?.photo,
                                                                        text: getNameForResume(item.user?.name, item.scienceTitlePrefix, item.scienceTitleSuffix),
                                                                        subText: <div style={{
                                                                            fontWeight: 'lighter',
                                                                            fontSize: 15
                                                                        }}>{item.functionTitle}</div>,
                                                                    }}
                                                                    messages={user.userMessages}
                                                                    selectedKey={entityTab}
                                                                    onTabClick={(innerItem: any) => navigateToEntity(item.id, type, innerItem.itemKey, selection[0])}
                                                                    tabs={GetDetailTabs(overflowActive, item, type, setMessage, patch, u)}
                                                                    actions={[
                                                                        {
                                                                            key: 'resumeAccessSettingsButton',
                                                                            icon: getStateIcon(item.permissionMode),
                                                                            title: `Shared with: ${item.permissionMode}`,
                                                                            items: [
                                                                                {
                                                                                    key: PUBLIC_PERMISSION_MODE,
                                                                                    text: 'Public (default)',
                                                                                    onClick: () => onUpdatePermissionMode(PUBLIC_PERMISSION_MODE, item.id, item.permissionMode),
                                                                                    canCheck: true,
                                                                                    disabled: !hasPermission(item.userContext?.permissions, EPermission.SetPermissionsMode),
                                                                                    isChecked: item.permissionMode === PUBLIC_PERMISSION_MODE,
                                                                                    iconProps: {
                                                                                        iconName: getStateIcon(PUBLIC_PERMISSION_MODE),
                                                                                    },
                                                                                },
                                                                                {
                                                                                    key: SHARED_PERMISSION_MODE,
                                                                                    text: 'Selected people',
                                                                                    onClick: () => onUpdatePermissionMode(SHARED_PERMISSION_MODE, item.id, item.permissionMode),
                                                                                    disabled: !hasPermission(item.userContext?.permissions, EPermission.SetPermissionsMode),
                                                                                    canCheck: true,
                                                                                    isChecked: item.permissionMode === SHARED_PERMISSION_MODE,
                                                                                    iconProps: {
                                                                                        iconName: getStateIcon(SHARED_PERMISSION_MODE),
                                                                                    },
                                                                                },
                                                                                {
                                                                                    key: PRIVATE_PERMISSION_MODE,
                                                                                    text: 'Private',
                                                                                    onClick: () => onUpdatePermissionMode(PRIVATE_PERMISSION_MODE, item.id, item.permissionMode),
                                                                                    canCheck: true,
                                                                                    disabled: !hasPermission(item.userContext?.permissions, EPermission.SetPermissionsMode),
                                                                                    isChecked: item.permissionMode === PRIVATE_PERMISSION_MODE,
                                                                                    iconProps: {
                                                                                        iconName: getStateIcon(PRIVATE_PERMISSION_MODE),
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    ]}
                                                                />
                                                            </WatcherProvider>
                                                        )}
                                                    </NavigationContext.Consumer>
                                                )}
                                            </UserContext.Consumer>
                                        </WatcherProvider>
                                    </DetailViewBlock>
                                </Flex>
                            );
                        }}
                    />
                </Layout.Detailview>
                {panelContent ? <Layout.PanelContent>{panelContent}</Layout.PanelContent> : null}
            </Layout>
        </Box>
    );
}

export default App;
function setShow(selectedEntities: IResumeDto[]) {
    throw new Error('Function not implemented.');
}

