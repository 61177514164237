import React, {useMemo, useState} from "react";
import {IContextualMenuProps} from "office-ui-fabric-react";
import styled from "ibis-design-system/lib/HelperFunctions/ThirdParty/styled-components";
import {Bold} from "../../Bold";
import {IOption} from "../../../Interfaces/IOption";
import Dropdown from "ibis-design-system/lib/components/inputFields/Dropdown";
import {useLanguages} from "../../../Hooks/GlobalConfigContext";
import {LanguageLevelOptions} from "../../../Utils/LanguageLevelOptions";
import {IEuropassLanguageDto} from "../../../Api/Dtos/Resume/IEuropassLanguageDto";
import {ELanguageLevel} from "../../../Api/Dtos/Resume/ELanguageLevel";
import {EOP, IPatchParams} from "../../../Interfaces/IPatchParams";
import {DotMenu} from "../../DotMenu";
import {hasPermission} from "../../../Utils/hasPermission";
import {IUserContextDto} from "../../../Api/Dtos/Resume/IUserContextDto";

const Item= styled.div`
  margin-top: 1px;
  display: flex;
  background-color: ${(props: any) => props.usingAsCreateForm ? undefined : "white"};
  padding: 10px;
  padding-bottom: 27px;
`;
const Flex1 = styled.div`
    Flex: 1;
    display: block;
    margin-left: 10px;
`;
const Flex2 = styled.div`
    Flex: 2;
    display: block;
`;

interface IEuropassLanguageProps {
    patch: (params: IPatchParams<string, keyof IEuropassLanguageDto | string>[]) => void
    europass: IEuropassLanguageDto;
    usingAsCreateForm?: boolean;
    userContext: IUserContextDto;
}

export const EuropassLanguage: React.FunctionComponent<IEuropassLanguageProps> = (props) => {

    const languages = useLanguages();
    const [editing, setEditing] = useState<boolean>(props.usingAsCreateForm ? props.usingAsCreateForm : false);

    const languageName = useMemo(() => languages.find(lg => lg.id === props.europass.language)?.label, [languages, props.europass])

    const menuProps: IContextualMenuProps = {
        items: [
            {
                key: 'update',
                text: 'Update',
                onClick: () => setEditing(!editing),
            },
            {
                key: 'delete',
                text: 'Delete',
                onClick: () => props.patch([{
                    op:EOP.Remove,
                    path: `europassLanguages/${props.europass.id}`,
                }]),
            },
        ],
    };

    return (
        <Item usingAsCreateForm={props.usingAsCreateForm}>
            <Flex2>
                <Bold style={{height: 35}}>Name</Bold>

                <div style={{marginTop: editing ? 15 : 28, width: 162}}>
                    { editing ?
                        <Dropdown
                            placeholder="Select a language"
                            multiSelect={false}
                            selected={props.europass.language}
                            options={languages}
                            onChanged={(change: IOption)  => props.patch([
                                    {
                                        op: EOP.Replace,
                                        path: props.usingAsCreateForm ? 'language' : `EuropassLanguages/${props.europass.id}/language`,
                                        value: change.key,
                                    },
                                ])
                            }
                        />
                        :
                        languageName
                    }
                </div>
            </Flex2>

            <Flex1>
                <Bold style={{height: 35}}>Listening</Bold>

                <div style={{marginTop: editing ? 15 : 28, width: 89}}>
                    { editing ?
                        <Dropdown
                            placeholder="Level"
                            multiSelect={false}
                            selected={props.europass.listening}
                            options={LanguageLevelOptions}
                            onChanged={(change: IOption) => props.patch([
                                    {
                                        op: EOP.Replace,
                                        path: props.usingAsCreateForm ? 'listening' : `EuropassLanguages/${props.europass.id}/listening`,
                                        value: change.key,
                                    },
                                ])
                            }
                        />
                        :
                        props.europass.listening !== null && props.europass.listening !== undefined  ? ELanguageLevel[props.europass.listening] : ""
                    }
                </div>
            </Flex1>
            <Flex1>
                <Bold style={{height: 35}}>Reading</Bold>

                <div style={{marginTop: editing ? 15 : 28, width: 89}}>
                    { editing ?
                        <Dropdown
                            placeholder="Level"
                            multiSelect={false}
                            selected={props.europass.reading}
                            options={LanguageLevelOptions}
                            onChanged={(change: IOption)  => props.patch([
                                    {
                                        op: EOP.Replace,
                                        path: props.usingAsCreateForm ? 'reading' :  `EuropassLanguages/${props.europass.id}/reading`,
                                        value: change.key,
                                    },
                                ])
                            }
                        />
                        :
                        props.europass.reading !== null && props.europass.reading !== undefined ? ELanguageLevel[props.europass.reading] : ""
                    }
                </div>
            </Flex1>
            <Flex1>
                <Bold style={{height: 35}}>Spoken interaction</Bold>

                <div style={{marginTop: editing ? 15 : 28, width: 89}}>
                    { editing ?
                        <Dropdown
                            placeholder="Level"
                            multiSelect={false}
                            selected={props.europass.spokenInteraction}
                            options={LanguageLevelOptions}
                            onChanged={(change: IOption) => props.patch([
                                    {
                                        op: EOP.Replace,
                                        path: props.usingAsCreateForm ? 'spokenInteraction' : `EuropassLanguages/${props.europass.id}/spokenInteraction`,
                                        value: change.key,
                                    },
                                ])
                            }
                        />
                        :
                        props.europass.spokenInteraction !== null && props.europass.spokenInteraction !== undefined  ? ELanguageLevel[props.europass.spokenInteraction] : ""
                    }
                </div>
            </Flex1>
            <Flex1>
                <Bold style={{height: 35}}>Spoken production</Bold>

                <div style={{marginTop: editing ? 15 : 28, width: 89}}>
                    { editing ?
                        <Dropdown
                            placeholder="Level"
                            multiSelect={false}
                            selected={props.europass.spokenProduction}
                            options={LanguageLevelOptions}
                            onChanged={(change: IOption) => props.patch([
                                    {
                                        op: EOP.Replace,
                                        path: props.usingAsCreateForm ? 'spokenProduction' : `EuropassLanguages/${props.europass.id}/spokenProduction`,
                                        value: change.key,
                                    },
                                ])
                            }
                        />
                        :
                        props.europass.spokenProduction !== null && props.europass.spokenProduction !== undefined  ? ELanguageLevel[props.europass.spokenProduction] : ""
                    }
                </div>
            </Flex1>
            <Flex1 style={{position: "relative"}}>
                <Bold style={{height: 35}}>Writing</Bold>

                <div style={{marginTop: editing ? 15 : 28, width: 89}}>
                    { editing ?
                        <Dropdown
                            placeholder="Level"
                            multiSelect={false}
                            selected={props.europass.writing}
                            options={LanguageLevelOptions}
                            onChanged={(change: IOption)  => props.patch([
                                    {
                                        op: EOP.Replace,
                                        path: props.usingAsCreateForm ? 'writing' : `EuropassLanguages/${props.europass.id}/writing`,
                                        value: change.key,
                                    },
                                ])
                            }
                        />
                        :
                        props.europass.writing !== null && props.europass.writing !== undefined  ? ELanguageLevel[props.europass.writing] : ""
                    }
                </div>

                {!props.usingAsCreateForm && hasPermission(props.userContext.permissions) ?
                    <DotMenu style={{position: "absolute", top: -4, right: -2}} menuProps={menuProps} />
                    :
                    null
                }
            </Flex1>
        </Item>
    );
};
