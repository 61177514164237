import React, {useEffect, useState} from "react";
import {IContainerProps} from "../Interfaces/IContainterProps";
import UserPicker from "ibis-design-system/lib/components/pickers/UserPicker";
import Button from "ibis-design-system/lib/components/core/Button";
import Box from "ibis-design-system/lib/components/core/Box";
import Settings from "../Settings";
import {IUserDetails} from "../Api/Dtos/IUserDto";
import {getUser} from "../Misc";
import {useCacheState} from "ibis-design-system/lib/Providers/HttpCacheProvider";
import PersonaWithCard from "ibis-design-system/lib/components/core/PersonaWithCard";
import {getImageInitials} from "../Utils/getImageInitials";
import {createContact} from "../Api/requests";
import {request} from "../Utils/request";
import {IResumeContactPersonCreateDto} from "../Api/Dtos/Resume/IContactPersonDto";

export const Contacts: React.FunctionComponent<IContainerProps> = (props) => {

    const httpCache = useCacheState();
    const [adding, setAdding] = useState<boolean>(false);
    const [users, setUsers] = useState<IUserDetails[]>();

    useEffect(() => {
        if (!props.item.contactPerson) return;
        Promise.all(props.item.contactPerson.map( (contact) => {
            return getUser(contact.userId, httpCache)
        })).then( (r) => {
            if (r) {
                r = r.filter( r => r);
                setUsers(r)
            }
        }).catch( e => console.log(e));
    }, [httpCache]);
    
    const addPerson = async (newUser: IResumeContactPersonCreateDto): Promise<void> => {
        if (!newUser || !newUser.userId || !newUser.resumeId) return;

        const { error } = await request<void>(createContact(newUser));

        if (error) {
            console.log(`Something went wrong attempting to create a new contact for resume with id: ${props.item.id}`);
            return;
        }

        const fetchNewUserResult = await request<IUserDetails>(getUser(newUser.userId, httpCache));

        if (fetchNewUserResult.error || !fetchNewUserResult.result) {
            console.log(`Something went wrong fetching the details of the newly created contact: ${newUser.userId}`);
            return;
        }

        const tmpUsers = users ? [...users, fetchNewUserResult.result] : [fetchNewUserResult.result];
        setUsers(tmpUsers);

    }

    return (
        <div style={{paddingTop: 20, paddingLeft: 30, paddingRight: 46}}>
            { users && users.length ?
                users.map( (user: IUserDetails, index: number) => (
                    <div style={{marginTop: index > 0 ? 16 : 0}}>
                        <PersonaWithCard
                            text={user?.name}
                            secondaryText={user?.jobTitle}
                            imageUrl={user?.photo}
                            imageInitials={getImageInitials(user.givenName, user.surName)}
                            primaryEmail={user?.email}
                            primaryPhone={user?.mobile}
                            contactInformation={[
                                {accountType: 'Mobile', value: user?.mobile},
                                {accountType: 'Phone', value: user?.phone},
                            ]}
                        />
                    </div>
                ))
                :
                <></>
            }

            <Box mt={1} style={{maxWidth: 325}}>
                {adding &&
                    <UserPicker
                        max={1}
                        url={`${Settings.apiUrls.singleSignOn}/users/details/search?take=10&skip=0&term=`}
                        onChanged={(value: any[]) => {
                            const newUserObject: IResumeContactPersonCreateDto = {
                                userId: value[0].id,
                                resumeId: props.item.id,
                            };

                            addPerson(newUserObject);
                            setAdding(false);
                        }}
                    />
                }
                <Button variant="commandBar" icon="Add" onClick={() => setAdding(!adding)}>
                    Add person
                </Button>
            </Box>
        </div>
    );
};
