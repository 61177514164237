import React, {useCallback, useEffect, useMemo, useState} from "react";
import {
    getCompanyNameWithSourceId,
    ICompanyIdWithSourceId,
    ICompanyNameWithSourceId
} from "../Utils/getCompanyNameWithSourceId";
import {LimitListSize} from "./LimitListSize";
import {OverviewItemRow} from "./OverviewItemRow";
import moment from "moment";
import {dateYearOrPresent} from "../Utils/dateYearOrPresent";
import {IWorkUndertakenDto} from "../Api/Dtos/Resume/IWorkUndertakenDto";
import {useNavigationState} from 'ibis-design-system/lib/Providers/NavigationProvider';
interface IWorkUndertakenOverviewItemProps {
    workUndertaken: IWorkUndertakenDto[] | null;
}

export const WorkUndertakenOverviewItem: React.FunctionComponent<IWorkUndertakenOverviewItemProps> = (props) => {
    const {
        entities,
        navigateToErrorPage
    } = useNavigationState();
    const [workUndertakenCompanies, setWorkUndertakenCompanies] = useState<ICompanyNameWithSourceId[]>();

    useEffect(() => {
        if (!props.workUndertaken) return;
        getAllCompaniesForEducations(props.workUndertaken);
    }, [props.workUndertaken]);

    const getAllCompaniesForEducations = async (workUndertaken: IWorkUndertakenDto[]): Promise<string[] | undefined> => {
        let companyIds: ICompanyIdWithSourceId[] = workUndertaken.map( work => ({companyId: work.client, sourceId: work.id}) );
        if (!companyIds) return;
        const teachingCompanies = await getCompanyNameWithSourceId(companyIds);
        setWorkUndertakenCompanies(teachingCompanies)
    }

    const companyName = (workId: string, workUndertakenCompanies?: ICompanyNameWithSourceId[]): string => {
        if (!workUndertakenCompanies || !workUndertakenCompanies.length) return "";

        let companyName = workUndertakenCompanies.find( c => c.sourceId === workId)?.companyName;
        if (companyName) return companyName;
        return "";
    };


    if (!props.workUndertaken) {
        return (
            <div />
        );
    }
    
    return (
        <LimitListSize maxSize={3} list={
            props.workUndertaken && props.workUndertaken.map((work, index) =>
                <OverviewItemRow
                    key={work.id}
                    rootStyles={{marginTop: index > 0 ? 1 : 0}}
                    linkText={`${work.assignment}`}
                    subText={`${work.position}`}
                    cursiveText={`${companyName(work.id, workUndertakenCompanies)} (${moment(work.from).year()} - ${dateYearOrPresent(work.to)})`}
                />
            )}
        />
    );
};


