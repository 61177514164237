import {IMinimalProject} from "../Components/ProjectPicker";
import {request} from "./request";
import {getMinimalProject} from "../Api/requests";

/**
 * Fetches a list of projects based on project name and LOIS code.
 * @param searchInput - The input by which we want to find projects.
 */
export const getProjects = async (searchInput: string): Promise<IMinimalProject[]> => {

    if (!searchInput) { return []; }

    const {result, error} = await request<{result: IMinimalProject[]}>(getMinimalProject(searchInput, {take: 7, skip: 0}));

    if (!result || !result.result || error) {
        console.log("An error occurred attempting to fetch minimal projects");
        return [];
    }

    return result.result;
};