import React, {useState} from "react";
import Button from "ibis-design-system/lib/components/core/Button";
import Flex from "ibis-design-system/lib/components/core/Flex";
import {GreyBox} from "./GreyBox";

interface IAddItemFormProps {
    addButtonText?: string;
    onAddButtonClick: () => void;
    cancel: () => void;
    closeOnAdd?: boolean;
}

export const AddItemForm: React.FunctionComponent<IAddItemFormProps> = (props) => {

    const [adding, setAdding] = useState<boolean>(false);

    if (!adding) {
        return (
            <Button variant="commandBar" icon="Add" onClick={() => setAdding(true)}>
                {props.addButtonText}
            </Button>
        )
    }

    return (
        <GreyBox>

            {props.children}

            <Flex mt={3} mb={1}>
                <Button variant="primary" onClick={() => {
                    props.onAddButtonClick()
                    if (props.closeOnAdd) setAdding(false);
                }}>
                    {props.addButtonText}
                </Button>
                <span style={{marginLeft: 8}}><Button onClick={() => {
                    setAdding(false);
                    props.cancel();
                }}>Cancel</Button></span>
            </Flex>
        </GreyBox>
    );
};
