import moment from "moment";

export const dateDisplay = (date?: string): string | number => {
    if (!date) return "-";
    let years = moment().diff(date, "years");
    if (years > 1 && years < 30) return years + "Y";
    if (years > 30) return "-";
    let months = moment().diff(date, "months");
    if (months >= 6) return months + "M";
    return moment().diff(date, "days")
}