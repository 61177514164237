import {EPermission} from "../Api/Dtos/Resume/EPermission";

/**
 * Use this function to check if a user has the correct role to perform an action.
 * Pass the user role, and the desired role.
 * Returns true if user contains the correct role, false if not.
 *
 * @param has - The permissions we would like to check is available for the user. Default value: EPermission.Update
 * @param userPermissions - An array of permissions for a user.
 */
export const hasPermission = (userPermissions?: EPermission[], has?: EPermission): boolean => {
    if (!userPermissions) return false;
    if (!has) has = EPermission.Update;
    return userPermissions.includes(has);
}
