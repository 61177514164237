import styled from "ibis-design-system/lib/HelperFunctions/ThirdParty/styled-components";
import {IFoundation} from "../Interfaces/IFoundation";
import React from "react";

export const OverviewItem = styled.div`
    min-height: 76px;
    padding-left: 20px;
    padding-top: 12px;
    cursor: pointer;
    background-color: white;
`;

export const LinkText = styled.div`
    font-weight: ${(props: IFoundation) => props.theme.fontWeights.bold};
    color: ${(props: IFoundation) => props.theme.colors.themePrimary};
`;

export const SubText = styled.div`
    color: ${(props: IFoundation) => props.theme.colors.neutralSecondary};
`;

export const CursiveText = styled.div`
  color: ${(props: IFoundation) => props.theme.colors.neutralSecondary};
  font-style: italic;
`;

interface IOverviewItemRowProps {
    linkText?: string;
    subText?: string;
    cursiveText?: string;

    rootStyles?: React.CSSProperties;
    linkTextStyles?: React.CSSProperties;
    subTextStyles?: React.CSSProperties;
    cursiveTextStyles?: React.CSSProperties;
}

export const OverviewItemRow: React.FunctionComponent<IOverviewItemRowProps> = (props) => {
    return (
        <OverviewItem style={props.rootStyles}>
            {props.linkText &&
                <LinkText style={props.linkTextStyles}>{props.linkText}</LinkText>
            }
            {props.subText &&
                <SubText style={props.subTextStyles}>{props.subText}</SubText>
            }
            {props.cursiveText &&
                <CursiveText style={props.cursiveTextStyles}>{props.cursiveText}</CursiveText>
            }
        </OverviewItem>
    );
};

