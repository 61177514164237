import React, {useEffect, useState} from "react";
import {IBasePickerSuggestionsProps, ITag, TagPicker} from '@fluentui/react/lib/Pickers';
import {IBasePickerStyleProps, IBasePickerStyles, IStyleFunctionOrObject} from "office-ui-fabric-react";
import styled from "ibis-design-system/lib/HelperFunctions/ThirdParty/styled-components";
import {IFoundation} from "../Interfaces/IFoundation";
import {getUser} from "../Misc";
import {useCacheState} from "ibis-design-system/lib/Providers/HttpCacheProvider";
import {getConnectedUsers, getPerson} from "../Api/requests";
import {request} from "../Utils/request";
import {useUserState} from "ibis-design-system/lib/Providers/LoginProvider";
import {createUserOrContact} from "../Utils/createUserOrContact";

const Label = styled.div`
  font-weight: ${(props: IFoundation) => props.theme.fontWeights.semiBold};
  margin-bottom: 9px;
`

export enum EUserSource {
    /**
     * User is a Deltares employee.
     */
    Deltares,
    /**
     * User is added as a contact person on a Company.
     */
    CompanyContactPerson
}

export interface IUserOrContact {
    id: string;
    source: EUserSource;
}

export interface IConnectedUserDto {
    id: string;
    name: string;
    isDeltaresUser: boolean;
}

export interface IUserOrContactPickerChange {
    userOrContact: IUserOrContact;
    type: "add" | "remove";
    allSelectedContacts: IUserOrContact[] | undefined;
}

interface ILanguagePickerProps {
    // An array of the selected Languages.
    selectedPeople: IUserOrContact[];
    // Fired when a user selects a Language.
    onChange: (selectedPeople: IUserOrContactPickerChange) => void;
    // The label displayed above the input field.
    label?: string;
}

/**
 * Fetched the
 * @param props
 * @constructor
 */
export const UserOrContactPicker: React.FunctionComponent<ILanguagePickerProps> = (props) => {

    const user = useUserState();
    const [tags, setTags] = useState<ITag[]>([]);
    const [selectedPeople, setSelectedPeople] = useState<ITag[]>([]);
    const httpCache = useCacheState();

    useEffect(() => {
        if (!props.selectedPeople) {
            setTags([]);
            return;
        }

        fetchUsersAndContactsAndSetTags(props.selectedPeople);
    }, []);

    const fetchUsersAndContactsAndSetTags = async (selectedPeople: IUserOrContact[]): Promise<void> => {
        if (!selectedPeople || !selectedPeople.length) return;
        const deltaresUsers = props.selectedPeople.filter(s => s.source === EUserSource.Deltares);
        const companyContact = props.selectedPeople.filter(s => s.source === EUserSource.CompanyContactPerson);

        let allTags: ITag[] = [];
        if (deltaresUsers) {
            const deltaresResult = await fetchDeltaresUsers(deltaresUsers);
            if (deltaresResult && deltaresResult.length) allTags = [...deltaresResult];
        }
        if (companyContact) {
            const companyResult = await fetchContactPersons(companyContact);
            if (companyResult && companyResult.length) allTags = [...allTags, ...companyResult];
        }

        setSelectedPeople(allTags);
    }

    const fetchContactPersons = async (selectedContactPersons: IUserOrContact[]): Promise<ITag[] | undefined> => {
        let result = await Promise.all(selectedContactPersons.map(async (user: IUserOrContact) => await getPerson(user.id))).catch(e => console.log(e));
        if (!result) return;

        result = result.filter(r => r);
        return result.map(user => ({name: user.name ? user.name : "", key: `${EUserSource.CompanyContactPerson}:${user.id}`}));
    }

    const fetchDeltaresUsers = async (selectedDeltaresUsers: IUserOrContact[]): Promise<ITag[] | undefined> => {
        let result = await Promise.all(selectedDeltaresUsers.map(async (user: IUserOrContact) => await getUser(user.id, httpCache))).catch(e => console.log(e));
        if (!result) return;

        result = result.filter(r => r);
        return result.map(user => ({name: user.name ? user.name : "", key: `${EUserSource.Deltares}:${user.id}`}));
    }

    const getTextFromItem = (item: ITag) => item.name;

    const listContainsTagList = (tag: ITag, tagList?: ITag[]) => {
        if (!tagList || !tagList.length || tagList.length === 0) {
            return false;
        }
        return tagList.some(compareTag => compareTag.key === tag.key);
    };

    const filterSuggestedTags = async (filterText: string, tagList?: ITag[]): Promise<ITag[]> => {
        const {result, error} = await request<IConnectedUserDto[]>(getConnectedUsers(user.id, filterText))

        if (!result || error) {
            return filterText
                ? tags.filter(
                    tag => tag.name.toLowerCase().indexOf(filterText.toLowerCase()) === 0 && !listContainsTagList(tag, tagList),
                )
                : [];
        }

        const newTags = result.map( us => ({key: `${us.isDeltaresUser ? EUserSource.Deltares : EUserSource.CompanyContactPerson}:${us.id}`, name: us.name}));
        setTags([...newTags]);
        return filterText
            ? newTags.filter(
                tag => tag.name.toLowerCase().indexOf(filterText.toLowerCase()) === 0 && !listContainsTagList(tag, tagList),
            )
            : [];
    };

    const sendSelectedToProps = (changedTags: ITag[] | undefined): void => {

        let allSelectedContacts: IUserOrContact[] | undefined = undefined;
        if (changedTags && changedTags.length) {
            allSelectedContacts = changedTags.map( tag => createUserOrContact(tag.key as string));
        }

        // If all tags have been removed, send a remove request for all tags.
        if (!changedTags || !changedTags.length && selectedPeople) {
            selectedPeople.forEach( tag => {
                const userOrContact = createUserOrContact(tag.key as string);
                props.onChange({userOrContact, type: "remove", allSelectedContacts});
            });
            setSelectedPeople([]);
            return;
        }
        if (selectedPeople && selectedPeople.length && changedTags && changedTags.length && selectedPeople.length > changedTags.length) {
            // Now that we have verified a tag has been removed, we'll find it and send a patch to remove that tag.
            let removed: ITag | undefined;
            selectedPeople.forEach( selectedPerson => {
                let found = false;
                changedTags.forEach( (t) => t.key === selectedPerson.key ? found = true : undefined);
                if (!found) removed = selectedPerson;
            });
            if (!removed) return;
            const userOrContact = createUserOrContact(removed.key as string);
            props.onChange({userOrContact, type: "remove", allSelectedContacts});
            setSelectedPeople(changedTags);
            return;
        }

        // Down here we know for sure a new tag has been added. So we'll find that language and send a create request to the backend.
        let added: ITag | undefined;
        changedTags?.forEach( changedTag => {
            if (!selectedPeople?.find( t => t.key === changedTag.key)) added = changedTag;
        });
        if (!added) return;
        const userOrContact = createUserOrContact(added.key as string);
        props.onChange({userOrContact, type: "add", allSelectedContacts});
        setSelectedPeople(changedTags ? changedTags : []);
    }

    const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
        suggestionsHeaderText: 'Suggested people',
        noResultsFoundText: 'No people found',
    };

    const style: IStyleFunctionOrObject<IBasePickerStyleProps, IBasePickerStyles> | undefined = {
        text: {
            borderLeft: "none",
            borderTop: "none",
            borderRight: "none",
        },
    }

    return (
        <div>
            <Label>{props.label}</Label>
            <TagPicker
                styles={style}
                removeButtonAriaLabel="Remove"
                onResolveSuggestions={filterSuggestedTags}
                getTextFromItem={getTextFromItem}
                pickerSuggestionsProps={pickerSuggestionsProps}
                itemLimit={30}
                selectedItems={selectedPeople}
                onChange={(items?: ITag[] | undefined) => sendSelectedToProps(items)}
            />
        </div>
    );
};
