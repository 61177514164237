import {useNavigationState} from "ibis-design-system/lib/Providers/NavigationProvider";
import {IResumeDto} from "../Api/Dtos/Resume/IResumeDto";

export interface ISelectedEntity {
    update: (callBack: (item: IResumeDto) => IResumeDto) => void;
    getFirst: () => IResumeDto | undefined;
    getSelection: () => IResumeDto[] | undefined;
}

/**
 * A React hook used for managing and updating selected entities.
 */
export const selectedEntity = (): ISelectedEntity => {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { selection, entities, updateEntities } = useNavigationState();

    /**
     * Update first selected entity.
     * @param callBack - A callback which updates the selected entity.
     */
    const update = (callBack: (item: IResumeDto) => IResumeDto): void => {
        let item = getFirst();

        if (!item) return;

        item = callBack(item);
        updateEntities([item]);
    }

    /**
     * Gets the first selected entity.
     */
    const getFirst = (): IResumeDto | undefined => {
        if (!selection || !selection.length) return undefined;
        console.log(entities);
        return { ...entities[selection[0]] };
    }

    /**
     * Get all selected entities.
     */
    const getSelection = (): IResumeDto[] | undefined => {
        if (!entities || !selection || !selection.length) return undefined;
        const tmpEntities = {...entities};
        let selectedEntities = [];
        for (let i = 0; i < selection.length; i++) {
            selectedEntities.push(tmpEntities[selection[i]]);
        }
        return selectedEntities;
    }

    return {update, getFirst, getSelection};
}