import React, {useMemo} from "react";
import Icon from 'ibis-design-system/lib/components/core/Icon';
import styled from "ibis-design-system/lib/HelperFunctions/ThirdParty/styled-components";
import {IBaseProps} from "../Interfaces/IBaseProps";

const Row = styled.div`
  display: flex;
  align-items: center;
`;
const Text = styled.div`
  margin-left: ${(props: any) => props.icon ? "7px" : "0px"}
`;

export interface ISmallIconWithTextProps extends IBaseProps {
    icon?: string;
    text: string;
    url?: boolean;
    tel?: boolean;
    email?: boolean;
}


export const SmallIconWithText: React.FunctionComponent<ISmallIconWithTextProps> = (props) => {

    const link = useMemo(() => {
        let link = "";
        if (props.tel) link = "tel:";
        if (props.email) link = "mailto:"
        return link + props.text;
    }, [props]);

    return (
        <Row style={props.style} className={props.className}>
            { props.icon && <Icon name={props.icon} size="xs"/> }
            <Text icon={props.icon}>
                {props.url || props.tel ?
                    <a href={link}>{props.text}</a>
                    :
                    props.text
                }
            </Text>
        </Row>
    );
};
