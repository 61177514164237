import React, {useState} from "react";
import {Bold} from "../../Bold";
import TextInput from "ibis-design-system/lib/components/inputFields/TextInput";
import {ITrainingDto} from "../../../Api/Dtos/Resume/ITrainingDto";
import {EOP, IPatchParams} from "../../../Interfaces/IPatchParams";
import {IYearPickerDates} from "../../YearPicker";
import CompanyPicker from 'ibis-design-system/lib/components/pickers/CompanyPicker';
import {useNatures} from "../../../Hooks/GlobalConfigContext";
import {getCompanies, getCompany} from "../../../Api/requests";
import {useCompany} from "../../../Hooks/useCompany";
import {companyPickerResult} from "../../../Utils/companyPickerResult";
import {MT12} from "../../MT12";
import {IBaseProps} from "../../../Interfaces/IBaseProps";
import {ResumeInfoCard} from "../../ResumeInfoCard";
import {onStateChange} from "../../../Utils/onStateChange";
import {IUserContextDto} from "../../../Api/Dtos/Resume/IUserContextDto";
import {generatePatchArrayForExistingObject} from "../../../Utils/generatePatchArray";

interface ITrainingProps extends IBaseProps {
    training?: ITrainingDto | null;
    patch: (params: IPatchParams<any, any>[]) => void;
    permissions: IUserContextDto;
}

export const Training: React.FunctionComponent<ITrainingProps> = (props) => {

    const natures = useNatures();
    const teachingInstitute = useCompany(props.training?.teachingInstitute);
    const [editing, setEditing] = useState<boolean>(false);
    const [training, setTraining] = useState<ITrainingDto | null | undefined>(props.training);

    const onChange = (key: keyof ITrainingDto, value: string) => {
        const result = onStateChange<ITrainingDto>(training, key, value);
        if (result) setTraining(result);
    }

    const changeDates = (dates: IYearPickerDates) => {
        if (!props.training || !training) return;

        const tmpTraining: ITrainingDto = {...training};
        tmpTraining.from = dates.start ? dates.start : null;
        tmpTraining.to = dates.end ? dates.end : null;
        setTraining(tmpTraining);
    }

    const updateTeachingInstitute = (change: any): void => {
        if (!training || !change) { return; }

        const pickerResult = companyPickerResult(change);
        const tmpTraining = {...training};
        tmpTraining.teachingInstitute = pickerResult;
        teachingInstitute.setCompanyId(pickerResult);
        setTraining(tmpTraining)
    }

    const onUpdate = () => {
        props.patch(generatePatchArrayForExistingObject(props.training, training, `trainings/${training?.id}`));
        setEditing(false);
    };

    return (
        <ResumeInfoCard
            editing={editing}
            setEditing={setEditing}
            period={{start: training && training.from ? training.from : undefined, end: training && training.to ? training.to : undefined}}
            updatePeriod={(dates: IYearPickerDates) => changeDates(dates)}
            permissions={props.permissions}
            onUpdate={onUpdate}
            onDelete={() => props.patch([
                {
                    op: EOP.Remove,
                    path: `trainings/${training?.id}`
                },
            ])}
            onCancel={() => setTraining(props.training)}
            style={props.style}
        >

            <Bold>Subject of training</Bold>
            { editing ?
                <TextInput
                    placeholder="Subject"
                    value={props.training?.subjectOfTraining}
                    onChange={(val: string) => onChange("subjectOfTraining", val)}
                />
                :
                <MT12>{training?.subjectOfTraining}</MT12>
            }

            <Bold style={{marginTop: 37}}>Education institute</Bold>
            { editing ?
                <CompanyPicker
                    placeholder="Select client"
                    pickLimit={1}
                    exclude={training?.teachingInstitute ? [training?.teachingInstitute] : []}
                    selected={training?.teachingInstitute ? [training?.teachingInstitute] : []}
                    natures={natures}
                    getCompany={getCompany}
                    getCompanies={(input: string) => getCompanies(input, null, null)}
                    onChanged={(change: any) => updateTeachingInstitute(change)}
                />
                :
                <MT12 style={{paddingBottom: 27}}>{teachingInstitute.company?.name}</MT12>
            }
        </ResumeInfoCard>
    );
};

