// Displays the name above a Resume.
export const getNameForResume = (fullName?: string, scienceTitlePrefix?: string, scienceTitleSuffix?: string): string => {
    let name = "";
    if (fullName) name = addNamePart(name, fullName);
    if (scienceTitlePrefix) name = addNamePart(name, scienceTitlePrefix);
    if (scienceTitleSuffix) name = addNamePart(name, scienceTitleSuffix);
    return name;
    // `${firstname} ${lnPrefix ? lnPrefix + " " + lastname : lastname} ${scienceTitlePrefix ? scienceTitlePrefix : ""}${scienceTitlePrefix ? "," : ""}${scienceTitleSuffix}`
    // return;
};

const addNamePart = (name: string, part: string): string => {
    if (name.length) return name + " " + part;
    return name + part;
}