import React from "react";
import moment from "moment";
import {ICalendarStrings, IContextualMenuProps} from "office-ui-fabric-react";
import {DotMenu} from "./DotMenu";
import { Calendar, DateRangeType } from "office-ui-fabric-react";
import {IBaseProps} from "../Interfaces/IBaseProps";
import {DatePickerStrings} from "../Utils/datePickerStrings";

// Interface for object that holds dates in ISO string format.
export interface IYearPickerDates {
    start?: string | null;
    end?: string | null
}

interface IYearPickerProps extends IBaseProps {
    // An object with a start and end date in UTC timestamp format.
    dates?: IYearPickerDates
    // Sends an object with the new dates in UTC timestamp format.
    onChange?: (dates: IYearPickerDates) => void;
    // If set to true, displays a single calendar instead of 2. As a result this component can be used for selecting a single date instead of being a date range picker.
    displaySingleCalendar?: boolean;
}

let initDates: IYearPickerDates = {
    start: undefined,
    end: undefined,
}

let years: any[] = [];
for (let i = 1960; i < moment().year() + 3; i++) years.push({key: i, value: i, active: true});

/**
 * Displays a date range picker.
 * If the displaySingleCalendar props is set to true, is used as a single date picker.
 * @param props
 * @constructor
 */
export const YearPicker: React.FunctionComponent<IYearPickerProps> = (props) => {

    const setSelectedDate = (key: keyof IYearPickerDates, value: Date) => {
        if (!props.onChange) return;
        const tmpDates = props.dates ? {...props.dates} : initDates;
        tmpDates[key] = moment(value).toISOString();
    
        if (key === "end" && showNowWhenEndIsSameAsThisMonth(tmpDates.end)) {
            tmpDates.end = null;
        }
    
        props.onChange(tmpDates);
    }

    const menuProps: IContextualMenuProps = {
        items: [
            {
                key: 'picker',
                onRender: () => {
                    return (
                        <div style={{display: "flex"}}>
                            <Calendar
                                dateRangeType={DateRangeType.Month}
                                selectDateOnClick
                                showGoToToday
                                highlightSelectedMonth
                                isDayPickerVisible={false}
                                onSelectDate={(date: Date) => setSelectedDate("start", date)}
                                value={props.dates?.start ? moment(props.dates?.start).toDate() : undefined}
                                strings={DatePickerStrings}
                            />
                            { !props.displaySingleCalendar &&
                                <Calendar
                                    dateRangeType={DateRangeType.Month}
                                    selectDateOnClick
                                    showGoToToday
                                    highlightSelectedMonth
                                    isDayPickerVisible={false}
                                    onSelectDate={(date: Date) => setSelectedDate("end", date)}
                                    value={props.dates?.end ? moment(props.dates?.end).toDate() : undefined}
                                    strings={DatePickerStrings}
                                />
                            }
                        </div>

                    )
                }
            },
        ],
    };

    return (
        <DotMenu style={{borderBottom: "1px solid black", ...props.style}} menuProps={menuProps} icon={"Calendar"}>
            { props.displaySingleCalendar ?
                <div>{props.dates?.start ? moment(props.dates?.start).format('yyyy') : ""}</div>
                :
                <div>{props.dates?.start ? moment(props.dates?.start).format('yyyy') : ""} - {showNowWhenEndIsSameAsThisMonth(props.dates?.end) ? 'now' : moment(props.dates?.end).format('yyyy')}</div>
            }
        </DotMenu>
    );
};

export const showNowWhenEndIsSameAsThisMonth = (endDate: any) => {
    if (endDate === 'now') return true;
    const currentMonthAndYear = `${moment().month() + 1} ${moment().year()}`;
    const selectedMonthAndYear = `${ moment(endDate).month() + 1} ${moment(endDate).year()}`;
    if (currentMonthAndYear === selectedMonthAndYear) return true;
    return false;
}