import styled from "ibis-design-system/lib/HelperFunctions/ThirdParty/styled-components";
import {IFoundation} from "../Interfaces/IFoundation";

export const BlueText = styled.div`
    color: ${(props: IFoundation) => props.theme.colors.themePrimary};
    margin-top: 4px;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
`;
