import {ITenderTabCard} from "../Interfaces/ITenderTabCard";
import {TenderTabCard} from "./TenderTabCard";
import {Overview} from "../Containers/Overview";
import {IResumeDto} from "../Api/Dtos/Resume/IResumeDto";
import {Resume} from "../Containers/Resume";
import {IPatchParams} from "../Interfaces/IPatchParams";
import {Publications} from "../Containers/Publications";
import {IUserDto} from "../Api/Dtos/IUserDto";
import {Chat} from "./Chat";
import {getFullName} from "../Utils/getFullName";
import {Documents} from "./Documents";
import {Contacts} from "../Containers/Contacts";

import { EmploymentRecord } from "./Resume/EmploymentRecord";
import { WorkUndertaken } from "./Resume/WorkUndertaken";
import {KeyQualifications} from "./Resume/KeyQualifications";

export const GetDetailTabs = (overflowActive: boolean, item: IResumeDto, type: any, setMessage: any, patch: (args: IPatchParams<any, any>[]) => void, user: IUserDto): ITenderTabCard[] => {
    return [
        TenderTabCard("Overview", <Overview item={item} patch={patch} setMessage={setMessage} overflowActive={overflowActive} type={type} user={user} />),
        TenderTabCard("Resume", <Resume item={item} patch={patch} setMessage={setMessage} overflowActive={overflowActive} type={type} user={user} />),
        TenderTabCard("Publications", <Publications item={item} patch={patch} setMessage={setMessage} overflowActive={overflowActive} type={type} user={user} />),
        TenderTabCard("Work Undertaken", <WorkUndertaken item={item} patch={patch} setMessage={setMessage} overflowActive={overflowActive} type={type} user={user} />),
        TenderTabCard("Employment Record", <EmploymentRecord item={item} patch={patch} setMessage={setMessage} overflowActive={overflowActive} type={type} user={user} />),
        TenderTabCard("Key Qualifications", <KeyQualifications item={item} patch={patch} setMessage={setMessage} overflowActive={overflowActive} type={type} user={user} />),
        TenderTabCard("Messages", <Chat setMessage={setMessage} id={item.id} title={item.firstname + " " + item.lastname} userContext={item.userContext}/>, "Message", true, "Messages", overflowActive),
        TenderTabCard("Documents", <Documents setMessage={setMessage} id={item.id} name={`Resume of ${getFullName(item.firstname, item.lastname)}`} userContext={item.userContext} />, "Folder", true, "Documents", overflowActive),
        TenderTabCard("Contacts", <Contacts item={item} patch={patch} setMessage={setMessage} overflowActive={overflowActive} type={type} user={user} />, "Contact", true, "Contacts", overflowActive),
    ]
}