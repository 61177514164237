import React, {useState} from "react";
import {AddItemForm} from "../../AddItemForm";
import {ICreateEuropassLanguageDto, IEuropassLanguageDto} from "../../../Api/Dtos/Resume/IEuropassLanguageDto";
import {ELanguageLevel, ELanguageLevelTable} from "../../../Api/Dtos/Resume/ELanguageLevel";
import {LanguageLevel} from "./LanguageLevel";
import {EOP, IPatchParams} from "../../../Interfaces/IPatchParams";
import {onStateChange} from "../../../Utils/onStateChange";
import {IUserContextDto} from "../../../Api/Dtos/Resume/IUserContextDto";
import {IResumeDto} from "../../../Api/Dtos/Resume/IResumeDto";
import { ICreateLanguagesDto, ILanguagesDto } from "../../../Api/Dtos/Resume/ILanguagesDto";

const INIT_PASS_LANGUAGE: ILanguagesDto = {
    id: "newLanguageSkill",
    isMotherTongue: false,
    language: "",
    listening: ELanguageLevelTable.MotherTongue,
    reading: ELanguageLevelTable.MotherTongue,
    speaking: ELanguageLevelTable.MotherTongue,
    writing: ELanguageLevelTable.MotherTongue
}

interface IAddLanguageSkillProps {
    patch: (args: IPatchParams<any, any>[]) => void;
    userContext: IUserContextDto;
    item: IResumeDto;
}

export const AddLanguageTable: React.FunctionComponent<IAddLanguageSkillProps> = (props) => {

    const [languageSkill, setLanguageSkill] = useState<ILanguagesDto>(INIT_PASS_LANGUAGE);

    const onChange = (key: keyof ILanguagesDto, value: any): void => {
        const result = onStateChange<ILanguagesDto>(languageSkill, key, value);
        if (result) setLanguageSkill(result);
    }

    return (
        <AddItemForm closeOnAdd addButtonText="Add language" cancel={() => setLanguageSkill(INIT_PASS_LANGUAGE)} onAddButtonClick={() => {
            let tmpEuropass: ICreateLanguagesDto = {
                language: languageSkill.language,
                reading: languageSkill.reading as any,
                listening: languageSkill.listening as any,
                speaking: languageSkill.speaking as any,
                writing: languageSkill.writing as any,
                isMotherTongue: languageSkill.isMotherTongue,
            }

            // Before we add the language, let's make sure it doesn't already exist for this user.
            // if(props.item.europassLanguages?.find( l => l.language === tmpEuropass.language) !== undefined) return;

            props.patch([
                {
                    op: EOP.Add,
                    path: `Languages/-`,
                    value: tmpEuropass
                }
            ])
        }}>
            <LanguageLevel
                languages={languageSkill}
                patch={(value: IPatchParams<string, keyof IEuropassLanguageDto | string>[]) => onChange(value[0].path as keyof ILanguagesDto, value[0].value)}
                usingAsCreateForm={true}
                userContext={props.userContext}
            />
        </AddItemForm>
    );
};
