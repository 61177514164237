import React from 'react';
import { CollectionHeader } from './Components/CollectionHeader';

export enum ECollections {
    Activity = "Activity",
    Recent = "Recent",
}

export function getCollectionTabs() {
 return [ECollections.Activity, ECollections.Recent];
}
// export function getCollectionTabs() {
//     return ["Activity", "Alphabetical", "Unit", "Recent"];
// }

export const PUBLIC_PERMISSION_MODE: string = 'Public';
export const SHARED_PERMISSION_MODE: string = 'Shared';
export const PRIVATE_PERMISSION_MODE: string = 'Private';

export const getStateIcon = (state: any): string =>
    ({
        [PUBLIC_PERMISSION_MODE]: 'Globe',
        [SHARED_PERMISSION_MODE]: 'ReminderGroup',
        [PRIVATE_PERMISSION_MODE]: 'ReminderPerson'
    }[state]);

export function mapEntityTypeToCollectionType() {
    return 'resumes'
}

export const educationTitleOptions = [
    {
        id: "BA.",
        label: "BA."
    },
    {
        id: "Bsc.",
        label: "Bsc."
    },
    {
        id: "Msc.",
        label: "Msc."
    },
    {
        id: "Dr.",
        label: "Dr."
    },
    {
        id: "MA.",
        label: "MA."
    },
    {
        id: "Prof.",
        label: "Prof."
    },
    {
        id: "Phd.",
        label: "Phd."
    },
    {
        id: "BBA.",
        label: "BBA."
    },
    {
        id: "BEng.",
        label: "BEng."
    },
    {
        id: "MEng.",
        label: "MEng."
    },
    {
        id: "Mr.",
        label: "Mr."
    }
]

export const collectionHeader = (selectedTab: ECollections): any => {
    let text: string = "";
    switch (selectedTab) {
        case ECollections.Activity.toLocaleLowerCase():
            text = "Recently updated resumes";
            break;
        case ECollections.Recent.toLocaleLowerCase():
            text = "Recently added resumes";
            break;
        default: text = "Resumes"
    }

    return (
        <CollectionHeader>
            {text}
        </CollectionHeader>
    )
}
