import React, {useMemo, useState} from "react";
import {AddItemForm} from "../../AddItemForm";
import {ICreateWorkUndertaken, IWorkUndertakenDto} from "../../../Api/Dtos/Resume/IWorkUndertakenDto";
import moment from "moment";
import TextInput from "ibis-design-system/lib/components/inputFields/TextInput";
import {MarginTopBlock} from "../../MarginTopBlock";
import WYSIWYGEditor from "ibis-design-system/lib/components/inputFields/WYSIWYGEditor";
import {WYSIWYG_dataConversion} from "../../../Utils/WYSIWYG_dataConversion";
import {ICountryDto} from "../../../Api/Dtos/ICountryDto";
import Flex from "ibis-design-system/lib/components/Atoms/Flex";
import CompanyPicker from "ibis-design-system/lib/components/pickers/CompanyPicker";
import {getCompanies, getCompany, getProjectById} from "../../../Api/requests";
import {IMinimalProject, ProjectPicker} from "../../ProjectPicker";
import {onStateChange} from "../../../Utils/onStateChange";
import {useCountries, useNatures} from "../../../Hooks/GlobalConfigContext";
import {getProjects} from "../../../Utils/getProjects";
import {companyPickerResult} from "../../../Utils/companyPickerResult";
import {EOP, IPatchParams} from "../../../Interfaces/IPatchParams";
import {SemiBold} from "../../Bold";
import {IYearPickerDates, YearPicker} from "../../YearPicker";
import { GenericPicker } from "../../GenericPicker";
import { ITag } from "office-ui-fabric-react";
import { ICompanyPicker } from "../../../Interfaces/ICompanyPicker";

const INITIAL_RECORD: ICreateWorkUndertaken = {
    from: moment().toISOString(),
    to: null,
    workUndertakenCountries: undefined,
    workUndertakenClients: [],
    activitiesPerformed: undefined,
    assignment: undefined,
    position: undefined,
    projectReferenceId: undefined,
    mainProjectFeatures:undefined
}

interface ICreateWorkUndertakenProps {
    patch: (args: IPatchParams<ICreateWorkUndertaken, string>[]) => void;
    id: string;
}


export const CreateWorkUndertaken: React.FunctionComponent<ICreateWorkUndertakenProps> = (props) => {

    const natures = useNatures();
    const countries = useCountries();
    const [workUndertaken, setWorkUndertaken] = useState<ICreateWorkUndertaken>(INITIAL_RECORD);

    const countryIds = useMemo(() => workUndertaken  && workUndertaken.workUndertakenCountries ? workUndertaken.workUndertakenCountries : [], [workUndertaken])
   
    const onChange = <T,>(key: keyof ICreateWorkUndertaken, value: T) => {
        const result = onStateChange<ICreateWorkUndertaken>(workUndertaken, key, value);
        if (result) setWorkUndertaken(result);
    }

    const createNewWorkUndertaken = (): void => {
        const tmpWorkUndertaken = {...workUndertaken};

        if (tmpWorkUndertaken.workUndertakenCountries) {
            //@ts-ignore
            tmpWorkUndertaken.workUndertakenCountries = tmpWorkUndertaken.workUndertakenCountries.map( ct => {
                return (
                    {
                        workUndertakenId: props.id,
                        countryId: ct,
                        countryOfWorkExperienceId: ct
                    }
                )
            });
        }
        if (tmpWorkUndertaken.workUndertakenClients) {
            //@ts-ignore
            tmpWorkUndertaken.workUndertakenClients = tmpWorkUndertaken.workUndertakenClients.map( ct => {
                return (
                    {
                        workUndertakenId: props.id,
                        clientId: ct,
                    }
                )
            });
        }
        props.patch([
            {
                op: EOP.Add,
                path: "workUndertakens/-",
                value: tmpWorkUndertaken
            }
        ]);
        setWorkUndertaken(INITIAL_RECORD);
    }

    const onAddWorkUndertaken = () => {
        createNewWorkUndertaken();
        setWorkUndertaken({...INITIAL_RECORD, workUndertakenClients: []}); 
    }

    const updatedSelectedCompanies = (selected: ICompanyPicker) => {
        setWorkUndertaken( (old: ICreateWorkUndertaken) => {
            const tmpOld = {...old};
            if (selected.op === "add") tmpOld.workUndertakenClients.push(selected.value.key);
            else tmpOld.workUndertakenClients = tmpOld.workUndertakenClients.filter( (old: string) => old !== selected.value.key);
            return tmpOld;
        });
    }

    const setYears = (dates: IYearPickerDates): void => {
        const tmpRecord = {...workUndertaken};
        tmpRecord.from = dates.start;
        tmpRecord.to = dates.end;
        setWorkUndertaken(tmpRecord);
    }

    return (
        <AddItemForm
            addButtonText={"Add Work Undertaken"}
            onAddButtonClick={onAddWorkUndertaken}
            cancel={() => setWorkUndertaken({...INITIAL_RECORD, workUndertakenClients: []})}
            closeOnAdd
        >
            <MarginTopBlock>
                <GenericPicker
                    fetchData={getProjects}
                    fetchSingle={getProjectById}
                    listToTags={(projects: IMinimalProject[]) => projects.map<ITag>( project => ({key: project.id, name: project.projectTitle }))}
                    singleTypeToTag={(project) => ({key: project.id, name: project.projectTitle })}
                    onChange={(projects: string[] | undefined) => {
                        const id = projects?.length ? projects[0] : undefined;
                        onChange<string | undefined>("projectReferenceId", id);
                    }}
                    label="Project reference"
                    pickLimit={1}
                />
            </MarginTopBlock>
            <div style={{flex: 4}}>
                    <TextInput
                        label="Name assignment/project"
                        placeholder="Name assignment/project"
                        value={workUndertaken?.assignment}
                        onChange={(val: string) => onChange("assignment", val)}
                    />
             </div>
            <Flex style={{width: "100%"}}>
                <div style={{flex: 1, marginTop: 5}}>
                    <SemiBold>Period</SemiBold>
                    <YearPicker
                        style={{marginTop: 7}}
                        dates={{
                        start: workUndertaken.from ? workUndertaken.from : undefined,
                        end: workUndertaken.to ? workUndertaken.to : undefined
                        }} 
                        onChange={(dates: IYearPickerDates) => setYears(dates)} 
                    />
                </div>
                <div style={{flex: 4}}>
                    <TextInput
                        label="Position"
                        placeholder="Position"
                        value={workUndertaken?.position}
                        onChange={(val: string) => onChange("position", val)}
                    />
                </div>
            </Flex>
            <MarginTopBlock>
                <WYSIWYGEditor
                    content={WYSIWYG_dataConversion(workUndertaken.mainProjectFeatures)}
                    onUpdate={(val: string) => onChange("mainProjectFeatures", val)}
                    label="Main Project Features"
                />
            </MarginTopBlock>
            <MarginTopBlock>
                <WYSIWYGEditor
                    content={WYSIWYG_dataConversion(workUndertaken.activitiesPerformed)}
                    onUpdate={(val: string) => onChange("activitiesPerformed", val)}
                    label="Activities performed"
                />
            </MarginTopBlock>
            <MarginTopBlock>
                <GenericPicker
                    list={countries}
                    listToTags={(countries: ICountryDto[]) => countries.map<ITag>( country => ({key: country.id, name: country.name }))}
                    singleTypeToTag={(country: ICountryDto) => ({key: country.id, name: country.name })}
                    onChange={(countries: string[] | undefined) => onChange<string[] | undefined>("workUndertakenCountries", countries)}
                    label="Countries of work experience"
                    pickLimit={10}
                />
            </MarginTopBlock>

            <MarginTopBlock>
                <Flex>
                    <div style={{flex: 1}}>
                        <CompanyPicker
                            label="Clients"
                            pickLimit={10}
                            exclude={workUndertaken.workUndertakenClients}
                            selected={workUndertaken.workUndertakenClients}
                            natures={natures}
                            multiSelect={true}
                            getCompany={getCompany}
                            getCompanies={(input: string) => getCompanies(input, null, null)}
                            onChanged={(change: ICompanyPicker) => updatedSelectedCompanies(change)}
                        />
                    </div>
                </Flex>
            </MarginTopBlock>

        </AddItemForm>
    );
};
