/**
 * Takes two dates in UTC format, and returns the years from those dates.
 * If one or both of the dates are not available, it returns a custom string to reflect this.
 * @param from
 * @param to
 */
import moment from "moment";
import { showNowWhenEndIsSameAsThisMonth } from "../Components/YearPicker";

export const yearFromToString = (from: string | null | undefined, to: string | null | undefined): string => {
    let dateString = "";
    if (from && moment(from).isValid()) {
        dateString += `${moment(from).format('yyyy')}`
    }
    if (dateString && dateString.length) {
        dateString +=  " - ";
    }
    // The extra check of showNowWhenEndIsSameAsThisMonth(to) is to show 'now' when the end date is the same as the current month.
    // If this check is left out, 'now' will not be shown if end date is the same as the current month.
    if (to && moment(to).isValid() && !showNowWhenEndIsSameAsThisMonth(to)) {
        dateString += `${moment(to).format('yyyy')}`
    } else {
        dateString += " now"
    }
    return dateString;
}