import React from "react";
import {IContainerProps} from "../../Interfaces/IContainterProps";
import {WorkUndertakenitem} from "./WorkUndertaken/WorkUndertakenitem";
import {CreateWorkUndertaken} from "./WorkUndertaken/CreateWorkUndertaken";
import {hasPermission} from "../../Utils/hasPermission";


interface IWorkUndertakenProps extends IContainerProps {
    style?: React.CSSProperties;
}

export const WorkUndertaken: React.FunctionComponent<IWorkUndertakenProps> = (props) => {
    const sortWorkUnderTakenItem = [...props.item.workUndertakens!];
    const safeDate = (dateString: any) => dateString && new Date(dateString);

    sortWorkUnderTakenItem.sort((a, b) => {
        if (a.to === null && b.to === null) return safeDate(b.from).getTime() - safeDate(a.from).getTime();
        if (a.to === null) return -1;
        if (b.to === null) return 1;
        if (a.to === b.to) return safeDate(b.from).getTime() - safeDate(a.from).getTime();
        return safeDate(b.to).getFullYear() - safeDate(a.to).getFullYear();
    });

    return (
        <div style={{paddingTop: 20, paddingLeft: 30, paddingRight: 46, width: '100%'}}>
            {hasPermission(props.item.userContext.permissions) &&
                <CreateWorkUndertaken patch={props.patch} id={props.item.id}/>
            }
            {sortWorkUnderTakenItem.map( work => <WorkUndertakenitem style={{marginTop: 1 }} key={work.id} permissions={props.item.userContext} workUndertaken={work} patch={props.patch}  /> )}
        </div>
    );
};