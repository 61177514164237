import Settings from './Settings';
import {IMenu} from "./Interfaces/IMenu";
import {IMenuItem} from "./Interfaces/IMenuItem";

const TAKE = 100;

export function getMenuItems(username: string): Array<IMenu | IMenuItem> {
    return [
        {
            id: username,
            name: username,
            expanded: true,
            items: [
                {
                    name: 'Following',
                    id: 'following',
                    request: {
                        url: `${Settings.apiUrls.resumes}/resumes/search`,
                        filters: [{ type: 'following' }],
                        take: TAKE,
                        skip: 0
                    }
                },
                {
                    name: 'My resumes',
                    id: 'my-resumes',
                    request: {
                        url: `${Settings.apiUrls.resumes}/resumes/search`,
                        skip: 0,
                        filters: [{ type: 'my-resumes' }],
                    }
                },
                {
                    name: 'My unit',
                    id: 'unit',
                    request: {
                        url: `${Settings.apiUrls.resumes}/resumes/search`,
                        skip: 0,
                        filters: [{ type: 'my-unit' }],
                    }
                },
                {
                    name: 'Shared with me',
                    id: 'shared-with-me',
                    request: {
                        url: `${Settings.apiUrls.resumes}/resumes/search`,
                        skip: 0,
                        filters: [{ type: 'shared-with' }],
                    }
                }
            ]
        },
        {
            id: 'active-resumes',
            name: 'Resumes',
            request: {
                url: `${Settings.apiUrls.resumes}/resumes/search`,
                skip: 0
            }
        },
        {
            id: 'archived',
            name: 'Archived',
            request: {
                url: `${Settings.apiUrls.resumes}/resumes/search`,
                filters: [{ type: 'archived'}],
                take: TAKE,
                skip: 0
            }
        },
        {
            id: 'deleted',
            name: 'Deleted',
            request: {
                url: `${Settings.apiUrls.resumes}/resumes/search`,
                filters: [{ type: 'deleted'}],
                take: TAKE,
                skip: 0
            }
        }
    ];
}

