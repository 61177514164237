import React, {useState} from "react";
import {useNatures} from "../../../Hooks/GlobalConfigContext";
import {IYearPickerDates, YearPicker} from "../../YearPicker";
import {companyPickerResult} from "../../../Utils/companyPickerResult";
import {AddItemForm} from "../../AddItemForm";
import Flex from "ibis-design-system/lib/components/core/Flex";
import {Bold, SemiBold} from "../../Bold";
import TextInput from "ibis-design-system/lib/components/inputFields/TextInput";
import CompanyPicker from "ibis-design-system/lib/components/pickers/CompanyPicker";
import {getCompanies, getCompany} from "../../../Api/requests";
import {ETrainingSource, ICreateEducationDto} from "../../../Api/Dtos/Resume/IEducationDto";
import {EOP} from "../../../Interfaces/IPatchParams";
import Dropdown from "ibis-design-system/lib/components/inputFields/Dropdown";
import {educationTitleOptions} from "../../../Constants";
import {IOption} from "../../../Interfaces/IOption";
import {onStateChange} from "../../../Utils/onStateChange";
import moment from "moment";

const INITIAL_EDUCATION: ICreateEducationDto = {
    from: moment().toISOString(),
    to: null,
    subjectOfTraining: undefined,
    teachingInstitute: undefined,
    educationName: undefined,
    title: undefined,
    trainingSource: ETrainingSource.Education
}

interface ICreateEducationProps {
    patch: (args: any) => void;
}

export const CreateEducation: React.FunctionComponent<ICreateEducationProps> = (props) => {
    const natures = useNatures()
    const [education, setEducation] = useState<ICreateEducationDto>(INITIAL_EDUCATION);

    const onChange = (key: keyof ICreateEducationDto, value: string) => {
        const result = onStateChange<ICreateEducationDto>(education, key, value);
        if (result) setEducation(result);
    }

    const setYears = (dates: IYearPickerDates): void => {
        const tmpTraining = {...education};
        tmpTraining.from = dates.start;
        tmpTraining.to = dates.end;
        setEducation(tmpTraining);
    }

    const updateTeachingInstitute = (change: any): void => {
        if (!education || !change) { return; }

        const pickerResult = companyPickerResult(change);
        const tmpTraining = {...education};
        tmpTraining.teachingInstitute = pickerResult ? pickerResult : undefined;
        setEducation(tmpTraining)
    }

    return (
        <AddItemForm closeOnAdd addButtonText={"Add education"} onAddButtonClick={() => props.patch([
            {
                op: EOP.Add,
                path: `trainings/-`,
                value: education,
            },
        ])} cancel={() => setEducation(INITIAL_EDUCATION)}>
            <Flex>
                <div style={{flex: 1, flexDirection: "column"}}>
                    <div style={{marginTop: 5}}>
                        <SemiBold style={{marginBottom: 6.5}}>Period</SemiBold>
                        <YearPicker onChange={(dates: IYearPickerDates) => setYears(dates)} dates={{start: education.from, end: education.to ?? undefined}} style={{minWidth: 130, justifyContent: "space-between"}} />
                    </div>
                </div>
                <div style={{flex: 4, flexDirection: "column"}}>
                    <Bold>Title of qualification awarded</Bold>
                    <Flex>
                        <div style={{flex: 1}}>
                            <Dropdown
                                placeholder="Level"
                                multiSelect={false}
                                selected={education?.title}
                                options={educationTitleOptions}
                                onChanged={(change: IOption)  => onChange("title", change.key)}
                            />
                        </div>
                        <div style={{flex: 4, marginLeft: 20}}>
                            <TextInput
                                placeholder="Education name"
                                value={education?.educationName}
                                onChange={(val: string) => onChange("educationName", val)}
                            />
                        </div>
                    </Flex>

                    <TextInput
                        label="Subject of training"
                        placeholder="Enter a subject"
                        value={education.subjectOfTraining}
                        onChange={(val: string) => onChange("subjectOfTraining", val)}
                    />

                    <div style={{marginTop: 20}}>
                        <CompanyPicker
                            label="Education institute"
                            placeholder="Select a education institute"
                            pickLimit={1}
                            exclude={education?.teachingInstitute ? [education?.teachingInstitute] : []}
                            selected={education?.teachingInstitute ? [education?.teachingInstitute] : []}
                            natures={natures}
                            getCompany={getCompany}
                            getCompanies={(input: string) => getCompanies(input, null, null)}
                            onChanged={(change: any) => updateTeachingInstitute(change)}
                        />
                    </div>
                </div>
            </Flex>
        </AddItemForm>
    );
};
