import React, {useState} from "react";
import {useNatures} from "../../../Hooks/GlobalConfigContext";
import {useCompany} from "../../../Hooks/useCompany";
import {IYearPickerDates} from "../../YearPicker";
import {companyPickerResult} from "../../../Utils/companyPickerResult";
import Flex from "ibis-design-system/lib/components/Atoms/Flex";
import {Bold} from "../../Bold";
import TextInput from "ibis-design-system/lib/components/inputFields/TextInput";
import CompanyPicker from "ibis-design-system/lib/components/pickers/CompanyPicker";
import {getCompanies, getCompany} from "../../../Api/requests";
import {MT12} from "../../MT12";
import Dropdown from "ibis-design-system/lib/components/inputFields/Dropdown";
import {IEducationDto} from "../../../Api/Dtos/Resume/IEducationDto";
import {IOption} from "../../../Interfaces/IOption";
import {educationTitleOptions} from "../../../Constants";
import {IBaseProps} from "../../../Interfaces/IBaseProps";
import {ResumeInfoCard} from "../../ResumeInfoCard";
import {onStateChange} from "../../../Utils/onStateChange";
import {EOP, IPatchParams} from "../../../Interfaces/IPatchParams";
import {IUserContextDto} from "../../../Api/Dtos/Resume/IUserContextDto";
import {generatePatchArrayForExistingObject} from "../../../Utils/generatePatchArray";

interface IEducationProps extends IBaseProps {
    education: IEducationDto;
    permissions: IUserContextDto;
    patch: (args: IPatchParams<any, any>[]) => void;
}

export const Education: React.FunctionComponent<IEducationProps> = (props) => {
    const natures = useNatures();
    const teachingInstitute = useCompany(props.education?.teachingInstitute);
    const [editing, setEditing] = useState<boolean>(false);
    const [education, setEducation] = useState<IEducationDto | null | undefined>(props.education);

    const onChange = (key: keyof IEducationDto, value: string) => {
        const result = onStateChange<IEducationDto>(education, key, value);
        if (result) setEducation(result);
    }

    const changeDates = (dates: IYearPickerDates) => {
        if (!props.education || !education) return;

        const tmpEducation: IEducationDto = {...education};
        tmpEducation.from = dates.start ? dates.start : null;
        tmpEducation.to = dates.end ? dates.end : null;
        setEducation(tmpEducation);
    }

    const updateTeachingInstitute = (change: any): void => {
        if (!education || !change) { return; }

        const pickerResult = companyPickerResult(change);
        const tmpEducation = {...education};
        tmpEducation.teachingInstitute = pickerResult;
        teachingInstitute.setCompanyId(pickerResult);
        setEducation(tmpEducation)
    }

    const onUpdate = (): void => {
        props.patch(generatePatchArrayForExistingObject(props.education, education, `trainings/${education?.id}`));
        setEditing(false);
    };

    return (
        <ResumeInfoCard
            editing={editing}
            setEditing={setEditing}
            period={{start: education && education.from ? education.from : undefined, end: education && education.to ? education.to : undefined}}
            updatePeriod={(dates: IYearPickerDates) => changeDates(dates)}
            permissions={props.permissions}
            onUpdate={onUpdate}
            onCancel={() => setEducation(props.education)}
            style={props.style}
            onDelete={() => props.patch([
                    {
                        op: EOP.Remove,
                        path: `trainings/${education?.id}`,
                    }
                ])}
        >
            <Bold>Title of qualification awarded</Bold>
            <Flex>
                <div style={{flex: 1}}>
                    { editing ?
                        <Dropdown
                            placeholder="Level"
                            multiSelect={false}
                            selected={education?.title}
                            options={educationTitleOptions}
                            onChanged={(change: IOption)  => onChange("title", change.title)}
                        />
                        :
                        <MT12>{education?.title}</MT12>
                    }
                </div>
                <div style={{flex: 4, marginLeft: 20}}>
                    { editing ?
                        <TextInput
                            placeholder="Education name"
                            value={props.education?.educationName}
                            onChange={(val: string) => onChange("educationName", val)}
                        />
                        :
                        <MT12>{education?.educationName}</MT12>
                    }
                </div>
            </Flex>

            <Bold style={{marginTop: 27}}>Subjects</Bold>
            { editing ?
                <TextInput
                    placeholder="Subject"
                    value={props.education?.subjectOfTraining}
                    onChange={(val: string) => onChange("subjectOfTraining", val)}
                />
                :
                <MT12>{education?.subjectOfTraining}</MT12>
            }

            <Bold style={{marginTop: 27}}>Education institute</Bold>
            { editing ?
                <CompanyPicker
                    placeholder="Select client"
                    pickLimit={1}
                    exclude={education?.teachingInstitute ? [education?.teachingInstitute] : []}
                    selected={education?.teachingInstitute ? [education?.teachingInstitute] : []}
                    natures={natures}
                    getCompany={getCompany}
                    getCompanies={(input: string) => getCompanies(input, null, null)}
                    onChanged={(change: any) => updateTeachingInstitute(change)}
                />
                :
                <MT12 style={{paddingBottom: 27}}>{teachingInstitute.company?.name}</MT12>
            }
        </ResumeInfoCard>
    )
};
