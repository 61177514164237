export interface IPatchParams<T, Y> {
    op: EOP,
    path: Y,
    value?: T,
}

export enum EOP {
    Replace = "replace",
    Remove = "remove",
    Add = "add"
}