import React, { useState } from "react";
import { IKeyQualifications } from "../../../Api/Dtos/Resume/IKeyQualificationsDto";
import { IUserContextDto } from "../../../Api/Dtos/Resume/IUserContextDto";
import { IBaseProps } from "../../../Interfaces/IBaseProps";
import { EOP, IPatchParams } from "../../../Interfaces/IPatchParams";
import { onStateChange } from "../../../Utils/onStateChange";
import { WYSIWYG_dataConversion } from "../../../Utils/WYSIWYG_dataConversion";
import { MarginTopBlock } from "../../MarginTopBlock";
import { ResumeInfoCard } from "../../ResumeInfoCard";
import WYSIWYGEditor from "ibis-design-system/lib/components/inputFields/WYSIWYGEditor";
import TextInput from "ibis-design-system/lib/components/inputFields/TextInput";
import {MT12} from "../../MT12";
import { SemiBold } from "../../Bold";

interface IKeyQualificationsProps extends IBaseProps {
    keyQualifications: IKeyQualifications;
    patch: (args: IPatchParams<any, any>[]) => void;
    permissions: IUserContextDto;

}

export const KeyQualificationsItem: React.FunctionComponent<IKeyQualificationsProps> = (props) => {
    const [keyQualifications, setKeyQualifications] = useState<IKeyQualifications | null | undefined>(props.keyQualifications);
    const [editing, setEditing] = useState<boolean>(false);

    const onChange = (key: keyof IKeyQualifications, value: any) => {
        const result = onStateChange<IKeyQualifications>(keyQualifications, key, value);
        if (result) setKeyQualifications(result);
    }

    const updateQualifications = (change: any): void => {
        if (!keyQualifications || !change) { return; }
        const tmpKeyQualifications = {...keyQualifications};
        setKeyQualifications(tmpKeyQualifications)
    }

    const updateKeyQualifications = (): void => {

        const updateArr: IPatchParams<any, any>[] = [
            {
                op: EOP.Replace,
                value: keyQualifications?.qualification,
                path: `/keyQualifications/${props.keyQualifications.id}/qualification`
            },
            {
                op: EOP.Replace,
                value: keyQualifications?.title,
                path: `/keyQualifications/${props.keyQualifications.id}/title`
            },
        ]

        props.patch(updateArr);
        setEditing(false);
    }
return(
    <ResumeInfoCard
    editing={editing}
    disablePeriod={true}
    setEditing={setEditing}
    onUpdate={updateKeyQualifications}
    onCancel={() => setKeyQualifications(props.keyQualifications)}
    style={props.style}
    permissions={props.permissions}
    onDelete={() => props.patch([
        {
            op: EOP.Remove,
            path: `/keyQualifications/${props.keyQualifications.id}`
        }
    ])}
>
   
        <MarginTopBlock>
            { keyQualifications?.title === null ? "" :
                <SemiBold>{editing ? 'Title' : 'Title:'}</SemiBold>}
                { editing ?
                <TextInput
                        placeholder="Title of key Qualification"
                        value={keyQualifications?.title}
                        onChange={(val: string) => onChange("title", val)}
                        />
                        :
                <MT12>{keyQualifications?.title}</MT12>
                }
            
            <WYSIWYGEditor
                style={{display: "block !important"}}
                content={WYSIWYG_dataConversion(props.keyQualifications.qualification).replace(/<\/?p>/g, '')}
                onUpdate={(val: string) => onChange("qualification", val)}
                label="Key Qualification"
                readOnly={!editing}
            />
        </MarginTopBlock>
    </ResumeInfoCard>
)
}