import {EUserSource, IUserOrContact} from "../Components/UserOrContactPicker";

export const createUserOrContact = (tagKey: string): IUserOrContact => {
    const sourceAndId = tagKey.split(":");
    const source = Number(sourceAndId[0]);

    return {
        id: sourceAndId[1],
        source: source === EUserSource.Deltares ? EUserSource.Deltares : EUserSource.CompanyContactPerson
    }
}