import styled from "ibis-design-system/lib/HelperFunctions/ThirdParty/styled-components";

export const TileGrid = styled.ul`
    display: grid;
    grid-template-columns: 150px 150px repeat(auto-fill, 150px);
    grid-template-rows: repeat(auto-fill, 150px);
    grid-gap: 8px;
    list-style: none;
    width: 100%;
    padding: 0;
    overflow: auto;
`;
