import React, {useEffect, useMemo, useState} from "react";
import {TileGrid} from "../TileGrid";
import {TileFilled} from "../TileFilled";
import {IResumeDto} from "../../Api/Dtos/Resume/IResumeDto";
import {dateDisplay} from "../../Utils/dateDisplay";

import {findTendersForUser, getActiveCompaniesForUser} from "../../Api/requests";
import {request} from "../../Utils/request";
import {ICompanyContactPerson} from "../../Api/Dtos/ICompanyPersonDto";

interface ITilesProps {
    item: IResumeDto
}

export const Tiles: React.FunctionComponent<ITilesProps> = (props) => {

    const [activeCompanies, setActiveCompanies] = useState<string>("-");
    const [tenderMentions, setTenderMentions] = useState<string>("-");

    useEffect(() => {
        getActiveInCompaniesForPerson(props.item.createdBy);
        findTenderReferences();
    }, []);
    function yearsWorked(currentDate = new Date()) {
        const startYear = new Date(props.item.employmentStartDate).getFullYear();
        const currentYear = currentDate.getFullYear();
        const years = currentYear - startYear;
        return years;
      }
    const daysSinceLastUpdate = useMemo(() => dateDisplay(props.item.lastModificationDate), [props.item]);
    const resumeFillRate = useMemo(() => {
        const fields: any[] = Object.keys(props.item).map( (key) => key);
        const singleFieldFillValue = 100 / fields.length;
        let filled = 0;
        fields.forEach( field => props.item[field as keyof IResumeDto] ? filled += singleFieldFillValue : undefined );
        return filled.toFixed(0);
    },[props.item]);

    const getActiveInCompaniesForPerson = async (personId: string): Promise<void> => {
        const { result, error } = await request<ICompanyContactPerson[]>(getActiveCompaniesForUser(personId));
        if (!result || error || !result.length) return;
        setActiveCompanies(result.length + '');
    }

    const findTenderReferences = async (): Promise<any> => {
        if (!props.item.user || !props.item.user.id) return;
        const { result, error } = await request(findTendersForUser(props.item.user?.id));
        if (!result || error) return;
        setTenderMentions(result.totalCount + "")
    };

    return (
        <TileGrid>
            <TileFilled title={`${daysSinceLastUpdate}`} subtext="Days since last update" warning={false} />
            <TileFilled title={`${resumeFillRate}`} subtext="Resume fill rate" warning={false} percentage />
            <TileFilled title={yearsWorked().toString()} subtext="Years at Deltares" warning={false} />
            <TileFilled title={props.item.nationality} titleStyles={{fontSize: props.item.nationality && props.item.nationality.length > 5 ? 20 : 52}} subtext="Nationality" warning={false} />

        </TileGrid>
    );
};
