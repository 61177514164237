import React from "react";
import {IContainerProps} from "../../Interfaces/IContainterProps";

import {hasPermission} from "../../Utils/hasPermission";
import { AssociationsItem } from "./Associations/AssociationsItem";
import { CreateAssociations } from "./Associations/CreateAssociations";


interface ICreateAssociationsProps extends IContainerProps {
    style?: React.CSSProperties;
}

export const Associations: React.FunctionComponent<ICreateAssociationsProps> = (props) => {
    return (
        <div>
            {props.item.associations?.map( (associations:any) => <AssociationsItem key={associations.id} permissions={props.item.userContext} associations={associations} patch={props.patch}  /> )}
            {hasPermission(props.item.userContext.permissions) &&
                <CreateAssociations patch={props.patch}/>
            }
        </div>
    );
};
