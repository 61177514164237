import React, {useState} from "react";
import {AddItemForm} from "../../AddItemForm";
import {ICreateEuropassLanguageDto, IEuropassLanguageDto} from "../../../Api/Dtos/Resume/IEuropassLanguageDto";
import {ELanguageLevel} from "../../../Api/Dtos/Resume/ELanguageLevel";
import {EuropassLanguage} from "./EuropassLanguage";
import {EOP, IPatchParams} from "../../../Interfaces/IPatchParams";
import {onStateChange} from "../../../Utils/onStateChange";
import {IUserContextDto} from "../../../Api/Dtos/Resume/IUserContextDto";
import {IResumeDto} from "../../../Api/Dtos/Resume/IResumeDto";

const INIT_PASS_LANGUAGE: IEuropassLanguageDto = {
    id: "newEuropass",
    isMotherTongue: false,
    language: "",
    listening: ELanguageLevel.A1,
    reading: ELanguageLevel.A1,
    spokenInteraction: ELanguageLevel.A1,
    spokenProduction: ELanguageLevel.A1,
    writing: ELanguageLevel.A1

}

interface IAddEuropassLanguageProps {
    patch: (args: IPatchParams<any, any>[]) => void;
    userContext: IUserContextDto;
    item: IResumeDto;
}

export const AddEuropassLanguage: React.FunctionComponent<IAddEuropassLanguageProps> = (props) => {

    const [europassLanguage, setEuropassLanguage] = useState<IEuropassLanguageDto>(INIT_PASS_LANGUAGE);

    const onChange = (key: keyof IEuropassLanguageDto, value: any): void => {
        const result = onStateChange<IEuropassLanguageDto>(europassLanguage, key, value);
        if (result) setEuropassLanguage(result);
    }

    return (
        <AddItemForm closeOnAdd addButtonText="Add language" cancel={() => setEuropassLanguage(INIT_PASS_LANGUAGE)} onAddButtonClick={() => {
            let tmpEuropass: ICreateEuropassLanguageDto = {
                language: europassLanguage.language,
                reading: europassLanguage.reading as any,
                listening: europassLanguage.listening as any,
                spokenInteraction: europassLanguage.spokenInteraction as any,
                writing: europassLanguage.writing as any,
                spokenProduction: europassLanguage.spokenProduction as any,
                isMotherTongue: europassLanguage.isMotherTongue,
            }

            // Before we add the language, let's make sure it doesn't already exist for this user.
            if(props.item.europassLanguages?.find( l => l.language === tmpEuropass.language) !== undefined) return;

            props.patch([
                {
                    op: EOP.Add,
                    path: `EuropassLanguages/-`,
                    value: tmpEuropass
                }
            ])
        }}>
            <EuropassLanguage
                europass={europassLanguage}
                patch={(value: IPatchParams<string, keyof IEuropassLanguageDto | string>[]) => onChange(value[0].path as keyof IEuropassLanguageDto, value[0].value)}
                usingAsCreateForm={true}
                userContext={props.userContext}
            />
        </AddItemForm>
    );
};
