import React from "react";
import {IContainerProps} from "../../../Interfaces/IContainterProps";
import {LanguageLevel} from "./LanguageLevel";
import {AddLanguageTable} from "./AddLanguageTable";
import {hasPermission} from "../../../Utils/hasPermission";
import { Bold } from "../../Bold";

interface ILanguageProps extends IContainerProps {}

export const LanguageTable: React.FunctionComponent<ILanguageProps> = (props) => {

    return (
        <div style={{width: "100%"}}>
            <Bold><p>Language Skills</p></Bold>
            { props.item.languages && props.item.languages.map( ep => <LanguageLevel key={ep.id} patch={props.patch} languages={ep} userContext={props.item.userContext} /> )}
            { hasPermission(props.item.userContext.permissions) &&
                <AddLanguageTable patch={props.patch} userContext={props.item.userContext} item={props.item}  />
            }
        </div>
    );
};
