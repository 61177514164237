import React from "react";
import {EuropassHeader} from "./EuropassHeader";
import {IContainerProps} from "../../../Interfaces/IContainterProps";
import {EuropassLanguage} from "./EuropassLanguage";
import {AddEuropassLanguage} from "./AddEuropassLanguage";
import {hasPermission} from "../../../Utils/hasPermission";

interface IEuropassProps extends IContainerProps {}

export const Europass: React.FunctionComponent<IEuropassProps> = (props) => {

    return (
        <div style={{width: "100%"}}>
            <EuropassHeader />
            { props.item.europassLanguages && props.item.europassLanguages.map( ep => <EuropassLanguage key={ep.id} patch={props.patch} europass={ep} userContext={props.item.userContext} /> )}
            { hasPermission(props.item.userContext.permissions) &&
                <AddEuropassLanguage patch={props.patch} userContext={props.item.userContext} item={props.item} />
            }
        </div>
    );
};
