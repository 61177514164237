import React, {useMemo} from "react";
import {IButtonStyles, IconButton, IContextualMenuProps} from "office-ui-fabric-react";
import {IBaseProps} from "../Interfaces/IBaseProps";

interface IDotMenuProps extends IBaseProps {
    menuProps: IContextualMenuProps;
    icon?: string;
}

export const DotMenu: React.FunctionComponent<IDotMenuProps> = (props) => {

    const customSplitButtonStyles: IButtonStyles = useMemo(() => {
        return {
            icon: {
                color: "black",
            },
        }
    }, []);

    if (props.children) {
        return (
            <IconButton
                style={props.style}
                menuProps={props.menuProps}
                menuIconProps={{style: {display: "none"}}}
            >
                <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%"}}>
                    <div>{props.children}</div>
                    <IconButton
                        styles={customSplitButtonStyles}
                        iconProps={{iconName: props.icon ? props.icon : 'More'}}
                    >
                    </IconButton>
                </div>
            </IconButton>
        );
    }

    return (
        <div style={props.style} className={props.className}>
            <IconButton
                styles={customSplitButtonStyles}
                iconProps={{iconName: props.icon ? props.icon : 'More'}}
                menuProps={props.menuProps} menuIconProps={{style: {display: "none"}}}
            />
        </div>
    );
};
