/**
 * Takes an object, and updates a value on this object based on a key and new value.
 * Can be used for state updating objects.
 * @param obj - The object we want to update.
 * @param key - The key of the property we wish to update.
 * @param value - The value we wish to set on the passed key property.
 */
export const onStateChange = <T>(obj: T | undefined | null, key: keyof T, value: any): T | undefined => {
    if (!obj) return;

    const tmpObj: T = {...obj};
    tmpObj[key] = value;
    return tmpObj;
}