import React, {useEffect, useState} from "react";
import styled from 'ibis-design-system/lib/HelperFunctions/ThirdParty/styled-components';
import Text from 'ibis-design-system/lib/components/Atoms/Text';
import Icon from 'ibis-design-system/lib/components/core/Icon';
import {IFoundation} from "../../Interfaces/IFoundation";
import {ExportCard} from "./ExportCard";
import {downloadTemplate} from "../../Api/requests";
import {useNavigationState} from "ibis-design-system/lib/Providers/NavigationProvider";
import {useTemplateDownloadOptions} from "../../Hooks/GlobalConfigContext";
import { request } from "../../Utils/request";
import { IResumeDto } from "../../Api/Dtos/Resume/IResumeDto";

interface IExportMenuProps {
    exportOptionsUrl: string;
    isOpen?: boolean;
    onClose?: () => void;
    item: any;
}

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 11px;
`

const Menu = styled.div`
  display: ${(props: any) => props.isOpen ? 'block' : 'none'};
  position: absolute;
  top: 0;
  overflow-x: scroll;
  right: 0;
  width: 340px;
  height: 98%;
  border: 1px solid #00000026;
  z-index: 2;
  background-color: ${(props: IFoundation) => props.theme.colors.neutralLight};
  padding: 0 5px 15px 0;
`;

export const ExportMenu: React.FunctionComponent<IExportMenuProps> = (props) => {

    const { entities, selection } = useNavigationState();
    const templateDownloadOptions = useTemplateDownloadOptions();
    const [isExporting, setIsExporting] = useState<string[]>([]);

    useEffect(() => {
        if (!props.exportOptionsUrl) return;
    }, [props.exportOptionsUrl]);

    const downloadResume = async (name: string): Promise<void> => {
        setIsExporting(prevState => [...prevState, name]);    
        try {
          if (!selection || !selection.length || !entities || !props.item) return;
      
          for (const selected of selection) {
            await request(downloadTemplate(entities[selected].id, `${name}`, props.item[selected].nickname, props.item[selected].lastname));
          }
        } catch (error) {
        }
        setIsExporting(prevState => prevState.filter(resumeName => resumeName !== name));
      };

    return (
        <Menu isOpen={props.isOpen}>
            <Top>
                <Text fontWeight={'bold'} style={{fontSize: 21}}>Export to document</Text>
                <Icon style={{cursor: "pointer"}} name="StatusCircleErrorX" size={'l'} onClick={props.onClose} />
            </Top>
            {templateDownloadOptions?.map( (exportCards, index) => {
                return (
                    <ExportCard
                        style={{marginTop: index > 0 ? 1 : 0}}
                        name={exportCards.item2!}
                        onClick={() => downloadResume(exportCards.item2!)}
                        key={index}
                        exportingList={isExporting}
                    />
                    
            )})}
        </Menu>
    );
};
