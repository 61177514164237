import React, {useState} from "react";
import {AddItemForm} from "../../AddItemForm";
import {ICreateEmploymentRecord} from "../../../Api/Dtos/Resume/IEmploymentRecordDto";
import moment from "moment";
import TextInput from "ibis-design-system/lib/components/inputFields/TextInput";
import {MarginTopBlock} from "../../MarginTopBlock";
import WYSIWYGEditor from "ibis-design-system/lib/components/inputFields/WYSIWYGEditor";
import {WYSIWYG_dataConversion} from "../../../Utils/WYSIWYG_dataConversion";
import {ICountryDto} from "../../../Api/Dtos/ICountryDto";
import Flex from "ibis-design-system/lib/components/Atoms/Flex";
import CompanyPicker from "ibis-design-system/lib/components/pickers/CompanyPicker";
import {getCompanies, getCompany} from "../../../Api/requests";
import {onStateChange} from "../../../Utils/onStateChange";
import {useCountries, useNatures} from "../../../Hooks/GlobalConfigContext";
import {companyPickerResult} from "../../../Utils/companyPickerResult";
import {EOP, IPatchParams} from "../../../Interfaces/IPatchParams";
import {createUserOrContact} from "../../../Utils/createUserOrContact";
import {SemiBold} from "../../Bold";
import {IYearPickerDates, YearPicker} from "../../YearPicker";
import { GenericPicker } from "../../GenericPicker";
import { ITag } from "office-ui-fabric-react";

const INITIAL_RECORD: ICreateEmploymentRecord = {
    from: moment().toISOString(),
    to: null,
    countriesOfWorkExperiences: undefined,
    contactPerson: undefined,
    activitiesPerformed: undefined,
    employer: undefined,
    position: undefined,
    projectReferences: undefined,
}

interface ICreateEmploymentRecordProps {
    patch: (args: IPatchParams<ICreateEmploymentRecord, string>[]) => void;
    id: string;
}

export const CreateEmploymentRecord: React.FunctionComponent<ICreateEmploymentRecordProps> = (props) => {

    const natures = useNatures();
    const countries = useCountries();
    const [employmentRecord, setEmploymentRecord] = useState<ICreateEmploymentRecord>(INITIAL_RECORD);

    const onChange = (key: keyof ICreateEmploymentRecord, value: any) => {
        const result = onStateChange<ICreateEmploymentRecord>(employmentRecord, key, value);
        if (result) setEmploymentRecord(result);
    }

    const updateEmployer = (change: any): void => {
        if (!employmentRecord || !change) { return; }

        const pickerResult = companyPickerResult(change);
        const tmpEmploymentRecord = {...employmentRecord};
        tmpEmploymentRecord.employer = pickerResult ? pickerResult : undefined;
        setEmploymentRecord(tmpEmploymentRecord)
    }

    const createNewEmploymentRecord = (): void => {
        const tmpEmploymentRecord = {...employmentRecord};
        if (tmpEmploymentRecord.contactPerson) {
            tmpEmploymentRecord.contactPerson = createUserOrContact(tmpEmploymentRecord.contactPerson.id as string);
            tmpEmploymentRecord.contactPerson = {
                // @ts-ignore
                contactId: tmpEmploymentRecord.contactPerson.id,
                source: tmpEmploymentRecord.contactPerson.source,
                employmentRecordId: props.id
            };
        }
        if (tmpEmploymentRecord.countriesOfWorkExperiences) {
            //@ts-ignore
            tmpEmploymentRecord.countriesOfWorkExperiences = tmpEmploymentRecord.countriesOfWorkExperiences.map( ct => {
                return (
                    {
                        employmentRecordId: props.id,
                        countryId: ct,
                        countryOfWorkExperienceId: ct
                    }
                )
            });
        }
        if (tmpEmploymentRecord.projectReferences) {
            //@ts-ignore
            tmpEmploymentRecord.projectReferences = tmpEmploymentRecord.projectReferences.map( ct => {
                return (
                    {
                        employmentRecordId: props.id,
                        ProjectReferenceId: ct
                    }
                )
            });
        }
        props.patch([
            {
                op: EOP.Add,
                path: "employmentRecords/-",
                value: tmpEmploymentRecord
            }
        ]);
        setEmploymentRecord(INITIAL_RECORD);
    }

    const setYears = (dates: IYearPickerDates): void => {
        const tmpRecord = {...employmentRecord};
        tmpRecord.from = dates.start;
        tmpRecord.to = dates.end;
        setEmploymentRecord(tmpRecord);
    }

    return (
        <AddItemForm
            addButtonText={"Add Employment Record"}
            onAddButtonClick={createNewEmploymentRecord}
            cancel={() => setEmploymentRecord(INITIAL_RECORD)}
            closeOnAdd
        >
            <Flex style={{width: "100%"}}>
                <div style={{flex: 1, marginTop: 5}}>
                    <SemiBold>Period</SemiBold>
                    <YearPicker
                        style={{marginTop: 7}}
                        dates={{
                        start: employmentRecord.from ? employmentRecord.from : undefined,
                        end: employmentRecord.to ? employmentRecord.to : undefined
                    }} onChange={(dates: IYearPickerDates) => setYears(dates)} />
                </div>
                <div style={{flex: 4}}>
                    <TextInput
                        label="Position"
                        placeholder="Position"
                        value={employmentRecord?.position}
                        onChange={(val: string) => onChange("position", val)}
                    />
                </div>
            </Flex>

            <MarginTopBlock>
                <WYSIWYGEditor
                    content={WYSIWYG_dataConversion(employmentRecord.activitiesPerformed)}
                    onUpdate={(val: string) => onChange("activitiesPerformed", val)}
                    label="Activities performed"
                />
            </MarginTopBlock>
            <MarginTopBlock>
                <GenericPicker
                    list={countries}
                    listToTags={(countries: ICountryDto[]) => countries.map<ITag>( country => ({key: country.id, name: country.name }))}
                    singleTypeToTag={(country: ICountryDto) => ({key: country.id, name: country.name })}
                    onChange={(countries: string[] | undefined) => onChange("countriesOfWorkExperiences", countries)}
                    label="Countries of work experience"
                    pickLimit={5}
                />
            </MarginTopBlock>

            <MarginTopBlock>
                <Flex>
                    <div style={{flex: 1}}>
                        <CompanyPicker
                            label="Employer"
                            pickLimit={1}
                            exclude={employmentRecord?.employer ? [employmentRecord?.employer] : []}
                            selected={employmentRecord?.employer ? [employmentRecord?.employer] : []}
                            natures={natures}
                            getCompany={getCompany}
                            getCompanies={(input: string) => getCompanies(input, null, null)}
                            onChanged={(change: any) => updateEmployer(change)}
                        />
                    </div>
                    <div style={{flex: 1, marginLeft: 20}}>
                    <TextInput
                        label="Reference contact person"
                        value={employmentRecord?.oldReferenceContactPerson}
                        onChange={(val: string) => onChange("oldReferenceContactPerson", val)}
                        />
                    </div>
                </Flex>
            </MarginTopBlock>

            {/* <MarginTopBlock>
                <ProjectPicker fetchProjects={getProjects} onChange={(projects: string[] | undefined) => onChange("projectReferences", projects)} label="Project references" />
            </MarginTopBlock> */}
        </AddItemForm>
    );
};
