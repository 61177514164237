import React from "react";
import styled from "ibis-design-system/lib/HelperFunctions/ThirdParty/styled-components";
import {IFoundation} from "../Interfaces/IFoundation";

const InputLabel = styled.div`
    font-weight: ${(props: IFoundation) => props.theme.fontWeights.bold};
`;
const InputBlock = styled.div`
  padding: 6px 8px;
  min-height: 22px;
  background-color: #0000000C;
`;

interface IReadOnlyInputProps {
    label?: string;
    text?: string;
}

export const ReadOnlyInput: React.FunctionComponent<IReadOnlyInputProps> = (props) => {
    return (
        <div>
            <InputLabel>{props.label}</InputLabel>
            <InputBlock style={{marginTop: props.label ? 5 : 0}}>
                {props.text}
            </InputBlock>
        </div>
    );
};
