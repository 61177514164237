import React, {useEffect, useMemo, useState} from "react";
import {IYearPickerDates} from "../../YearPicker";
import {IBaseProps} from "../../../Interfaces/IBaseProps";
import {useCountries, useNatures} from "../../../Hooks/GlobalConfigContext";
import {useCompany} from "../../../Hooks/useCompany";
import {IEmploymentRecordDto, IReferenceContactPerson} from "../../../Api/Dtos/Resume/IEmploymentRecordDto";
import {ResumeInfoCard} from "../../ResumeInfoCard";
import {SemiBold} from "../../Bold";
import TextInput from "ibis-design-system/lib/components/inputFields/TextInput";
import {MT12} from "../../MT12";
import {onStateChange} from "../../../Utils/onStateChange";
import {MarginTopBlock} from "../../MarginTopBlock";
import CompanyPicker from "ibis-design-system/lib/components/pickers/CompanyPicker";
import {getCompanies, getCompany, getPerson} from "../../../Api/requests";
import {companyPickerResult} from "../../../Utils/companyPickerResult";
import {ICountryDto} from "../../../Api/Dtos/ICountryDto";
import Flex from 'ibis-design-system/lib/components/Atoms/Flex';
import {ReferencePersonPicker} from "../../ReferencePersonPicker";
import {getInitials, IPersonaProps, ITag} from "office-ui-fabric-react";
import {yearPickerDates} from "../../../Utils/yearPickerDates";
import {EOP, IPatchParams} from "../../../Interfaces/IPatchParams";
import {IUserContextDto} from "../../../Api/Dtos/Resume/IUserContextDto";
import {EUserSource} from "../../UserOrContactPicker";
import {request} from "../../../Utils/request";
import {IPersonDto} from "../../../Api/Dtos/IPersonDto";
import {getUser} from "../../../Misc";
import {useCacheState} from "ibis-design-system/lib/Providers/HttpCacheProvider";
import {IUserDetails} from "../../../Api/Dtos/IUserDto";
import { GenericPicker } from "../../GenericPicker";
import WYSIWYGEditor from "ibis-design-system/lib/components/inputFields/WYSIWYGEditor";
import {WYSIWYG_dataConversion} from "../../../Utils/WYSIWYG_dataConversion";

interface IEmploymentRecordItemProps extends IBaseProps {
    employmentRecord: IEmploymentRecordDto;
    patch: (args: IPatchParams<any, any>[]) => void;
    permissions: IUserContextDto;

}

export const EmploymentRecordItem: React.FunctionComponent<IEmploymentRecordItemProps> = (props) => {
    
    const httpCache = useCacheState();
    const natures = useNatures();
    const countries = useCountries();
    const employer = useCompany(props.employmentRecord?.employer);
    const [editing, setEditing] = useState<boolean>(false);
    const [employmentRecord, setEmploymentRecord] = useState<IEmploymentRecordDto | null | undefined>(props.employmentRecord);

    const countryNames = useMemo(() => {
        return props.employmentRecord.countriesOfWorkExperiences?.map( wc => {
            let country = countries.find( (country: ICountryDto) => country.id === wc.countryOfWorkExperienceId);
            if (!country) return "";
            return country.name;
        });
    }, [props.employmentRecord, countries]);

    const changeDates = (dates: IYearPickerDates) => {
        if (!props.employmentRecord || !employmentRecord) return;

        const tmpEmploymentRecord: IEmploymentRecordDto = {...employmentRecord};
        tmpEmploymentRecord.from = dates.start ? dates.start : null;
        tmpEmploymentRecord.to = dates.end ? dates.end : null;
        setEmploymentRecord(tmpEmploymentRecord);
    }

    const onChange = (key: keyof IEmploymentRecordDto, value: any) => {
      
        const result = onStateChange<IEmploymentRecordDto>(employmentRecord, key, value);
        if (result) setEmploymentRecord(result);
    }

    const updateEmployer = (change: any): void => {
        if (!employmentRecord || !change) { return; }

        const pickerResult = companyPickerResult(change);
        const tmpEmploymentRecord = {...employmentRecord};
        tmpEmploymentRecord.employer = pickerResult;
        employer.setCompanyId(pickerResult);
        setEmploymentRecord(tmpEmploymentRecord)
    }

    const updateRecord = (): void => {

        const updateArr: IPatchParams<any, any>[] = [
            {
                op: EOP.Replace,
                value: employmentRecord?.position,
                path: `/employmentRecords/${props.employmentRecord.id}/position`
            },
            {
                op: EOP.Replace,
                value: employmentRecord?.activitiesPerformed,
                path: `/employmentRecords/${props.employmentRecord.id}/activitiesPerformed`
            },
            {
                op: EOP.Replace,
                value: employmentRecord?.mainProjectFeatures,
                path: `/employmentRecords/${props.employmentRecord.id}/mainProjectFeatures`
            },
            {
                op: EOP.Replace,
                value: employmentRecord?.countriesOfWorkExperiences?.map(ct => ({
                    countryOfWorkExperienceId: ct.countryOfWorkExperienceId,
                })),
                path: `/employmentRecords/${props.employmentRecord.id}/countriesOfWorkExperiences`
            },
            {
                op: EOP.Replace,
                value: employmentRecord?.employer,
                path: `/employmentRecords/${props.employmentRecord.id}/employer`
            },
            {
                op: EOP.Replace,
                value: employmentRecord?.projectReferences,
                path: `/employmentRecords/${props.employmentRecord.id}/projectReferences`
            },
            {
                op: EOP.Replace,
                value: employmentRecord?.oldReferenceContactPerson,
                path: `/employmentRecords/${props.employmentRecord.id}/oldReferenceContactPerson`
            },
        ]

        props.patch(updateArr);
        setEditing(false);
    }

    return (
        <ResumeInfoCard
            editing={editing}
            setEditing={setEditing}
            period={yearPickerDates(employmentRecord?.from, employmentRecord?.to)}
            updatePeriod={(dates: IYearPickerDates) => changeDates(dates)}
            onUpdate={updateRecord}
            onCancel={() => setEmploymentRecord(props.employmentRecord)}
            style={props.style}
            permissions={props.permissions}
            onDelete={() => props.patch([
                {
                    op: EOP.Remove,
                    path: `employmentRecords/${props.employmentRecord.id}`
                }
            ])}
        >
            <Flex style={{gap: '30px'}}>
                <Flex style={{width: '33%'}}>
                    <MarginTopBlock>
                        <SemiBold>{editing ? 'Position' : 'Position:'}</SemiBold>
                        { editing ?
                            <TextInput
                                placeholder="Position"
                                value={props.employmentRecord?.position}
                                onChange={(val: string) => onChange("position", val)}
                            />
                            :
                            <MT12>{props.employmentRecord?.position}</MT12>
                        }
                    </MarginTopBlock>
                </Flex>

                <Flex style={{width: '33%'}}>
                    <MarginTopBlock>
                        <SemiBold>{editing ? 'Countries of work experience' : 'Countries of work experience:'}</SemiBold>
                        { editing ?
                            <GenericPicker
                                list={countries}
                                listToTags={(countries: ICountryDto[]) => countries.map<ITag>( country => ({key: country.id, name: country.name }))}
                                selectedTags={employmentRecord?.countriesOfWorkExperiences?.map(c => c.countryOfWorkExperienceId) || []}
                                singleTypeToTag={(country: ICountryDto) => ({key: country.id, name: country.name })}
                                onChange={(countries?: string[]) => {
                                    const countryIds = countries?.map((countryId: string) => ({ countryOfWorkExperienceId: countryId }));
                                    onChange("countriesOfWorkExperiences", countryIds)}
                                }
                                pickLimit={5}
                            />
                            :
                            <MT12>{countryNames && countryNames.join(", ")}</MT12>
                        }
                    </MarginTopBlock>
                </Flex>

                <Flex style={{width: '33%'}}>     
                    <MarginTopBlock>
                        <SemiBold>{editing ? 'Employer' : 'Employer:'}</SemiBold>
                        { editing ?
                            <CompanyPicker
                                pickLimit={1}
                                exclude={employmentRecord?.employer ? [employmentRecord?.employer] : []}
                                selected={employmentRecord?.employer ? [employmentRecord?.employer] : []}
                                natures={natures}
                                getCompany={getCompany}
                                getCompanies={(input: string) => getCompanies(input, null, null)}
                                onChanged={(change: any) => updateEmployer(change)}
                            />
                            :
                            <MT12>{employer?.company?.name}</MT12>
                        }
                    </MarginTopBlock>
                </Flex>
            </Flex>
            
            <MarginTopBlock>
                <Flex style={{gap: '10px'}}>
                    <WYSIWYGEditor
                        content={WYSIWYG_dataConversion(props.employmentRecord.activitiesPerformed)}
                        onUpdate={(val: string) => onChange("activitiesPerformed", val)}
                        label={editing ? "Activities performed" : "Activities performed:"}
                        readOnly={!editing}
                    />
                </Flex>
            </MarginTopBlock>

            <MarginTopBlock>
                    { editing ?
                        <TextInput
                            label="Reference contact person"
                            value={employmentRecord?.oldReferenceContactPerson}
                            onChange={(val: string) => onChange("oldReferenceContactPerson", val)}
                        />
                        :
                        <div>
                            <SemiBold>Reference contact person</SemiBold>
                            <MT12>{employmentRecord?.oldReferenceContactPerson}</MT12>
                        </div>
                    }
            </MarginTopBlock>
        </ResumeInfoCard>
    );
};
