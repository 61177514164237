import React, {useState} from "react";
import {IBaseProps} from "../../../Interfaces/IBaseProps";
import {IYearPickerDates} from "../../YearPicker";
import {ResumeInfoCard} from "../../ResumeInfoCard";
import {Bold} from "../../Bold";
import TextInput from "ibis-design-system/lib/components/inputFields/TextInput";
import {MT12} from "../../MT12";
import {onStateChange} from "../../../Utils/onStateChange";
import {IUserContextDto} from "../../../Api/Dtos/Resume/IUserContextDto";
import { IAssociationsDto } from "../../../Api/Dtos/Resume/IAssociationsDto";
import { EOP, IPatchParams } from "../../../Interfaces/IPatchParams";

interface IAssociationsProps extends IBaseProps {
    associations: IAssociationsDto;
    patch: (args: any) => void;
    permissions: IUserContextDto;
}

export const AssociationsItem: React.FunctionComponent<IAssociationsProps> = (props) => {

    const [editing, setEditing] = useState<boolean>(false);
    const [association, setAssociation] = useState<IAssociationsDto | null | undefined>(props.associations);

    const onChange = (key: keyof IAssociationsDto, value: string) => {
        const result = onStateChange<IAssociationsDto>(association, key, value);
        if (result) setAssociation(result);
    }

    const updateAssociations = (): void => {

        const updateArr: IPatchParams<any, any>[] = [
            {
                op: EOP.Replace,
                value: association?.referenceAssociation,
                path: `/associations/${props.associations.id}/referenceAssociation`
            },
        ]

        props.patch(updateArr);
        setEditing(false);
    }

    return(
            <ResumeInfoCard
                className="associationsItemClass"
                editing={editing}
                setEditing={setEditing}
                onUpdate={updateAssociations}
                onCancel={() => setAssociation(props.associations)}
                permissions={props.permissions}
                style={props.style}
                disablePeriod={true}
                onDelete={() => props.patch([
                    {
                        op: EOP.Remove,
                        path: `/associations/${props.associations.id}`
                    }
                ])}
            >
                <Bold>Association reference</Bold>
                { editing ?
                    <TextInput
                        placeholder="Association reference"
                        value={props.associations.referenceAssociation}
                        onChange={(val: string) => onChange("referenceAssociation", val)}
                    />
                    :
                    <MT12>{association?.referenceAssociation}</MT12>
                }
            </ResumeInfoCard>

    )
}