import React from "react";
import {IContainerProps} from "../../Interfaces/IContainterProps";
import {Training} from "./EducationAndTraining/Training";
import {CreateTraining} from "./EducationAndTraining/CreateTraining";
import {Education} from "./EducationAndTraining/Education";
import {CreateEducation} from "./EducationAndTraining/CreateEducation";
import {MarginTopBlock} from "../MarginTopBlock";
import {IEducationDto} from "../../Api/Dtos/Resume/IEducationDto";
import {ITrainingDto} from "../../Api/Dtos/Resume/ITrainingDto";
import {hasPermission} from "../../Utils/hasPermission";

interface IEducationAndTrainingProps extends IContainerProps {}

export const EducationAndTraining: React.FunctionComponent<IEducationAndTrainingProps> = (props) => {
    return (
        <div>

            { props.item.educations && props.item.educations.map( (education: IEducationDto, index: number) => <Education key={education.id} permissions={props.item.userContext} style={{marginTop: index > 0 ? 1 : 0}} education={education} patch={props.patch} /> )}
            { hasPermission(props.item.userContext.permissions) &&
                <CreateEducation patch={props.patch}/>
            }

            <MarginTopBlock>
                { props.item.trainings && props.item.trainings.map( (training: ITrainingDto, index: number) => <Training key={training.id} permissions={props.item.userContext} style={{marginTop: index > 0 ? 1 : 0}} training={training} patch={props.patch} /> )}
                { hasPermission(props.item.userContext.permissions) &&
                    <CreateTraining patch={props.patch} />
                }
            </MarginTopBlock>
        </div>
    );
};
