/**
 * Can pass a firstname and lastname and return them as a well formatted single string.
 * If both name parts are undefined, the function returns undefined too.
 * @param firstname
 * @param lastname
 */
export const getFullName = (firstname?: string, lastname?: string): string | undefined => {
    if (!firstname && !lastname) return undefined;
    let parts = [firstname, lastname];
    return parts.join(" ");
}