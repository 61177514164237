import React, {useState} from "react";
import { ICreateAssociationsDto } from "../../../Api/Dtos/Resume/IAssociationsDto";
import {onStateChange} from "../../../Utils/onStateChange";
import { AddItemForm } from "../../AddItemForm";
import {IYearPickerDates, YearPicker} from "../../YearPicker";
import Flex from "ibis-design-system/lib/components/core/Flex";
import {SemiBold} from "../../Bold";
import TextInput from "ibis-design-system/lib/components/inputFields/TextInput";

const INITIAL_ASSOCIATIONS: ICreateAssociationsDto = {
    associationId: undefined,
    referenceAssociation: undefined,
}

interface ICreateAssociationsProps {
    patch: (args: any) => void;
}

export const CreateAssociations: React.FunctionComponent<ICreateAssociationsProps> = (props) => {
    const [associations, setAssociations] = useState<ICreateAssociationsDto>(INITIAL_ASSOCIATIONS);

    const onChange = (key: keyof ICreateAssociationsDto, value: string) => {
        const result = onStateChange<ICreateAssociationsDto>(associations, key, value);
        if (result) setAssociations(result);
    }


    return(
        <AddItemForm 
            addButtonText={"Add association"} 
            onAddButtonClick={() => props.patch([
                {
                    op: 'add',
                    path: `associations/-`,
                    value: {
                        ...associations,
                        associationId: '00000000-0000-0000-0000-000000000000'
                        
                    },
                },
            ])} 
            cancel={() => setAssociations(INITIAL_ASSOCIATIONS)}
            closeOnAdd
        >
            <Flex>
                <div style={{flex: 1, flexDirection: "column"}}>
                </div>
                <div style={{flex: 4, flexDirection: "column"}}>
                    <TextInput
                        label="Association reference"
                        placeholder="Association reference"
                        value={associations.referenceAssociation}
                        onChange={(val: string) => onChange("referenceAssociation", val)}
                    />
                </div>
            </Flex>
        </AddItemForm>
    )

}
