import Settings from "../Settings";
import {
    CancelToken,
    DownloadFileWithPost,
    HttpGet,
    HttpPost,
    HttpPut
} from "ibis-design-system/lib/HelperFunctions/HttpHelper";
import {ICompanyDto} from "./Dtos/ICompanyDto";
import {IMinimalProject} from "../Components/ProjectPicker";
import {IPersonCreateDto, IPersonDto} from "./Dtos/IPersonDto";
import {IReferenceContactDto} from "./Dtos/IReferenceContactDto";
import {ICalendarEventDto} from "./Dtos/ICalendarEventDto";
import {IGlobalConfigResponse} from "./Dtos/IBasicGlobalConfigEntity";
import {IResumeContactPersonCreateDto} from "./Dtos/Resume/IContactPersonDto";
import {IConnectedUserDto} from "../Components/UserOrContactPicker";
import {ICompanyPersonApiModel} from "./Dtos/ICompanyPersonApiModel";
import {IUserSearch} from "./Dtos/IUserSearch";
import {ICompanyContactPerson} from "./Dtos/ICompanyPersonDto";
import {ICountrySearchByTermDto} from "./Dtos/ICountryDto";

export const createNewResume = async (): Promise<any> => {
    return HttpPost(`${Settings.apiUrls.resumes}/resumes`, {});
}

export const updatePermissionMode = async (mode: string, id: string) => {
    return HttpPut(`${Settings.apiUrls.resumes}/resumes/${id}/permission-mode`, {mode});
}

export async function getDeltaresUserDetails(deltaresId: string, cancelToken: any, cache: any) {
    const url = `${Settings.apiUrls.singleSignOn}/users/${deltaresId}/details`;

    if (cache?.[url]) return cache[url];
    else if (cache) {
        cache[url] = await getUserDetails(url, cancelToken, cache);
        return cache[url];
    } else getUserDetails(url, cancelToken);
}

export async function findDeltaresUserByName(term: string, cache: any): Promise<IUserSearch> {
    const cancelToken = CancelToken.source();

    const url = `${Settings.apiUrls.singleSignOn}/users/details/search?take=10&skip=0&term=${term}`;

    if (cache?.[url]) return cache[url];
    else if (cache) {
        cache[url] = await getUserDetails(url, cancelToken.token, cache);
        return cache[url];
    } else return getUserDetails(url, cancelToken.token);
}

function getUserDetails(url: string, cancelToken: any, cache: any = null): Promise<any> {
    return HttpGet(url, cancelToken)
        .then((response: any) => {
            if (cache) cache[url] = response;
            return response;
        })
        .catch((error: any) => {
            switch (error.code) {
                case 499:
                    // Canceled request
                    break;
                case 400:
                    sendLog(JSON.stringify(error), `Bad request when fetching "${url}"`);
                    break;
                case 500:
                    sendLog(
                        JSON.stringify(error),
                        `Internal server error while retrieving "${url}"`
                    );
                    break;
                case 408:
                case 502:
                case 503:
                case 504:
                    sendLog(JSON.stringify(error), `Connection timeout while fetching "${url}"`);
                    break;
                default:
                    sendLog(
                        JSON.stringify(error),
                        `An unknown error occurred while fetching "${url}"`
                    );
                    break;
            }
        });
}

export async function sendLog(error: any, additionalInfo: any, userId?: any) {
    HttpPost(`${Settings.apiUrls.logService}/log/client-error`, {
        applicationId: Settings.appId,
        error: JSON.stringify(error),
        errorInfo: additionalInfo,
        url: window.location.href,
        userId: userId,
    }).catch((error: any) => {
        console.log(error);
        Promise.resolve();
    });
}

let getCompaniesSource: any = null;
export async function getCompanies(input: string, personId = null, cache: any) {
    if (getCompaniesSource !== null) getCompaniesSource.cancel();
    getCompaniesSource = CancelToken.source();

    let url = `${Settings.apiUrls.cci}/companies/details/search?take=10&skip=0&term=${input}`;
    if (personId) url += `&excludePerson=${personId}`;
    if (cache && cache[url]) {
        return cache[url];
    } else {
        return HttpGet(url, getCompaniesSource.token)
            .then((response: any) => {
                if (cache) cache[url] = response.result;
                return response.result;
            })
            .catch((error: any) => {
                // Canceled
                if (error.code === 499) return;
            });
    }
}

export async function getCompany(companyId: string, cancelToken = null): Promise<ICompanyDto | undefined> {
    if (!companyId) return;
    return await HttpGet(`${Settings.apiUrls.cci}/companies/${companyId}`, cancelToken);
}

export const getMinimalProject = async (searchInput: string, searchOptions: {take: number, skip: number}): Promise<{result: IMinimalProject[]}> => {
    return await HttpGet(`${Settings.apiUrls.projects}/projects/project/minimal?searchInput=${searchInput}&take=${searchOptions.take}&skip=${searchOptions.skip}`);
}

export const getProjectById = async (projectId: string): Promise<{result: any}> => {
    return await HttpGet(`${Settings.apiUrls.projects}/projects/${projectId}`);
}
export const getCountryById = async (countryId: string): Promise<{result: any}> => {
    return await HttpGet(`${Settings.apiUrls.tenders}//countries/${countryId}`);
}
export const getPerson = (id: string): Promise<IPersonDto> => {
    return HttpGet(`${Settings.apiUrls.cci}/persons/${id}`);
}

export const getConnectedUsers = (userId: string, name: string):Promise<IConnectedUserDto[]> => {
    const body = {
        take: 10,
        skip: 0,
        filters: [
            {
                type: "user",
                parameters: [
                    {
                        name: "id",
                        value: userId
                    }
                ]
            },
            {
                type: "notArchived"
            },
            {
                type: "text",
                parameters: [
                    {
                        name: "term",
                        value: name
                    }
                ]
            }
        ],
        sorting: {
            type: "lastModificationDate",
            parameters: [
                {
                    name: "order",
                    value: "descending"
                }
            ]
        }
    }
    return HttpPost(`${Settings.apiUrls.resumes}/ConnectedUser/${name}`, body);
}

export const createPerson = (newPerson: IPersonCreateDto): Promise<IReferenceContactDto> => {
    return HttpPost(`${Settings.apiUrls.cci}/persons`, newPerson);
}
export const addPersonToCompany = (personId: string, companyId: string): Promise<void> => {
    const companyPerson: ICompanyPersonApiModel = {}
    return HttpPost(`${Settings.apiUrls.cci}/persons/${personId}/companies/${companyId}`, companyPerson);
}

export const getCalenderEventsForActiveUser = (userId: string): Promise<IGlobalConfigResponse<Array<{entity: ICalendarEventDto, sortKey: string}>>> => {
    return HttpPost(`${Settings.apiUrls.calendars}/events/search`, {
        skip: 0,
        take: 50,
        filters: [
            {
                type: 'user',
                parameters: [{ name: 'id', value: userId }],
            },
            { type: 'notArchived' },
        ],
    });
}

export const createContact = (newContact: IResumeContactPersonCreateDto): Promise<void> => {
    return HttpPost(`${Settings.apiUrls.resumes}/resume/contacts`, newContact);
}

export const followResume = async (resumeId: string, isFollowing: boolean): Promise<void> => {
    if (isFollowing) {
        return HttpPut(`${Settings.apiUrls.resumes}/resumes/${resumeId}/follow`);
    }

    return HttpPut(`${Settings.apiUrls.resumes}/resumes/${resumeId}/unfollow`);
}

export const archiveResume = async (resumeId: string): Promise<void> => {

    return HttpPut(`${Settings.apiUrls.resumes}/resumes/${resumeId}/archive`);
 }

 export const unArchiveResume = async (resumeId: string): Promise<void> =>{
    return HttpPut(`${Settings.apiUrls.resumes}/resumes/${resumeId}/unarchive`);
 }

export const getActiveCompaniesForUser = async (personId: string): Promise<ICompanyContactPerson[]> => {
    return HttpPut(`${Settings.apiUrls.cci}/persons/${personId}/companies`);
}

export const downloadTemplate = async (resumeId: string, templateName: string, name: string, lastName: string,): Promise<any> => {
    return await DownloadFileWithPost(`${Settings.apiUrls.resumes}/${resumeId}/download/${templateName}/${2}`, null, name + " " + lastName +", "  + templateName, + ".docx")
}

export const downloadDocumentZip = async (ResumeId: string, templateName: string): Promise<any> => {
    return await DownloadFileWithPost(`${Settings.apiUrls.resumes}/download/${templateName}/${2}`)
}
/**
 * Find a country details using a string value (for example a name).
 * @param term - The term you want to search with.
 * @param take - The amount of results you wish to take, default = 1.
 * @param skip - The amount of results to skip, default = 0.
 */
export const searchCountryByTerm = (term: string, take?: number, skip?: number): Promise<ICountrySearchByTermDto> => {
    return HttpGet(`${Settings.apiUrls.globalConfig}/countries/details/search?term=${term}&take=${take ? take : 1}&skip=${skip ? skip : 0}`);
}

export const isAdmin = async () => {
    return HttpGet(`${Settings.apiUrls.globalConfig}/permissions/access`);
}
/**
 * Find all tenders that have the passed user in the team, or are created by the passed user.
 * @param userId
 * @returns An array of tenders
 */
export const findTendersForUser = async (userId: string): Promise<{entities: any[], skip: number, totalCount: number}> => {
    const search = {
        "take": 50,
        "skip": 0,
        "filters": [
            {
                "type": "my-tenders",
                "parameters": [
                    {
                        "name": "id",
                        "value": userId
                    }
                ]
            }
        ],
        "sorting": {
            "type": "lastModificationDate",
            "parameters": [
                {
                    "name": "order",
                    "value": "descending"
                }
            ]
        }
    }

    return HttpPost(`${Settings.apiUrls.tenders}/tenders/search`, search);
}
