import React from "react";
import {IBaseProps} from "../Interfaces/IBaseProps";
import Box from "ibis-design-system/lib/components/Atoms/Box/Box";

/**
 * Provides a box with 100% width.
 * @param props - IBaseProps
 */
export const Container: React.FunctionComponent<IBaseProps> = (props) => {
    return (
        <Box width="100%" stye={props.style} className={props.className}>
            {props.children}
        </Box>
    );
};
