import React from "react";
import {IBaseProps} from "../Interfaces/IBaseProps";
import Box from "ibis-design-system/lib/components/Atoms/Box/Box";

/**
 * Provides some base padding and width for it's content.
 * @param props - IBaseProps
 */
export const ContainerContent: React.FunctionComponent<IBaseProps> = (props) => {
    return (
        <Box padding="30px" paddingTop="0px" width="100%" style={props.style} className={props.className}>
            {props.children}
        </Box>
    );
};
