import {IActionArrayItem} from "../Interfaces/IActionArrayItem";
import {createActionArrayItem} from "./createActionArrayItem";

export const createActionArray = (selection: any, entities: any, updateFollowing: any, archiveItem:any, setExportMenuIsOpen: (isOpen: boolean) => void): IActionArrayItem[] => {
    if (!selection || selection.length < 1) return [];
    let actionArray = [];

    // const startFollowing = () => {
    //     for (let i = 0; i < selection.length; i++) {
    //         const item = { ...entities[selection[i]] };
    //         if (!item) continue;
    //         followTender(item.id);
    //     }
    // };

    let isFollowingAtleastOneItem = false;
    for (let i = 0; i < selection.length; i++) {
        const item = entities[selection[i]];

        if (item.isFollowing) {
            isFollowingAtleastOneItem = true;
            break;
        }
    }

    let isArchived =  false;
    for (let i = 0; i < selection.length; i++) {
        if (entities) {
            const item = entities[selection[i]];
            if (item.state === 2 || item.isArchived) {
                isArchived = true;         
                break;
            }
        }
        
    }
    actionArray.push(createActionArrayItem(
        "Archive",
        isArchived ? "Unarchive" : "Archive",
        selection ? selection.length === 0 : true,
        () => archiveItem ? archiveItem() : null,
    ));

    actionArray.push(createActionArrayItem(
        "Download",
        "Export",
        false,
        () => setExportMenuIsOpen(true)
    ));

    actionArray.push(createActionArrayItem(
        !isFollowingAtleastOneItem ? "FavoriteList" : "Unfavorite",
        !isFollowingAtleastOneItem ? "Start following" : "Stop following",
        selection ? selection.length === 0 : true,
        () => updateFollowing(),
        "following"
    ));

    return actionArray;
}