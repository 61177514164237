import React from "react";
import {DocumentsContainer} from 'ibis-design-system/lib/components/patterns/Documents';
import Box from 'ibis-design-system/lib/components/Atoms/Box/Box';
import Settings from "../Settings";
import {sendLog} from "../Api/requests";
import {HttpPost} from 'ibis-design-system/lib/HelperFunctions/HttpHelper';
import {IUserContextDto} from "../Api/Dtos/Resume/IUserContextDto";
import {hasPermission} from "../Utils/hasPermission";
import {EPermission} from "../Api/Dtos/Resume/EPermission";

interface IDocumentsProps {
    id: string;
    setMessage: any;
    name: string;
    userContext: IUserContextDto;
}

export const Documents: React.FunctionComponent<IDocumentsProps> = (props) => {

    const isContributor = hasPermission(props.userContext?.permissions, EPermission.Contribute);

    function notifyDocument(action: any) {
        let message;
        let notifyAction;
        if (action === 'add') {
            message = `A document has been added to ${props.name}`;
            notifyAction = 0;
        } else {
            message = `A document has been deleted from ${props.name}`;
            notifyAction = 2;
        }
        // Notify the notification service that a Entity is created
        const notification = {
            entityId: props.id,
            action: notifyAction,
            modifiedField: 'documents',
            message: message,
        };
        // Just send it and then ignore it. Nothing happens when it completes and nothing happens when it fails
        HttpPost(`${Settings.apiUrls.cci}/companies/notifications`, notification);
    }

    return (
        <Box width="100%" height="100%" py={2} px={3}>
            <DocumentsContainer
                isPublic
                // prettier-ignore
                url={`${Settings.apiUrls.resumes}/resumes/${props.id}/documents`}
                // prettier-ignore
                fileUploadUrl={`${Settings.apiUrls.fileService}/files`}
                onError={(error: any) => {
                    props.setMessage('error', error.message);
                }}
                onWarning={(error: any) => {
                    props.setMessage('warning', error.message);
                }}
                onSuccess={(response: any, action: any) => notifyDocument(action)}
                onTimeout={(error: any, url: any) => {
                    props.setMessage(
                        'error',
                        `Couldn't reach the server. The developers have been notified.`
                    );
                    sendLog(JSON.stringify(error), `Server timeout on url: "${url}"`);
                }}
                onUnknownError={(error: any, url: any) => {
                    props.setMessage(
                        'error',
                        `An unknown error occurred... Our developers have been notified`
                    );
                    sendLog(JSON.stringify(error), `Unknown error on url: "${url}"`);
                }}
                disabled={!isContributor}
            />
        </Box>
    );
};
