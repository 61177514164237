import {EOP, IPatchParams} from "../Interfaces/IPatchParams";

/**
 * Pass an old object and the updated version of this object.
 * Generates an array of objects for the Patch function used in the framework.
 * This function ONLY works for already existing objects. For adding new objects the default code is so short no util function has been written for it.
 * @param oldObject - The old object.
 * @param newObject - The new object with updated values.
 * @param path - The default path we want to attach to each patch param object. For example "/language/some-uuid-here".
 * @returns IPatchParams<any, any>[]
 *
 * @example
 * const original = { name: "gijs", age: 25 };
 * const updated = { name: "Merel", age 33, allergies: ["peanut"] };
 * const result = generatePatchArrayForExistingObject(original, updated, "/person/person-uuid-here");
 * // The console.log() of result looks like this:
 * [
 *  {
 *    op: "replace",
 *    path: "/person/person-uuid-here/name",
 *    value: "Merel"
 *  },
 *  {
 *    op: "replace",
 *    path: "/person/person-uuid-here/age",
 *    value: 33
 *  },
 *  {
 *    op: "replace",
 *    path: "/person/person-uuid-here/allergies",
 *    value: ["peanut"]
 *  }
 * ]
 */
export const generatePatchArrayForExistingObject = (oldObject: any, newObject: any, path: string): IPatchParams<any, any>[] => {
    const allKeys = Object.keys({ ...oldObject, ...newObject });
    // Use this object to keep track of changed props
    const changesObj: IPatchParams<any, any>[] = [];
    // Iterate through keys
    allKeys.forEach(key => {
        // If previous is different from current
        if (oldObject[key] !== newObject[key]) {
            changesObj.push({
                path: `${path}/${key}`,
                op: EOP.Replace,
                value: newObject[key]
            })

        }
    });

    return changesObj;
}
