import React, {useMemo, useState} from "react";
import {IBaseProps} from "../../../Interfaces/IBaseProps";
import {EOP, IPatchParams} from "../../../Interfaces/IPatchParams";
import {
    EPublicationAuthorSource,
    IPublicationAuthorCreate,
    IPublicationCreate
} from "../../../Api/Dtos/Resume/IPublicationDto";
import TextInput from "ibis-design-system/lib/components/inputFields/TextInput";
import {onStateChange} from "../../../Utils/onStateChange";
import {AddItemForm} from "../../AddItemForm";
import Flex from "ibis-design-system/lib/components/core/Flex";
import {SemiBold} from "../../Bold";
import {IYearPickerDates, YearPicker} from "../../YearPicker";
import moment from "moment";
import {yearPickerDates} from "../../../Utils/yearPickerDates";
import {MT20} from "../../MT20";
import {IUserDto} from "../../../Api/Dtos/IUserDto";
import {EUserSource, IUserOrContact, IUserOrContactPickerChange, UserOrContactPicker} from "../../UserOrContactPicker";
import { GenericPicker } from "../../GenericPicker";
import { ITag } from "office-ui-fabric-react";

const INITIAL_PUBLICATION: IPublicationCreate = {
    title: "",
    year: moment().toISOString(),
    authors:[] ,

}

interface IPublicationItemProps extends IBaseProps {
    patch: (args: IPatchParams<any, any>[]) => void;
    user: IUserDto;
    id?: string;
}

export const CreatePublication: React.FunctionComponent<IPublicationItemProps> = (props) => {

    const [publication, setPublication] = useState<IPublicationCreate>(INITIAL_PUBLICATION);
     
    const onChange = (key: keyof IPublicationCreate, value: any) => {
        const result = onStateChange<IPublicationCreate>(publication, key, value);
        if (result) setPublication(result);
    }
    
    const createPublication = async (): Promise<void> => {
        const creatorAsAuthor: IPublicationAuthorCreate = {
          authorId: props.user.user.id,
          source: EPublicationAuthorSource.Deltares,
          name: publication.authors
        };

        const tmpPublications = { ...publication, authors: [ creatorAsAuthor ] };
      
        props.patch([
          {
            op: EOP.Add,
            path: `/publications/-`,
            value: tmpPublications,
          },
        ]);
      
        setPublication(INITIAL_PUBLICATION);
      };
    

    return (
        <AddItemForm
            addButtonText={"Add publication"}
            onAddButtonClick={() => createPublication()}
            cancel={() => setPublication(INITIAL_PUBLICATION)}
            closeOnAdd
        >
            <Flex>
                <div style={{flex: 1, flexDirection: "column"}}>
                    <div style={{marginTop: 5}}>
                        <SemiBold style={{marginBottom: 6.5}}>Year</SemiBold>
                        <YearPicker
                            onChange={(dates: IYearPickerDates) => onChange("year", dates.start)}
                            dates={yearPickerDates(publication.year, undefined)}
                            style={{minWidth: 130, justifyContent: "space-between"}}
                            displaySingleCalendar
                        />
                    </div>
                </div>
                <div style={{flex: 4, flexDirection: "column"}}>
                    <TextInput
                        label="Title"
                        placeholder="Title"
                        value={publication.title}
                        onChange={(val: string) => onChange("title", val)}
                    />

                    <MT20>
                        <TextInput
                            label="Author(s)"
                            value={publication.authors}
                            onChange={(val: string) => onChange("authors", val)}/>
                    </MT20>

                    <MT20>
                    </MT20>
                    <MT20>
                        <TextInput
                            label="Originally published in"
                            placeholder="Published in"
                            value={publication.originallyPublishedIn}
                            onChange={(val: string) => onChange("originallyPublishedIn", val)}
                        />
                    </MT20>

                    <MT20>
                        <Flex>
                            <div style={{flex: 1}}>
                                <TextInput
                                    label="DOI"
                                    placeholder=""
                                    value={publication.DOI}
                                    onChange={(val: string) => onChange("DOI", val)}
                                />
                            </div>
                            <div style={{flex: 1, marginLeft: 20}}>
                                <TextInput
                                    label="Reference/URL"
                                    placeholder=""
                                    value={publication.reference}
                                    onChange={(val: string) => onChange("reference", val)}
                                />
                            </div>
                        </Flex>
                    </MT20>
                </div>
            </Flex>
        </AddItemForm>
    );
};
