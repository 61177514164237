import {getCompany} from "../Api/requests";

export interface ICompanyIdWithSourceId {
    companyId?: string | null;
    sourceId: string;
}

export interface ICompanyNameWithSourceId {
    companyName?: string;
    sourceId: string;
}

/**
 * Pass an array of objects with a company id, and the id of an entity this company id is attached to.
 * Returns an array of objects with a company name, and the id that company is attached to.
 * @param companyIdsWithSourceId
 */
export const getCompanyNameWithSourceId = async (companyIdsWithSourceId: ICompanyIdWithSourceId[]): Promise<ICompanyNameWithSourceId[]> => {
    return await Promise.all<ICompanyNameWithSourceId>(companyIdsWithSourceId.map(async (companyIdWithSource) => {
        if (!companyIdWithSource.companyId) return {companyName: "", sourceId: companyIdWithSource.sourceId};
        const company = await getCompany(companyIdWithSource.companyId);
        return {companyName: company?.name, sourceId: companyIdWithSource.sourceId}
    }));
}