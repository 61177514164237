import React, {useMemo} from "react";
import ItemTemplate from 'ibis-design-system/lib/components/core/ListItemTemplate';
import Box from 'ibis-design-system/lib/components/core/Box';
import Flex from 'ibis-design-system/lib/components/core/Flex';

import {IResumeDto} from "../Api/Dtos/Resume/IResumeDto";
import {IADUser} from "../Interfaces/IADUser";
import {IPersonaProps, Persona} from "office-ui-fabric-react";
import {useLanguages} from "../Hooks/GlobalConfigContext";
import {getImageInitials} from "../Utils/getImageInitials";
import { archiveResume } from "../Api/requests";


interface ITenderMenuCardProps {
    item: IResumeDto;
    isSelected: any;
    onToggleItem: any;
    onRowClick: any;
    user: IADUser;
}

export const TenderMenuCard: React.FunctionComponent<ITenderMenuCardProps> = (props) => {

    const languages = useLanguages();

    const personaData: IPersonaProps = useMemo( () => {
        const selectedLang = languages.find( lg => lg.id === props.item.resumeLanguage);

        return {
            imageUrl: props.item.user?.photo as string | undefined,
            showInitialsUntilImageLoads: true,
            imageInitials: getImageInitials(props.item.firstname, props.item.lastname),
            onRenderPrimaryText: () => <div ><span style={{fontWeight: "bold"}}>{props.item.user?.name}</span> <span>({props.item.user?.department} - {props.item.user?.division})</span> </div>,
            onRenderSecondaryText: () => (
                <div style={{fontSize: 14}}>
                    <div>{props.item.functionTitle}</div>
                    <div>{selectedLang ? selectedLang.label : ""}</div>
                </div>
            )
        };
    }, [props.item, languages]);

    return (
        <ItemTemplate isSelected={props.isSelected} onToggle={props.onToggleItem} onClick={props.onRowClick} hasNotifications={props.item?.hasNotifications}>
            <ItemTemplate.SubText>
                <Box>
                    <Flex>
                        <Box flex={1} style={{ overflow: 'hidden' }}>
                            <Persona styles={{root: {height: "100%"}}} {...personaData} />
                        </Box>
                    </Flex>
                </Box>
            </ItemTemplate.SubText>
        </ItemTemplate>
    );
};
