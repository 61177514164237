import React, { useEffect, useState } from 'react';

import SmartSearch from 'ibis-design-system/lib/components/inputFields/SmartSearch';
import {
    addTextFilter,
    addMOUFilter,
    addCountryFilter,
    addMaconomyFilter,
    addDigiOfficeFilter,
    addCityFilter,
    addDeltaresContactFilter,
    addLanguageFilter,
    addCompanyFilter,
    addGenericFilter,
    addUnitFilters,
    addDepartmentFilters,
    addFunctionTitleFilter,
} from 'ibis-design-system/lib/components/inputFields/SmartSearch/filters';
import { isEmptyOrSpaces } from 'ibis-design-system/lib/HelperFunctions/StringHelper';
import { useNavigationState } from 'ibis-design-system/lib/Providers/NavigationProvider';
import { useCacheState } from 'ibis-design-system/lib/Providers/HttpCacheProvider';
import { HttpGet, CancelToken } from 'ibis-design-system/lib/HelperFunctions/HttpHelper';

import {useCountries, useLanguages, useUnits, useDepartments, useAreasOfExpertise,useFunctionTitle } from '../Hooks/GlobalConfigContext';
import Settings from '../Settings';

function Search(props) {
    const {
        collectionId,
        collectionTab,
        navigateToCollection,
        setEntities,
        setSelection
    } = useNavigationState();

    const cache = useCacheState();
    const countries = useCountries();
    const languages = useLanguages();
    const units = useUnits();
    const departments = useDepartments();
    const areaOfExpertise = useAreasOfExpertise(); 
    const functionTitle = useFunctionTitle(); 

    const [searchInput, setSearchInput] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [searchFilters, setSearchFilters] = useState([]);

    useEffect(() => {
        props.setFilters(searchFilters.map(x => x.filter));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchFilters]);

    useEffect(() => {
        const source = CancelToken.source();

        determineFilters(searchInput);

        return () => {
            source.cancel();
        };

        function determineFilters(input) {
            if (isEmptyOrSpaces(input)) {
                setSuggestions([]);
                return;
            }
            input = input?.toLowerCase();
            const filteredSuggestions = [addTextFilter(input)];

            // Local determined filters
            addIdFilters(filteredSuggestions, input);
            addCountryFilters(filteredSuggestions, input);
            addResumeLanguageFilters(filteredSuggestions, input);
            addResumeFunctionTitle(filteredSuggestions, input);   
            addResumeUnitFilter(filteredSuggestions, input);
            addResumeDepartmentFilter(filteredSuggestions, input);
            addResumeAreaOfExpertiseFilter(filteredSuggestions, input);
            

            setSuggestions(filteredSuggestions);

            // Server side determined filters (async)
            addCityFilters(input, source);
            addDeltaresContactFilters(input, source);
            addClientFilters(input, source);
        }

        function addDeltaresContactFilters(input, source) {
            if (input.length < 3) return;
            const url = `${Settings.apiUrls.singleSignOn}/users/details/search?skip=0&take=5&term=${input}`;
            const users = cache[url];
            if (users) {
                setUserSuggestions(users);
            } else {
                HttpGet(url, source.token).then(({ result }) => {
                    cache[url] = result;
                    setUserSuggestions(result);
                });
            }
        }

        function setUserSuggestions(users) {
            const newSuggestions = [];
            for (let i = 0; i < users.length; i++) {
                newSuggestions.push(addDeltaresContactFilter(users[i]));
            }
            setSuggestions(currentSuggestions => currentSuggestions.concat(newSuggestions));
        }

        function addClientFilters(input, source) {
            if (input.length < 3) return;
            const url = `${Settings.apiUrls.cci}/companies/details/search?skip=0&take=5&term=${input}`;
            const companies = cache[url];
            if (companies) {
                setClientSuggestions(companies);
            } else {
                HttpGet(url, source.token).then(({ result }) => {
                    cache[url] = result;
                    setClientSuggestions(result);
                });
            }
        }

        function setClientSuggestions(companies) {
            const newSuggestions = [];
            for (let i = 0; i < companies.length; i++) {
                newSuggestions.push(addCompanyFilter(companies[i].name, companies[i].id, 'Client', 'client'));
            }
            setSuggestions(currentSuggestions => currentSuggestions.concat(newSuggestions));
        }

        function addResumeLanguageFilters(localFilters, input) {
            if (input.length < 3) return;
            const matchedLanguages = languages.filter(x => x.label?.toLowerCase().includes(input));

            for (let i = 0; i < matchedLanguages.length; i++) {
                localFilters.push(
                    addLanguageFilter(matchedLanguages[i])
                );
            }
        }

        function addCityFilters(input, source) {
            if (input.length < 3) return;
            const url = `${Settings.apiUrls.cci}/companies/search/city-suggestions?term=${input}`;
            const cities = cache[url];
            if (cities) {
                setCitySuggestions(cities);
            } else {
                HttpGet(url, source.token).then(response => {
                    cache[url] = response;
                    setCitySuggestions(response);
                });
            }
        }

        function setCitySuggestions(cities) {
            const newSuggestions = [];
            for (let i = 0; i < cities.length; i++) {
                newSuggestions.push(addCityFilter(cities[i]));
            }
            setSuggestions(currentSuggestions => currentSuggestions.concat(newSuggestions));
        }
        
        function addResumeUnitFilter(localFilters, input) {
            if (input.length < 3) return;
            const matchedUnits = units.filter(x => x.abbreviation?.toLowerCase().includes(input));

            for (let i = 0; i < matchedUnits.length; i++) {
                localFilters.push(
                    addUnitFilters(matchedUnits[i].abbreviation, input)
                );
            }
        }

        function addResumeDepartmentFilter(localFilters, input) {
            if (input.length < 2) return;
            const matched = new Set(departments.filter(x => x.abbreviation?.toLowerCase().includes(input)).slice(0, 10));

            for (const department of matched) {
                localFilters.push(addDepartmentFilters('term', department.abbreviation, department.abbreviation));
            }
        }
        
        function addResumeFunctionTitle(localFilters, input) {
            if (input.length < 3) return;
            const matchedFunction = functionTitle.filter(x => x.functionTitle?.toLowerCase().includes(input));
            for (let i = 0; i <= matchedFunction.length; i++) {
                localFilters.push(
                    addFunctionTitleFilter(input, matchedFunction[i])
                );
            }
        }
        function addResumeAreaOfExpertiseFilter(localFilters, input) {
            if (input.length < 3) return;
            const matched = areaOfExpertise.filter(x => x.label?.toLowerCase().includes(input));

            if (!matched.length) return;
            for (let i = 0; i <= matched.length; i++) {
                if (!matched[i] || !matched[i].label) {
                    console.error("No label found for ", matched[i], i);
                    continue;
                }
                localFilters.push(
                   // addGenericFilter(matched[i].label, )
                   addGenericFilter(matched[i].label, matched[i].id, 'area-of-expertise', 'Area of expertise', 'id', 'Search')
                );
            }
        }

        function addIdFilters(localFilters, input) {
            if (isNaN(input)) return;
            localFilters.push(addDigiOfficeFilter(input));
            if (input.length !== 6) return;
            localFilters.push(addMaconomyFilter(input));
        }

        function addCountryFilters(localFilters, input) {
            if (input.length < 3) return;
            const matchedCountries = countries.filter(x => x.name?.toLowerCase().includes(input));

            for (let i = 0; i < matchedCountries.length; i++) {
                localFilters.push(
                    addCountryFilter(matchedCountries[i].id, matchedCountries[i].name)
                );
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchFilters, searchInput, countries]);

    return (
        <SmartSearch
            input={searchInput}
            setInput={setSearchInput}
            onChange={value => setSearchInput(value)}
            suggestions={suggestions}
            onSearch={filters => {
                setSearchFilters(filters);
                if (collectionId) {
                    setSelection([]);
                    setEntities(null);
                    navigateToCollection(collectionId, collectionTab);
                }
            }}
            activeSearchFilters={searchFilters}
        />
    );
}


export default Search;