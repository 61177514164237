import React, {useState} from "react";
import {IBaseProps} from "../../../Interfaces/IBaseProps";
import {IYearPickerDates} from "../../YearPicker";
import {useNatures} from "../../../Hooks/GlobalConfigContext";
import {useCompany} from "../../../Hooks/useCompany";
import {ITeachingDto} from "../../../Api/Dtos/Resume/ITeachingDto";
import {ResumeInfoCard} from "../../ResumeInfoCard";
import {Bold} from "../../Bold";
import TextInput from "ibis-design-system/lib/components/inputFields/TextInput";
import {MT12} from "../../MT12";
import CompanyPicker from "ibis-design-system/lib/components/pickers/CompanyPicker";
import {getCompanies, getCompany} from "../../../Api/requests";
import {companyPickerResult} from "../../../Utils/companyPickerResult";
import {onStateChange} from "../../../Utils/onStateChange";
import {IUserContextDto} from "../../../Api/Dtos/Resume/IUserContextDto";
import { EOP, IPatchParams } from "../../../Interfaces/IPatchParams";

interface ITeachingProps extends IBaseProps {
    teachingAssignment: ITeachingDto;
    patch: (args: any) => void;
    permissions: IUserContextDto;
}

export const TeachingAssignment: React.FunctionComponent<ITeachingProps> = (props) => {

    const natures = useNatures();
    const teachingInstitute = useCompany(props.teachingAssignment?.teachingInstitute);
    const institute = useCompany(props.teachingAssignment.teachingInstitute);
    const [editing, setEditing] = useState<boolean>(false);
    const [teachingAssignment, setTeachingAssignment] = useState<ITeachingDto | null | undefined>(props.teachingAssignment);

    const onChange = (key: keyof ITeachingDto, value: string) => {
        const result = onStateChange<ITeachingDto>(teachingAssignment, key, value);
        if (result) setTeachingAssignment(result);
    }

    const changeDates = (dates: IYearPickerDates) => {
        if (!props.teachingAssignment || !teachingAssignment) return;

        const tmpTeachingAssignment: ITeachingDto = {...teachingAssignment};
        tmpTeachingAssignment.from = dates.start ? dates.start : null;
        tmpTeachingAssignment.to = dates.end ? dates.end : null;
        setTeachingAssignment(tmpTeachingAssignment);
    }
    
    const updateRecord = (): void => {
        
        const updateArr: IPatchParams<any, any>[] = [
            {   
                op: EOP.Replace,
                value: teachingAssignment?.teachingAssignment,
                path: `/teachings/${props.teachingAssignment.id}/teachingAssignment`
            },
            {
                op: EOP.Replace,
                value: teachingAssignment?.teachingInstitute,
                path: `/teachings/${props.teachingAssignment.id}/teachingInstitute`
            },
        ]
        props.patch(updateArr);
        setEditing(false);
    }
    const updateTeachingInstitute = (change: any): void => {
        if (!teachingAssignment || !change) { return; }
      
        const pickerResult = companyPickerResult(change);
        const tmpWorkTeachingAssignment = {...teachingAssignment};
        tmpWorkTeachingAssignment.teachingInstitute = pickerResult;
        institute.setCompanyId(pickerResult)
        console.log('Company updated');
        setTeachingAssignment(tmpWorkTeachingAssignment);
      };

    return (
        <ResumeInfoCard
            editing={editing}
            setEditing={setEditing}
            period={{start: teachingAssignment && teachingAssignment.from ? teachingAssignment.from : undefined, end: teachingAssignment && teachingAssignment.to ? teachingAssignment.to : undefined}}
            updatePeriod={(dates: IYearPickerDates) => changeDates(dates)}
            onUpdate={updateRecord}
            onCancel={() => setTeachingAssignment(props.teachingAssignment)}
            permissions={props.permissions}
            style={props.style}
            onDelete={() => props.patch([
                {
                    op: EOP.Remove,
                    path: `/teachings/${props.teachingAssignment.id}`
                }
            ])}
        >
            <Bold>Teaching assignment</Bold>
            { editing ?
                <TextInput
                    placeholder="Assignment"
                    value={props.teachingAssignment?.teachingAssignment}
                    onChange={(val: string) => onChange("teachingAssignment", val)}
                />
                :
                <MT12>{teachingAssignment?.teachingAssignment}</MT12>
            }

            <Bold style={{marginTop: 37}}>Education institute</Bold>
            { editing ?
                <CompanyPicker
                    placeholder="Select client"
                    pickLimit={1}
                    exclude={teachingAssignment?.teachingInstitute ? [teachingAssignment?.teachingInstitute] : []}
                    selected={teachingAssignment?.teachingInstitute ? [teachingAssignment?.teachingInstitute] : []}
                    natures={natures}
                    getCompany={getCompany}
                    getCompanies={(input: string) => getCompanies(input, null, null)}
                    onChanged={(change: any) => updateTeachingInstitute(change)}
                />
                :
                <MT12 style={{paddingBottom: 27}}>{teachingInstitute.company?.name}</MT12>
            }
        </ResumeInfoCard>
    );
};
