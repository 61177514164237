import React, {useEffect, useState} from "react";
import {IEducationDto} from "../../Api/Dtos/Resume/IEducationDto";
import {ITrainingDto} from "../../Api/Dtos/Resume/ITrainingDto";
import {IBaseProps} from "../../Interfaces/IBaseProps";
import {OverviewItemRow} from "../OverviewItemRow";
import moment from "moment";
import {dateYearOrPresent} from "../../Utils/dateYearOrPresent";
import {
    getCompanyNameWithSourceId,
    ICompanyIdWithSourceId,
    ICompanyNameWithSourceId
} from "../../Utils/getCompanyNameWithSourceId";

interface IEducationAndTrainingItemProps extends IBaseProps {
    educations: IEducationDto[] | null;
    trainings: ITrainingDto[] | null;
}

export const EducationAndTrainingItem: React.FunctionComponent<IEducationAndTrainingItemProps> = (props) => {

    const [companiesForEducation, setCompaniesForEducation] = useState<ICompanyNameWithSourceId[]>();

    useEffect(() => {
        if (!props.educations) return;
        getAllCompaniesForEducations(props.educations);
    }, [props.educations]);

    const getAllCompaniesForEducations = async (educations: IEducationDto[]): Promise<string[] | undefined> => {
        let companyIds: ICompanyIdWithSourceId[] = educations.map( ed => ({companyId: ed.teachingInstitute, sourceId: ed.id}) );
        if (!companyIds) return;
        const teachingCompanies = await getCompanyNameWithSourceId(companyIds);
        setCompaniesForEducation(teachingCompanies)
    }

    return (
        <div style={props.style} className={props.className}>

            {props.educations && props.educations.map((education, index) =>
                <OverviewItemRow
                    key={education.id}
                    rootStyles={{marginTop: index > 0 ? 1 : 0}}
                    linkText={`${education.teachingInstitute && companiesForEducation ? companiesForEducation.find( c => c.sourceId === education.id)?.companyName :  ""}`}
                    cursiveText={`${education.title ? education.title : ""} ${education.educationName ? education.educationName : ""}`}
                    subText={`${education.teachingInstitute && companiesForEducation ? companiesForEducation.find( c => c.sourceId === education.id)?.companyName :  ""} (${education.from ? moment(education.from).year() : ""} - ${dateYearOrPresent(education.to)})`}
                />
            )}

            {props.trainings && props.trainings.map((training, index) => {
                    if (!training.subjectOfTraining) return <div key={training.id}/>;
                    return (
                        <OverviewItemRow
                            key={training.id}
                            rootStyles={{
                                minHeight: 56,
                                marginTop: index > 0 || (props.educations && props.educations.length) ? 1 : 0,
                                cursor: "default"
                            }}
                            subText={training.subjectOfTraining}
                            subTextStyles={{color: "black"}}
                        />
                    );
                }
            )}

        </div>
    );
};
