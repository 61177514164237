import React from "react";
import Flex from 'ibis-design-system/lib/components/Atoms/Flex';
import styled from "ibis-design-system/lib/HelperFunctions/ThirdParty/styled-components";
import {BlueText} from "../../BlueText";

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: white;
  padding: 14px 24px 14px 10px;
`;

interface IEuropassHeaderProps {}

export const EuropassHeader: React.FunctionComponent<IEuropassHeaderProps> = (props) => {
    return (
        <Row>
            <img width={108} height={59} src={"/logo-europass.svg"} alt="Europass logo" />
            <Flex>
                <a href="https://europa.eu/europass/en/about-europass" style={{textDecoration: "none"}}>
                    <BlueText style={{marginTop: -2}}>About europass</BlueText>
                </a>
                <a href="https://europa.eu/europass/eportfolio/screen/profile-wizard?lang=en" style={{textDecoration: "none"}}>
                    <BlueText style={{marginTop: -2}}>Self-assessment grid</BlueText>
                </a>
            </Flex>
        </Row>
    );
};
