import React, {useEffect, useMemo, useState} from "react";
import {IYearPickerDates} from "../../YearPicker";
import {IBaseProps} from "../../../Interfaces/IBaseProps";
import {useCountries, useNatures} from "../../../Hooks/GlobalConfigContext";
import {useCompany} from "../../../Hooks/useCompany";
import {ResumeInfoCard} from "../../ResumeInfoCard";
import {SemiBold} from "../../Bold";
import TextInput from "ibis-design-system/lib/components/inputFields/TextInput";
import {MT12} from "../../MT12";
import {onStateChange} from "../../../Utils/onStateChange";
import WYSIWYGEditor from "ibis-design-system/lib/components/inputFields/WYSIWYGEditor";
import {WYSIWYG_dataConversion} from "../../../Utils/WYSIWYG_dataConversion";
import {MarginTopBlock} from "../../MarginTopBlock";
import CompanyPicker from "ibis-design-system/lib/components/pickers/CompanyPicker";
import {getCompanies, getCompany, getProjectById} from "../../../Api/requests";
import {ICountryDto} from "../../../Api/Dtos/ICountryDto";
import {yearPickerDates} from "../../../Utils/yearPickerDates";
import {EOP, IPatchParams} from "../../../Interfaces/IPatchParams";
import {IUserContextDto} from "../../../Api/Dtos/Resume/IUserContextDto";
import { IWorkUndertakenClientsCreate, IWorkUndertakenDto } from "../../../Api/Dtos/Resume/IWorkUndertakenDto";
import { request } from "../../../Utils/request";
import { GenericPicker } from "../../GenericPicker";
import { ITag } from "office-ui-fabric-react";
import { ICompanyPicker } from "../../../Interfaces/ICompanyPicker";
import Flex from "ibis-design-system/lib/components/core/Flex";


interface IWorkUndertakenItemProps extends IBaseProps {
    workUndertaken: IWorkUndertakenDto;
    patch: (args: IPatchParams<any, any>[]) => void;
    permissions: IUserContextDto;

}

export const WorkUndertakenitem: React.FunctionComponent<IWorkUndertakenItemProps> = (props) => {
    const natures = useNatures();
    const countries = useCountries();
    const companyNames = useCompany(null);
    const [editing, setEditing] = useState<boolean>(false);
    const [workUndertaken, setWorkUndertaken] = useState<IWorkUndertakenDto>(props.workUndertaken);

    useEffect(() => fetchCompaniesAndSetWorkUndertaken(), [props.workUndertaken]);

    const fetchCompaniesAndSetWorkUndertaken = () => {
        setWorkUndertaken(props.workUndertaken);
        companyNames.fetchCompanies(props.workUndertaken.workUndertakenClients?.map(c => c.clientId));
    }
       
    const countryNamess = () => {
        return workUndertaken?.workUndertakenCountries?.map( wc => {
            let names: string | undefined = "";
            if (typeof wc === "string") {
                names = countries.find( (country: ICountryDto) => country.id === wc)?.name;
            } else {
                names = countries.find( (country: ICountryDto) => {
                    const id = wc.countryId ? wc.countryId : wc.id;
                    if (country.id === id) return true;
                })?.name;
            }
            return names;
        });
    };

    const countryNames = countryNamess();

    const changeDates = (dates: IYearPickerDates) => {
        if (!props.workUndertaken || !workUndertaken) return;

        const tmpWorkUndertaken: IWorkUndertakenDto = {...workUndertaken};
        tmpWorkUndertaken.from = dates.start ? dates.start : null;
        tmpWorkUndertaken.to = dates.end ? dates.end : null;
        setWorkUndertaken(tmpWorkUndertaken);
    }

    const onChange = (key: keyof IWorkUndertakenDto, value: any) => {
        const result = onStateChange<IWorkUndertakenDto>(workUndertaken, key, value);
        if (result) setWorkUndertaken(result);
    }

    
    const updatedSelectedCompanies = (selected: ICompanyPicker) => {
        setWorkUndertaken( (old: IWorkUndertakenDto) => {
            const tmpOld = {...old};

            if (selected.op === "add") {
                const obj: IWorkUndertakenClientsCreate = { clientId: selected.value.key, workUndertakenId: workUndertaken.id }
                tmpOld.workUndertakenClients?.push(obj);
            }

            else {
                const newWorkUndertaken = tmpOld.workUndertakenClients?.filter( old => old.clientId !== selected.value.key);
                tmpOld.workUndertakenClients = newWorkUndertaken ? newWorkUndertaken : null;
            }
    
            return tmpOld;
        });
    }

    const updateRecord = (): void => {
        const updateArr: IPatchParams<any, any>[] = [
            {   
                op: EOP.Replace,
                value: workUndertaken?.position,
                path: `/workUndertakens/${props.workUndertaken.id}/position`
            },
            {
                op: EOP.Replace,
                value: workUndertaken?.activitiesPerformed,
                path: `/workUndertakens/${props.workUndertaken.id}/activitiesPerformed`
            },
            {
                op: EOP.Replace,
                value: workUndertaken?.mainProjectFeatures,
                path: `/workUndertakens/${props.workUndertaken.id}/mainProjectFeatures`
            },
            
            {
                op: EOP.Replace,
                value: workUndertaken?.workUndertakenCountries?.map(ct => ({
                    countryId: ct.countryId,
                })),
                path: `/workUndertakens/${props.workUndertaken.id}/workUndertakenCountries`                
            },
            {
                op: EOP.Replace,
                value: workUndertaken?.workUndertakenClients?.map(ct => ({ 
                    clientId: ct.clientId,
                    workUndertakenId: props.workUndertaken.id
                })),
                path: `/workUndertakens/${props.workUndertaken.id}/workUndertakenClients`
            },
            {
                op: EOP.Replace,
                value: workUndertaken?.assignment,
                path: `/workUndertakens/${props.workUndertaken.id}/assignment`
            },
            {
                op: EOP.Replace,
                value: workUndertaken?.projectReferenceId,
                path: `/workUndertakens/${props.workUndertaken.id}/ProjectReferenceId`
            },
            {
                op: EOP.Replace,
                value: workUndertaken?.from,
                path: `/workUndertakens/${props.workUndertaken.id}/from`
            },
            {
                op: EOP.Replace,
                value: workUndertaken?.to,
                path: `/workUndertakens/${props.workUndertaken.id}/to`
            },
        ]
        props.patch(updateArr);
        setEditing(false);
    }
    
    const companyIds = useMemo(() => workUndertaken.workUndertakenClients?.map( c => c.clientId), [editing, workUndertaken.workUndertakenClients?.length]);
      
    return (
        <ResumeInfoCard
            editing={editing}
            setEditing={setEditing}
            period={yearPickerDates(workUndertaken?.from, workUndertaken?.to)}
            updatePeriod={(dates: IYearPickerDates) => changeDates(dates)}
            onUpdate={updateRecord}
            onCancel={() => setWorkUndertaken(props.workUndertaken)}
            style={props.style}
            permissions={props.permissions}
            onDelete={() => props.patch([
                {
                    op: EOP.Remove,
                    path: `workUndertakens/${props.workUndertaken.id}`
                }
            ])}
        >
            <MarginTopBlock>
                <Flex style={{gap: '10px'}}>
                    <SemiBold>{editing ? 'Name assignment/project' : 'Name assignment/project:'}</SemiBold>
                    {! editing && <div style={{flex: 1}}>{props.workUndertaken?.assignment}</div>}
                </Flex>
                { editing &&
                    <TextInput
                        placeholder='Name assignment/project'
                        value={props.workUndertaken.assignment}
                        onChange={(val: string) => onChange("assignment", val)}
                    />
                }
            </MarginTopBlock>

            <Flex style={{gap: '30px'}}>
                <Flex style={{width: '25%'}}>
                    <MarginTopBlock>
                    <SemiBold>{editing ? 'Position' : 'Position:'}</SemiBold>
                    { editing ?
                        <TextInput
                            placeholder="Position"
                            value={props.workUndertaken?.position}
                            onChange={(val: string) => onChange("position", val)}
                        />
                        :
                        <MT12>{props.workUndertaken?.position}</MT12>
                    }
                    </MarginTopBlock>
                </Flex>

                <Flex style={{width: '25%'}}>
                    <MarginTopBlock>
                        <SemiBold>{editing ? 'Countries of work experience' : 'Countries of work experience:'}</SemiBold>
                        { editing ?
                        <GenericPicker
                                list={countries}
                                listToTags={(countries: ICountryDto[]) => countries.map<ITag>( country => ({key: country.id, name: country.name }))}
                                selectedTags={workUndertaken.workUndertakenCountries === null ? [] : workUndertaken.workUndertakenCountries.map( c => c.countryId)}
                                singleTypeToTag={(country: ICountryDto) => ({key: country.id, name: country.name })}
                                onChange={(countries: string[] | undefined) => {
                                    const countryIds = countries?.map((countryId: string) => ({ countryId: countryId }));
                                    onChange("workUndertakenCountries", countryIds)}
                                }
                                pickLimit={10}
                            />
                            :
                            <MT12>{countryNames && countryNames.join(", ")}</MT12>
                        }
                    </MarginTopBlock>
                </Flex>

                <Flex style={{width: '50%'}}>       
                    <MarginTopBlock>
                        { !editing ? <SemiBold>{editing ? 'Clients' : 'Clients:'}</SemiBold> : ''}
                            { editing &&
                                <CompanyPicker
                                    label="Clients"
                                    pickLimit={10}
                                    exclude={companyIds}
                                    selected={companyIds}
                                    natures={natures}
                                    multiSelect={true}
                                    getCompany={getCompany}
                                    getCompanies={(input: string) => getCompanies(input, null, null)}
                                    onChanged={updatedSelectedCompanies}
                                />
                            }
                        {!editing && <MT12>{companyNames.companyNames.join(" , ")}</MT12> }
                    </MarginTopBlock>
                </Flex>
            </Flex>

            <MarginTopBlock >
                <Flex style={{gap: '10px'}}>
                    { workUndertaken?.mainProjectFeatures && !null || editing ?
                        <WYSIWYGEditor
                            content={WYSIWYG_dataConversion(props.workUndertaken.mainProjectFeatures)}
                            onUpdate={(val: string) => onChange("mainProjectFeatures", val)}
                            label={editing ? "Main project Features" : "Main project Features:"}
                            readOnly={!editing}
                        />
                    : ''}
                </Flex>
            </MarginTopBlock>

            <MarginTopBlock >
                <Flex style={{gap: '10px'}}>
                    { workUndertaken?.activitiesPerformed && !null || editing ?
                        <WYSIWYGEditor                   
                            content={WYSIWYG_dataConversion(props.workUndertaken.activitiesPerformed)}
                            onUpdate={(val: string) => onChange("activitiesPerformed", val)}
                            label={editing ? "Activities performed" : "Activities performed:"}
                            readOnly={!editing}
                        />
                    : ''}
                </Flex>
            </MarginTopBlock>

            {/* for some padding at the bottom */}
            <MarginTopBlock />

        </ResumeInfoCard>
    );
};