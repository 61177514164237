import React from "react";
import styled from "ibis-design-system/lib/HelperFunctions/ThirdParty/styled-components";
import {IBaseProps} from "../Interfaces/IBaseProps";

const Header = styled.div`
    background-color: #0000000D;
    font: normal normal 600 14px/19px Segoe UI;
    color: #333333;
    padding: 11px;
    word-break: break-word;
`

const Content = styled.div`
    background-color: ${(props: any) => props.white ? "white" : undefined};
    color: #333333;
`

interface IInformationBlockProps extends IBaseProps {
    header: string;
    // If set to true, the background color of the content is white.
    contentWhite?: boolean;
}

export const InformationBlock: React.FunctionComponent<IInformationBlockProps> = (props) => {
    return (
        <div className={props.className} style={props.style}>
            <Header>
                <div>{props.header}</div>
            </Header>

            <Content white={props.contentWhite}>
                {props.children}
            </Content>
        </div>
    );
};
