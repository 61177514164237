import React from "react";
import {IContainerProps} from "../../Interfaces/IContainterProps";
import Flex from 'ibis-design-system/lib/components/Atoms/Flex';
import {ReadOnlyInput} from "../ReadOnlyInput";
import {MarginTopBlock} from "../MarginTopBlock";
import styled from "ibis-design-system/lib/HelperFunctions/ThirdParty/styled-components";
import moment from "moment";

const MDiv = styled.div`
    width: 137px;
`;

interface IPersonaDetailsProps extends IContainerProps {}

export const PersonaDetails: React.FunctionComponent<IPersonaDetailsProps> = (props) => {
    return (
        <div>
            <div>Information retrieved from Deltares HR employee file.</div>

            <Flex mt={1}>
                <div style={{flex: 2 }}>
                    <ReadOnlyInput label="First name" text={props.item.nickname} />
                </div>
                {props.item.lnPrefix ? <div style={{flex: 1, marginLeft: 20}}>
                    <ReadOnlyInput label="LN Prefix" text={props.item.lnPrefix} />
                </div> : null}
                <div style={{flex: 2, marginLeft: 20}}>
                    <ReadOnlyInput label="Last name" text={props.item.lastname} />
                </div>
            </Flex>

            <MarginTopBlock>
                <Flex>
                    <div style={{flex: 1 }}>
                        <ReadOnlyInput label="Gender" text={props.item.gender} />
                    </div>
                    <div style={{flex: 1, marginLeft: 20}}>
                        <ReadOnlyInput label="Nationality" text={props.item.nationality} />
                    </div>
                    <div style={{flex: 1, marginLeft: 20}}>
                        <ReadOnlyInput label="Date of birth" text={props.item.dateOfBirth && moment(props.item.dateOfBirth).year() > 1900 ? moment(props.item.dateOfBirth).format("MMMM Do YYYY") : undefined} />
                    </div>
                    <div style={{flex: 2, marginLeft: 20}}>
                        <ReadOnlyInput label="Place of birth" text={props.item.placeOfBirth} />
                    </div>
                </Flex>
            </MarginTopBlock>

            <MarginTopBlock>
                <Flex>
                    {props.item.scienceTitlePrefix ? 
                    <MDiv>
                        <ReadOnlyInput label="Scientific title prefix" text={props.item.scienceTitlePrefix} />
                    </MDiv>
                    : null}
                    {props.item.scienceTitleSuffix ?
                    <MDiv style={{marginLeft: 20}}>
                        <ReadOnlyInput label="Scientific title suffix" text={props.item.scienceTitleSuffix} />
                    </MDiv>
                    : null}
                    {props.item.dutchScientificTitle ?
                    <MDiv style={{marginLeft: 20}}>
                        <ReadOnlyInput label="Dutch scientific title" text={props.item.dutchScientificTitle} />
                    </MDiv>
                    : null}
                </Flex>
            </MarginTopBlock>
        </div>
    );
};
