import styled from "ibis-design-system/lib/HelperFunctions/ThirdParty/styled-components";
import {IFoundation} from "../Interfaces/IFoundation";

export interface IRootProps extends IFoundation {
    editing: boolean;
}

export const EditableCardRoot = styled.div`
    background-color: ${(props: IRootProps) => props.editing ? props.theme.colors.neutralLight : "white"};
    padding: 10px;
    position: relative;
`;