import React, {useMemo} from "react";
import WatchAbleScrollContainer from "ibis-design-system/lib/components/watchAbleComponents/WatchAbleScrollContainer";
import Editview from "ibis-design-system/lib/components/core/Editview";
import {General} from "../Components/Resume/General";
import {PersonaDetails} from "../Components/Resume/PersonalDetails";
import {Language} from "../Components/Resume/Language";
import {EducationAndTraining} from "../Components/Resume/EducationAndTraining";
import {EmploymentRecord} from "../Components/Resume/EmploymentRecord";
import {Teaching} from "../Components/Resume/Teaching";
import {IContainerProps} from "../Interfaces/IContainterProps";
import Flex from 'ibis-design-system/lib/components/Atoms/Flex';
import { WorkUndertaken } from "../Components/Resume/WorkUndertaken";
import { Associations } from "../Components/Resume/Associations";

export const Resume: React.FunctionComponent<IContainerProps> = (props) => {

    const subjects = useMemo(() => [
            {
                id: 'general',
                name: 'General',
                component: <General {...props} />,
                showHeader: false,
            },
            {
                id: 'personalDetails',
                name: 'Personal details',
                component: <PersonaDetails {...props} />,
                showHeader: true,
            },
            {
                id: 'languages',
                name: 'Languages',
                component: <Language {...props} />,
                showHeader: true,
            },
            {
                id: 'educationAndTraining',
                name: 'Education and training',
                component: <EducationAndTraining {...props} />,
                showHeader: true,
            },
            {
                id: 'teaching',
                name: 'Teaching',
                component: <Teaching {...props} />,
                showHeader: true,
            },
            {
                id: 'associations',
                name: 'Membership of association',
                component: <Associations {...props} />,
                showHeader: true,
            },
        ], [props]);

    return (
        <div style={{width: "100%"}}>
        <WatchAbleScrollContainer>
            {/* this calc is the width of the navigation + padding (60px) */}
            <Flex width="calc(100% - 260px)" pl={3}>
                <Editview subjects={subjects}/>
            </Flex>
        </WatchAbleScrollContainer>
        </div>
    );
};
