export interface IPublicationDto {
    id:                    string;
    creationDate?:          string;
    createdBy?:             string;
    lastModificationDate?:  string;
    lastModifiedBy?:        string;
    lastActionType?:        number;
    year?:                  string;
    title?:                 string;
    originallyPublishedIn?: string;
    doi?:                   string;
    reference?:             string;
    authors?:               IPublicationAuthorDto[];
    deltaresMissionAreas?:  string[];
    resumeId?:              string;
    resume?:                string;
}

export interface IPublicationAuthorDto {
    id:                    string;
    authorId:              string;
    publicationId:         string;
    source:                EPublicationAuthorSource;
    isMainAuthor?:          boolean;
    name?:                  string;

}

export interface IPublicationCreate {
    /**
     * The title of the publication.
     */
    title: string;
    /**
     * A UTC timestamp, from which only the year is displayed in the UI.
     */
    year: string;
    /**
     * The journal in which the article was piblished.
     */
    originallyPublishedIn?: string;
    /**
     * DOI: Document Object Identifier.
     * Contains information about the published location.
     */
    DOI?: string;
    /**
     * A URL reference to the published article.
     */
    reference?: string;
    /**
     * When creating a publication, the user creating it is always added as an author.
     * This means there is always at least one author.
     */
    authors: string[];
    /**
     * Deltares mission areas can be found in the Global Config application.
     */
    deltaresMissionAreas?: string[];



}

export interface IPublicationAuthorCreate {
    authorId: string;
    // The source indicates if the author is a Deltares user, or a Person (contact person for a company).
    source: EPublicationAuthorSource;
    isMainAuthor?:          boolean;
    name?: string[];
}

export enum EPublicationAuthorSource {
    Deltares,
    CompanyContactPerson,
    Internal,
    External,
    Other
}