import React, {useMemo} from "react";
import {IContainerProps} from "../../Interfaces/IContainterProps";
import {LanguagePicker} from "../LanguagePicker";
import {useLanguages} from "../../Hooks/GlobalConfigContext";
import {IBasicGlobalConfigEntity} from "../../Api/Dtos/IBasicGlobalConfigEntity";
import Flex from 'ibis-design-system/lib/components/Atoms/Flex';
import {Europass} from "./Europass/Europass";
import {MarginTopBlock} from "../MarginTopBlock";
import {EOP} from "../../Interfaces/IPatchParams";
import {ICreateEuropassLanguageDto, IEuropassLanguageDto} from "../../Api/Dtos/Resume/IEuropassLanguageDto";
import {hasPermission} from "../../Utils/hasPermission";
import { LanguageTable } from "./LanguagesTable/LanguageTable";
import { ICreateLanguagesDto, ILanguagesDto } from "../../Api/Dtos/Resume/ILanguagesDto";


interface ILanguageProps extends IContainerProps {}

export const Language: React.FunctionComponent<ILanguageProps> = (props) => {

    const languages = useLanguages();
    const motherTongues = useMemo(() => props.item.europassLanguages?.filter(pl => pl.isMotherTongue), [props]);
    const motherTonguesTable = useMemo(() => props.item.languages?.filter(pl => pl.isMotherTongue), [props]);
    const otherLanguages = useMemo(() => props.item.europassLanguages?.filter(pl => !pl.isMotherTongue), [props]);
    const otherLanguagesTable = useMemo(() => props.item.languages?.filter(pl => !pl.isMotherTongue), [props]);

    const updateLanguages = (items: IBasicGlobalConfigEntity[] | undefined, motherTongue: boolean): void => {
        let langs = motherTongue ? motherTongues : otherLanguages; // props.item.europassLanguages;

        // If the items array is empty, and there is an item present in the language array, remove the items in the languages.
        if (langs?.length && !items?.length) {
            let toRemove: IEuropassLanguageDto[] | undefined;
            let toRemoveTable: ILanguagesDto[] | undefined;
            toRemove = motherTongue ? motherTongues : otherLanguages;
            toRemoveTable = motherTongue ? motherTonguesTable : otherLanguagesTable;
            if (!toRemove || !toRemove.length) return;
            if(!toRemoveTable || !toRemoveTable.length)return;
            props.patch(toRemove.map( lang => ({
                op: EOP.Remove,
                path: `EuropassLanguages/${lang.id}`
            })));
            props.patch(toRemoveTable.map( lang => ({
                op: EOP.Remove,
                path: `Languages/${lang.id}`
            })));

            return;
        }

        // If a new item has been removed, we find that item and call a patch.
        if (langs && langs.length && items && items.length && langs.length > items.length) {
            // Now that we have verified a new language has been removed, we'll find it and send a patch to remove that language.
            let removed: IEuropassLanguageDto | undefined;
            let removedTable : ILanguagesDto | undefined;
            langs.forEach( (language : any) => {
                let found = false;
                // items.forEach( (i) => i.id === language.language ? found = true : undefined);
                if (!found) removed = language;
                if (!found) removedTable = language;
            });
            if (!removed) return;
            props.patch([
                {
                    op: EOP.Remove,
                    path: `EuropassLanguages/${removed.id}`
                }
            ]);
            if (!removedTable) return;
            props.patch([
                {
                    op: EOP.Remove,
                    path: `Languages/${removedTable.id}`
                }
            ]);
            return;
        }

        // Down here we know for sure a new language has been added. So we'll find that language and send a create request to the backend.
        // NOTE - These languages are added like this when using the text input. If you add languages using the europass picker, check the <Europass /> component below to see the adding process.
        let added: IBasicGlobalConfigEntity | undefined;
        items?.forEach( item => {
            if (!langs?.find( language => language.language === item.id)) added = item;
        });
       if (!added) return;

        let tmpEuropass: ICreateEuropassLanguageDto = {
            language: added.id,
            isMotherTongue: motherTongue,
        }
        props.patch([
            {
                op: EOP.Add,
                path: `EuropassLanguages/-`,
                value: tmpEuropass
            }
        ]);
        let tmpLanguageTable: ICreateLanguagesDto = {
            language: added.id,
            isMotherTongue: motherTongue,
        }
        props.patch([
            {
                op: EOP.Add,
                path: `Languages/-`,
                value: tmpLanguageTable
            }
        ]);
    }

    return (
        <div>
            <MarginTopBlock>
                <LanguageTable {...props} />
            </MarginTopBlock>
            <MarginTopBlock>
                <Europass {...props} />
            </MarginTopBlock>

        </div>
    );
};
