/**
 * Returns the first letter of a first and lastname if one or both are present.
 * @param firstName - optional
 * @param lastName - optional
 */
export const getImageInitials = (firstName?: string, lastName?: string): string => {
    let initials = "";
    if (firstName && firstName.length) initials += firstName.substring(0, 1);
    if (lastName && lastName.length) initials += lastName.substring(0, 1);
    return initials;
}