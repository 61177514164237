import React, { useEffect, useState } from "react";
import {
    IBasePickerStyleProps,
    IBasePickerStyles,
    IBasePickerSuggestionsProps,
    IStyleFunctionOrObject,
    ITag,
    TagPicker
} from "office-ui-fabric-react";
import {request} from "../Utils/request";
import {Bold} from "./Bold";
import { useCountries } from "../Hooks/GlobalConfigContext";

interface IPickerProps {
    /**
     * A function which fetches the tenders that will be displayed as selectable options.
     * Takes a string (Tender title, or LOIS code) as search input, and passes additional options for limiting the amount of search results.
     */
    fetchData?: (searchInput: string) => Promise<any[]>;
    fetchSingle?: (id: string) => Promise<any>;
    singleTypeToTag: (single: any) => ITag;
    listToTags: (list: any[]) => ITag[]
    
    list?: any[];

    // When the list of selected tenders changes, this function is fired. It returns a list of all selected tenders. Value will be undefined if no tenders are selected.
    onChange: (tags: string[] | undefined) => void;
    // The maximum amount of labels that can be added.
    pickLimit?: number;
    // A list of Guids for selected projects.
    selectedTags?: string[];
    // The label displayed above the input field.
    label?: string;
    // Only styles the overlying div, not the input.
    className?: string;
    // Only styles the overlying div, not the input.
    style?: React.CSSProperties;
}

let listTags: ITag[] | undefined = undefined;

export const GenericPicker: React.FunctionComponent<IPickerProps> = (props) => {

    const [selected, setSelected] = useState<ITag[] | undefined>(undefined);
    
    const getTextFromItem = (item: ITag) => item.name === undefined ? '' : item.name;

    useEffect(() => {
      if (props.list) {
        listTags = props.listToTags(props.list)
        setInitiallySelectedTags(listTags, props.selectedTags);
        return;
      }

      fetchAllExisting(props.selectedTags);
    }, []);

    const setInitiallySelectedTags = (listTags: ITag[], selectedTags?: string[]) => {

      if (!props.selectedTags) return;

      const selected: ITag[] = [];
      
      listTags.forEach((tag) => {
        selectedTags!.forEach((s) => {
          if (tag.key === s) selected.push(tag);
        });
      });

      setSelected(selected);
    };

    const fetchAllExisting = async (ids?: string[]): Promise<void> => {
      if (!ids || !ids.length || !props.fetchSingle) return;

      const tags: ITag[]  = [];

      await Promise.all(ids.map( async (id: string) => {
          if (!props.fetchSingle) return;

          const { result, error } = await request(props.fetchSingle(id));

          if (error || !result) return;
          
          const tag = props.singleTypeToTag(result);
          
          tags.push(tag);
      }));

      setSelected(tags);
    }


    const filterSuggestedTags = async (filterText: string): Promise<ITag[]> => {

      let result: any = undefined;
      const lowerCaseFilterText = filterText.toLowerCase(); // convert filterText to lowercase
      
      if (props.list) {
          result = [];
          listTags?.forEach( item => {
              if (item.name?.toLowerCase().includes(lowerCaseFilterText)) result.push(item); // convert item.name to lowercase before checking if it includes the filterText
          });

          return result;
        }  
        else if(props.fetchData) {
          const response = await request(props.fetchData(filterText));
          result = response.result;
          return props.listToTags(result);
      }

      return [];
  };

    const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
        suggestionsHeaderText: 'Suggested projects',
        noResultsFoundText: 'No projects found',
    };

    const style: IStyleFunctionOrObject<IBasePickerStyleProps, IBasePickerStyles> | undefined = {
        text: {
            borderLeft: "none",
            borderTop: "none",
            borderRight: "none",
            height: '33px',
        }
    }

    return (
        <div style={props.style} className={props.className}>
            <Bold>{props.label}</Bold>
            <TagPicker
                styles={style}
                removeButtonAriaLabel="Remove"
                onResolveSuggestions={filterSuggestedTags}
                getTextFromItem={getTextFromItem}
                pickerSuggestionsProps={pickerSuggestionsProps}
                itemLimit={props.pickLimit}
                selectedItems={selected}
                onChange={(tags: ITag[] | undefined) => {
                    setSelected(tags);
                    const ids = tags?.map<string>( tag => tag.key as string);
                    props.onChange(ids);
                }}
            />
        </div>
    );
};


